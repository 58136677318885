import { Tooltip } from "antd";
import React from "react";
import { tooltip } from "../../assests/images";
import { SwitchBtn, TextInput } from "../../components";
import PropTypes from "prop-types";

export default function AdOptimization({
  scrapContents,
  setAdvanceSettings,
  advanceSetting,
}) {
  return (
    <div className="mb-20">
      <div>
        <div className="flex mt-8">
          <h4>Is there any special event in your ad?</h4>
          <Tooltip
            placement="bottom"
            title="Tip: By selecting any of these Special
              Events our A.I. will adjust your Ad Copy
              so it can perfectly fit your ad campaign
              strategy."
          >
            <img src={tooltip} width="20px" />
          </Tooltip>
        </div>
        <div>
          <div>
            <div className="flex">
              <div>
                <SwitchBtn
                  disabled={scrapContents ? false : true}
                  onChange={(e) => {
                    setAdvanceSettings((state) => ({
                      ...state,
                      freeShipping: {
                        ...state.freeShipping,
                        active: e,
                      },
                    }));
                  }}
                />
              </div>
              <div className="ml-3 description">
                <div className="flex">
                  Your offer has free shipping?
                  <Tooltip
                    placement="bottom"
                    title="Tip: Use this if you offer Free
                      Shipping on what you’re currently
                      promoting"
                  >
                    <img src={tooltip} width="20px" />
                  </Tooltip>
                </div>
                {advanceSetting.freeShipping.active ? (
                  <div>
                    <div>
                      <div>
                        <h4>
                          What is the minimum order value to get free shipping?
                        </h4>
                      </div>
                      <div className="w-80">
                        <TextInput
                          disabled={scrapContents ? false : true}
                          type="number"
                          placeholder="0"
                          onChange={(e) =>
                            setAdvanceSettings((state) => ({
                              ...state,
                              freeShipping: {
                                ...state.freeShipping,
                                discount: e.target.value,
                              },
                            }))
                          }
                          value={
                            advanceSetting.freeShipping.discount >= 0
                              ? advanceSetting.freeShipping.discount
                              : 0
                          }
                        />
                      </div>
                      <div className="mr-8 float-right  text-xs">
                        ($0 means its free shipping on all orders)
                      </div>
                    </div>
                    <div className="w-80">
                      <TextInput
                        onChange={(e) =>
                          setAdvanceSettings((state) => ({
                            ...state,
                            freeShipping: {
                              ...state.freeShipping,
                              promo: e.target.value,
                            },
                          }))
                        }
                        disabled={scrapContents ? false : true}
                        placeholder="Promo code(e.g SUMMER2021)"
                      />
                      <div className="mr-1 float-right  text-xs">
                        (Leave blank if it is unnecessary)
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex mt-3">
              <div>
                <SwitchBtn
                  checked={advanceSetting.discount.active}
                  disabled={scrapContents ? false : true}
                  onChange={(e) =>
                    setAdvanceSettings((state) => ({
                      ...state,
                      discount: {
                        ...state.discount,
                        active: e,
                        offType: !e && "",
                      },
                    }))
                  }
                  className="switch-btn"
                />
              </div>
              <div className="ml-3 description">
                <div className="flex">
                  Giving out a discount ($ off or % off)?
                  <Tooltip
                    placement="bottom"
                    title="Tip: Use this if you offer any sort
                      of Discount on what you’re currently
                      promoting - be it $-based or %-based
                      discounts."
                  >
                    <img src={tooltip} width="20px" />
                  </Tooltip>
                </div>
                {advanceSetting.discount.active ? (
                  <div className="mt-3">
                    <div className="flex">
                      <div>
                        <SwitchBtn
                          disabled={scrapContents ? false : true}
                          checked={
                            advanceSetting.discount.offType === "dollar_off"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setAdvanceSettings((state) => ({
                              ...state,
                              discount: {
                                ...state.discount,
                                offType: e ? "dollar_off" : "",
                                active: true,
                              },
                            }))
                          }
                        />
                      </div>
                      <div className="ml-3">Is it a $ off discount?</div>
                    </div>
                    {advanceSetting.discount.offType === "dollar_off" &&
                    advanceSetting.discount.offType !== "percent_off" ? (
                      <div>
                        <div className="mb-6 mt-3">
                          <div className="flex">
                            <div className="w-64">
                              <TextInput
                                addonBefore="$ Value"
                                disabled={scrapContents ? false : true}
                                type="number"
                                placeholder="0"
                                onChange={(e) =>
                                  setAdvanceSettings((state) => ({
                                    ...state,
                                    discount: {
                                      ...state.discount,
                                      discount: e.target.value,
                                    },
                                  }))
                                }
                                value={
                                  advanceSetting.discount.discount >= 0
                                    ? advanceSetting.discount.discount
                                    : 0
                                }
                              />
                            </div>
                          </div>
                          <div className=" text-xs">
                            ($0 means its free shipping on all orders)
                          </div>
                        </div>
                        <div className="mb-8">
                          <TextInput placeholder="Promo code(e.g SUMMER2021)" />
                          <div className="float-right  text-xs">
                            (Leave blank if it is unnecessary)
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex mt-3">
                      <div>
                        <SwitchBtn
                          disabled={scrapContents ? false : true}
                          checked={
                            advanceSetting.discount.offType === "percent_off"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setAdvanceSettings((state) => ({
                              ...state,
                              discount: {
                                ...state.discount,
                                offType: e ? "percent_off" : "",
                                active: true,
                              },
                            }))
                          }
                        />
                      </div>
                      <div className="ml-3">Or, is it a % off discount?</div>
                    </div>
                    {advanceSetting.discount.offType === "percent_off" &&
                    advanceSetting.discount.offType !== "dollar_off" ? (
                      <div>
                        <div className="mb-6 mt-3 w-max">
                          <div className="flex">
                            <div className="w-64">
                              <TextInput
                                addonBefore="$ Value"
                                disabled={scrapContents ? false : true}
                                type="number"
                                placeholder="0"
                                onChange={(e) =>
                                  setAdvanceSettings((state) => ({
                                    ...state,
                                    discount: {
                                      ...state.discount,
                                      per: e.target.value,
                                    },
                                  }))
                                }
                                value={
                                  advanceSetting.discount.per >= 0
                                    ? advanceSetting.discount.per
                                    : 0
                                }
                              />
                            </div>
                          </div>
                          <div className=" text-xs">
                            ($0 means its free shipping on all orders)
                          </div>
                        </div>
                        <div>
                          <TextInput
                            onChange={(e) =>
                              setAdvanceSettings((state) => ({
                                ...state,
                                discount: {
                                  ...state.discount,
                                  promo: e.target.value,
                                },
                              }))
                            }
                            disabled={scrapContents ? false : true}
                            placeholder="Promo code(e.g SUMMER2021)"
                          />
                          <div className="float-right  text-xs">
                            (Leave blank if it is unnecessary)
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="flex mt-3">
              <div>
                <SwitchBtn
                  disabled={scrapContents ? false : true}
                  onChange={(e) =>
                    setAdvanceSettings((state) => ({
                      ...state,
                      ocassion: {
                        ...state.discount,
                        active: e,
                      },
                    }))
                  }
                  className="switch-btn"
                />
              </div>
              <div className="ml-3 description">
                <div className="flex">
                  Celebrating a special occasion?
                  <Tooltip
                    placement="bottom"
                    title="Tip: Use this if you what you’re
                      currently promoting is related to
                      any Special Occasion"
                  >
                    <img src={tooltip} width="20px" />
                  </Tooltip>
                </div>
                {advanceSetting.ocassion.active ? (
                  <div>
                    <h4>What is occasion is your ad related to?</h4>
                    <div className="w-80">
                      <TextInput
                        disabled={scrapContents ? false : true}
                        placeholder="Name of the occasion"
                        onChange={(e) =>
                          setAdvanceSettings((state) => ({
                            ...state,
                            ocassion: {
                              ...state.ocassion,
                              name: e.target.value,
                            },
                          }))
                        }
                        value={advanceSetting.ocassion.name}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex mt-3">
              <div>
                <SwitchBtn
                  disabled={scrapContents ? false : true}
                  onChange={(e) =>
                    setAdvanceSettings((state) => ({
                      ...state,
                      location: {
                        ...state.location,
                        active: e,
                      },
                    }))
                  }
                  className="switch-btn"
                />
              </div>
              <div className="ml-3 description">
                <div className="flex">
                  Your offer is related to a specific location?
                  <Tooltip
                    placement="bottom"
                    title="Tip: Use this if you what you’re
                      currently promoting is related to a
                      Specific Location"
                  >
                    <img src={tooltip} width="20px" />
                  </Tooltip>
                </div>
                {advanceSetting.location.active ? (
                  <div>
                    <h4>What Location is this ad targeting?</h4>
                    <div className="w-80">
                      <TextInput
                        onChange={(e) =>
                          setAdvanceSettings((state) => ({
                            ...state,
                            location: {
                              ...state.location,
                              name: e.target.value,
                            },
                          }))
                        }
                        value={advanceSetting.location.name}
                        disabled={scrapContents ? false : true}
                        placeholder="Name of the location(e.g:New York)"
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AdOptimization.propTypes = {
  scrapContents: PropTypes.bool,
  advanceSetting: PropTypes.any,
  setAdvanceSettings: PropTypes.func,
};
