import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Settings,
  DashBoard,
  ContentGeneration,
  Templates,
  Tutorials,
  Help,
  Showcase,
} from "../../modules";
import PropTypes from "prop-types";
import { SubMenu } from "../sidebar-data/sidebar-data";

export const UserPanelMenuItems = () => (
  <div className="mt-5">
    <SubMenu />
  </div>
);
export const UserPanelRouting = ({ path, setRenderBrain }) => {
  return (
    <div>
      <Routes>
        <Route
          path={`${path}`}
          element={<DashBoard setRenderBrain={setRenderBrain} />}
        />
        <Route path={`${path}/settings`} element={<Settings />} />
        <Route
          path={`${path}/content-generation`}
          element={<ContentGeneration />}
        />
        <Route
          path={`${path}/templates`}
          element={<Templates setRenderBrain={setRenderBrain} />}
        />
        <Route path={`${path}/tutorial`} element={<Tutorials />} />
        <Route path={`${path}/showcase`} element={<Showcase />} />
        <Route path={`${path}/help`} element={<Help />} />
      </Routes>
    </div>
  );
};
UserPanelRouting.propTypes = {
  path: PropTypes.string.isRequired,
  setRenderBrain: PropTypes.func,
};
