import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AdHeader } from "../post-components/header";
import { AdPrimary } from "../post-components/primary";
import Views from "../post-components/View";
import Bubble from "../../../modules/Buble";

export const PasAdCopy = ({
  logo,
  state,
  text,
  primaryImg,
  primaryText,
  onClick,
  score,
  wirteClipboardDesc,
  type,
  showCase,
  view,
}) => {
  const [, setIsPrev] = useState(false);
  const [, setIsEidted] = useState(false);
  const { copyIndex } = useSelector((e) => e.post);

  let d = state?.statticType;

  useEffect(() => {
    setIsEidted(false);
    setIsPrev(false);
  }, [d, copyIndex]);
  return (
    <div
      style={{ boxShadow: "0px 1px 3px rgb(0 0 0 / 20%)", maxWidth: 440 }}
      className="w-100 border-2 mx-auto container bg-white rounded-lg"
    >
      <AdHeader
        onClick={onClick}
        title={text}
        score={score}
        logo={logo}
        wirteClipboardDesc={wirteClipboardDesc}
      />
      <AdPrimary
        primaryImg={primaryImg}
        primaryText={primaryText}
        type={type}
        imageHidden={true}
      />
      {showCase && <Views view={view} />}
      {showCase && <Bubble audience={state?.showcase?.audience} />}

      <br />
    </div>
  );
};

PasAdCopy.propTypes = {
  state: PropTypes.any,
  text: PropTypes.any,
  primaryImg: PropTypes.any,
  primaryText: PropTypes.any,
  headline: PropTypes.any,
  logo: PropTypes.any,
  setHeadlineIndex: PropTypes.any,
  onClick: PropTypes.any,
  score: PropTypes.number,
  wirteClipboardDesc: PropTypes.func,
  wirteClipboardHeadline: PropTypes.func,
  type: PropTypes.any,
  view: PropTypes.number,
  showCase: PropTypes.bool,
};
