import { TextInput, Btn } from "../../../components/forms";
import {
  ArrowRightOutlined,
  EyeFilled,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  airbnb,
  amazon,
  att,
  billionTrees,
  cocaCola,
  google,
  ibm,
  paw,
  scotts,
  thd,
  verizon,
  yahoo,
  topAd,
  clutch,
  meta,
  logoGif,
} from "../../../assests/images/";
import "./scss/index.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authActions, modalAction } from "../../../store/actions";
import { userSchema } from "../../../Validations/UserValidation";

export function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logos = [
    paw,
    billionTrees,
    cocaCola,
    yahoo,
    amazon,
    google,
    scotts,
    att,
    airbnb,
    verizon,
    ibm,
    thd,
    topAd,
    meta,
    clutch,
  ];
  const handleSignUp = async () => {
    const url = new URL(window.location.href).searchParams;
    const discount = url.get("discount");
    const ref = url.get("ref");

    if (!name) dispatch(modalAction.error({ title: "First Name is Required" }));
    else if (!email)
      dispatch(modalAction.error({ title: "Email is Required" }));
    else if (!password)
      dispatch(modalAction.error({ title: "Password is Required" }));
    else if (name && email && password) {
      try {
        const formData = {
          email,
          password,
          username: name,
          fullname: name,
          discount,
          ref,
        };
        let isValid = await userSchema.validate(formData);
        if (!isValid) return;
        setLoad(true);
        dispatch(authActions.signup(formData, navigate, setLoad));
      } catch (err) {
        if (err.name === "ValidationError") {
          dispatch(
            modalAction.error({
              title: err.errors,
            })
          );
        }
      }
    }
  };
  return (
    <div className="signup h-screen w-screen flex flex-row md:place-content-center">
      <div className="h-screen  flex flex-col justify-center lg:px-8  xs:overflow-hidden px-3  sm:w-full  md:w-2/4 sm:justify-center sm:items-center">
        <div className="lg:w-96 md:w-96 sm:w-full xs:w-full p-5">
          <img src={logoGif} width="100px" />
          <h1 className="title">Create UnicornCopy.AI account!</h1>
          <p className="subtitle">
            So you can subscribe to a paid plan after 😎
          </p>
          <div className="pb-2">
            <label htmlFor="name">What’s your first name?</label>
            <TextInput
              placeholder="Your Good name?"
              type="text"
              id="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>

          <div className="pt-2 pb-2">
            <label htmlFor="email">What’s your best email address?</label>
            <TextInput
              placeholder="your active email address?"
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="pt-2 pb-2 relative">
            <label htmlFor="passowrd">What’s your password?</label>
            {showPassword ? (
              <div
                onClick={() => setShowPassword((e) => !e)}
                className="absolute w-5  z-10 right-1  cursor-pointer m-1.5"
              >
                <EyeFilled
                  style={{ fontSize: "16px", color: "#60114a" }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <div
                onMouseDown={() => setShowPassword(true)}
                className="absolute w-5 z-10  right-1 cursor-pointer m-1.5"
              >
                <EyeInvisibleOutlined
                  style={{ fontSize: "16px" }}
                  className="cursor-pointer !text-[#60114a]"
                  color="#60114a"
                />
              </div>
            )}
            <TextInput
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              name="input"
              placeholder="Set your password"
            />
          </div>
          <div className="pt-2 pb-2">
            <Btn
              value="Continue"
              icon={<ArrowRightOutlined />}
              bgColor="#1e2a3b"
              color="#ffffff"
              onClick={handleSignUp}
              load={load}
            />
          </div>
          <p>
            Already have an account?{" "}
            <Link className="LoginBtn" to="/">
              Login
            </Link>
          </p>
        </div>
      </div>
      <div className=" h-screen  w-4/5  xs:hidden sm:hidden md:hidden  lg:inline-block xl:inline-block main-content-signup">
        <div className="flex flex-col justify-center content-center side-contents">
          <h1 className="text-4xl text-white">
            A digital agency built for the privacy era.
          </h1>
          <p className="text-white text-lg">
            Beat privacy updates with our creative first, predictive modeling
          </p>
          <div className="project-logo w-4/5 flex justify-center content-center items-center gap-4 flex-wrap ml-auto mr-auto opacity-50">
            {logos.map((value, index) => {
              return (
                <img
                  src={value}
                  key={index}
                  className="w-24 opacity-90"
                  alt=""
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
