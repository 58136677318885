import { GPT3API, API } from "../config/api";

const generate = (data) =>
  // GPT3API.post("completion",  data ).then(
  GPT3API.post("fetchGpt3DataFromServer", data).then(
    (res) => res,
    (err) => err
  );
const generateBatch1 = (data) =>
  API.post("fetchFirstBatch", data).then(
    // API.post("fetchGpt3DataFromServer",  data ).then(
    (res) => res,
    (err) => err
  );
const fetchGpt3DataFromServer = (data) =>
  API.post("fetchGpt3DataFromServer", data).then(
    (res) => res,
    (err) => err
  );
const login = () =>
  GPT3API.post("authentication", {
    email: "test@test.com",
    password: "Pa$$word_2021",
    strategy: "local",
  }).then(
    (res) => res,
    (err) => err
  );
const generateGoogleAd = (data) =>
  GPT3API.post("completion", data).then(
    (res) => res,
    (err) => err
  );
const fetchHistory = () =>
  API.get("fetchHistory").then(
    (res) => res,
    (err) => err
  );
const deleteSingleHistory = (data) =>
  API.post("deleteSingleHistory", data).then(
    (res) => res,
    (err) => err
  );
const deleteAllHistory = (data) =>
  API.post("deleteAllHistory", data).then(
    (res) => res,
    (err) => err
  );

export const gpt3 = {
  generate,
  login,
  generateBatch1,
  fetchGpt3DataFromServer,
  generateGoogleAd,
  fetchHistory,
  deleteSingleHistory,
  deleteAllHistory,
};
