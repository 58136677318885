import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { AdHeader } from "../post-components/header";
// import { AdHeadline } from "../post-components/headline";
import { useSelector } from "react-redux";
import "./scss/index.scss";
import { TiktokAdPrimary } from "../post-components/primaryTiktok";
import Tiktok from "../../../assests/images/background/tiktokLogo.svg";

export const TiktokAdCopy = ({
  // logo,
  state,
  // text,
  primaryImg,
  primaryText,
  // headline,
  showCase,
  // setHeadlineIndex,
  onClick,
  // score,
  // wirteClipboardDesc,
  IndexChanger,
  // wirteClipboardHeadline,
  type,
  handleShowCase,
  view,
}) => {
  const [, setIsPrev] = useState(false);
  const [, setIsEidted] = useState(false);
  const { copyIndex } = useSelector((e) => e.post);

  let d = state?.statticType;

  useEffect(() => {
    setIsEidted(false);
    setIsPrev(false);
  }, [d, copyIndex]);
  return (
    <div
      style={{
        boxShadow: "0px 1px 3px rgb(0 0 0 / 20%)",
        maxWidth: 420,
        marginTop: 40,
      }}
      className="root-tiktok"
    >
      <p style={{ textAlign: "center", color: "#ffff" }}>Following | For You</p>
      <div className="contentArea">
        {/* <AdHeader
        onClick={onClick}
        title={text}
        score={score}
        logo={logo}
        wirteClipboardDesc={wirteClipboardDesc}
      /> */}
        <TiktokAdPrimary
          primaryImg={primaryImg}
          primaryText={primaryText}
          type={type}
          imageHidden={true}
          IndexChanger={IndexChanger}
          onClick={onClick}
          handleShowCase={handleShowCase}
          showCase={showCase}
          view={view}
        />
      </div>
      <div className="footer-tiktok">
        <img src={Tiktok} alt="Tiktok" width="30px" />
      </div>
      {/* <AdHeadline
        setHeadlineIndex={setHeadlineIndex}
        wirteClipboardHeadline={wirteClipboardHeadline}
        headline={headline}
      /> */}
    </div>
  );
};

TiktokAdCopy.propTypes = {
  state: PropTypes.any,
  text: PropTypes.any,
  primaryImg: PropTypes.any,
  primaryText: PropTypes.any,
  headline: PropTypes.any,
  logo: PropTypes.any,
  setHeadlineIndex: PropTypes.any,
  onClick: PropTypes.any,
  score: PropTypes.number,
  wirteClipboardDesc: PropTypes.func,
  wirteClipboardHeadline: PropTypes.func,
  type: PropTypes.any,
  IndexChanger: PropTypes.any,
  handleShowCase: PropTypes.any,
  showCase: PropTypes.any,
  view: PropTypes.any,
};
