import { ArrowDownOutlined } from "@ant-design/icons";
import React from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";

export default function BrainCaption({
  perc,
  bottomText2,
  color,
  color2,
  bottomText,
  mostValuableAdTypesLabels,
  series,
}) {
  //   console.log(mostValuableAdTypesLabels);
  return (
    <div className="flex-row" style={{ marginTop: -30 }}>
      {series === "C" ? (
        <>
          <span className="flex-row">
            {mostValuableAdTypesLabels?.length &&
              mostValuableAdTypesLabels.map((e, i) => {
                return (
                  <div
                    className="flex items-center"
                    style={{
                      alignContent: "space-between",
                      justifyContent: "space-between",
                    }}
                    key={i}
                  >
                    <p className="white text-base m-0 ml-2">{e.name}</p>
                    <span className="flex">
                      <p
                        className="text-base m-0 text-right"
                        style={{
                          color: `${e.status < 0 ? color2 : color}`,
                        }}
                      >
                        <ArrowDownOutlined
                          style={{
                            color: `${e.status < 0 ? color2 : color}`,
                            fontSize: "20px",
                            transform: e.status > 0 && "rotate(180deg)",
                          }}
                        />
                        {`${e.status}%`}{" "}
                        {e.status === 0
                          ? "Didn't affect performance"
                          : e.status > 0
                          ? "Increase in ad performance"
                          : "Decrease in ad performance"}
                      </p>
                    </span>
                  </div>
                );
              })}
          </span>
        </>
      ) : (
        <>
          <span className="flex">
            <div
              className="flex items-center"
              style={{
                alignContent: "space-between",
                justifyContent: "space-between",
              }}
            >
              <p className="white text-base m-0 ml-2">{bottomText}</p>
              <span className="flex">
                <span style={{ transform: perc > 0 && "rotate(180deg)" }}>
                  <ArrowDownOutlined
                    style={{ color: `${color}`, fontSize: "20px" }}
                  />
                </span>
                <p
                  className="text-base m-0"
                  style={{ color: `${color}` }}
                >{`${perc}%`}</p>
              </span>
            </div>
          </span>
          <span className="flex">
            <div className="flex items-center">
              <p className="white text-base m-0 ml-2">{bottomText2}</p>
              <span style={{ transform: perc > 0 && "rotate(180deg)" }}>
                <ArrowDownOutlined
                  style={{ color: `${color2}`, fontSize: "20px" }}
                />
              </span>
              <p
                className="text-base m-0"
                style={{ color: `${color2}` }}
              >{`${perc}%`}</p>
            </div>
          </span>
        </>
      )}
    </div>
  );
}

BrainCaption.propTypes = {
  perc: PropTypes.any,
  color: PropTypes.any,
  bottomText: PropTypes.any,
  color2: PropTypes.string,
  bottomText2: PropTypes.string,
  series: PropTypes.string,
  mostValuableAdTypesLabels: PropTypes.array,
};
