import React from "react";
import PropTypes from "prop-types";

export default function Headershowcase({
  filters,
  handleFilter,
  selectedFilter,
}) {
  return (
    <>
      <div className="flex lg:ml-10 w-full row flex-wrap container-btn-showcase-header rounded-lg dropdown">
        {!!filters?.length &&
          filters.map((e, idx) => {
            return (
              <div
                key={idx}
                className={`btn-showcase ${
                  selectedFilter?.toLowerCase() === e?.type?.toLowerCase() &&
                  "btn-showcase-activ"
                }`}
                onClick={() => handleFilter(e.type)}
              >
                <p>{e.name}</p>
              </div>
            );
          })}
      </div>
    </>
  );
}

Headershowcase.propTypes = {
  filters: PropTypes.any,
  handleFilter: PropTypes.any,
  selectedFilter: PropTypes.any,
};
