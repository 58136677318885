import React from "react";
import { Link } from "react-router-dom";
import {
  tutorial,
  logout,
  help,
  dashboard,
  setting,
  template,
  showcase,
} from "../../assests/images";
import PropTypes from "prop-types";
import "./index.scss";
import { Menu } from "antd";
import { authActions } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentKey } from "../../store/actions/sidebarActions";
export const SidebarDataForUserPanel = [
  {
    title: "Dashboard",
    route: "",
    icon: <img src={dashboard} width="22px" />,
    key: "dashboard",
  },
  {
    title: "Templates",
    route: "templates",
    icon: <img src={template} width="22px" height="30px" />,
    key: "templates",
  },
  {
    title: "Showcase",
    route: "showcase",
    icon: <img src={showcase} width="22px" height="30px" />,
    key: "showcase",
  },
  {
    title: "Settings",
    route: "settings",
    icon: <img src={setting} width="22px" height="30px" />,
    key: "settings",
  },
  {
    title: "Help",
    route: "help",
    icon: <img src={help} width="22px" height="30px" />,
    key: "help",
  },
  {
    title: "Tutorial",
    route: "tutorial",
    icon: <img src={tutorial} width="22px" height="30px" />,
    key: "tutorial",
  },
  {
    title: "Logout",
    route: "logout",
    icon: <img src={logout} width="22px" height="30px" />,
    key: "logout",
  },
];

export const SubMenu = () => {
  // const pathname = window.location.pathname;
  // let [currentKey, setCurrentKey] = useState();

  const currentKey = useSelector((state) => state.sideBarReducer.currentKey);
  const dispatch = useDispatch();

  function handleKeyChange(key) {
    dispatch(updateCurrentKey(key));
  }

  console.log(currentKey);

  let pathForSideMenu = "/unicorncopy/userpanel";

  return (
    <>
      {SidebarDataForUserPanel.map((item) => {
        return (
          <Link key={item.key} to={`${pathForSideMenu}/${item.route}`}>
            <Menu className="menus-lists">
              <Menu.Item
                key={item.key}
                className={`meun-li customColor ${
                  item.key === currentKey
                    ? "ant-menu-items-selected"
                    : "notselected"
                } `}
                icon={item.icon}
                onClick={() => {
                  if (item.title === "Logout") authActions.logout();
                  handleKeyChange(item.key);
                }}
              >
                {item.title}
              </Menu.Item>
            </Menu>
          </Link>
        );
      })}
    </>
  );
};

SubMenu.propTypes = {
  item: PropTypes.any,
  path: PropTypes.any,
};
