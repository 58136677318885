const adPostData = (data) => {
  return {
    type: "AD_POST_DATA",
    payload: data,
  };
};
const setPostSettings = (data) => {
  return {
    type: "SET_POST_SETTINGS",
    payload: data,
  };
};
const updateAddSetting = (data) => {
  return {
    type: "UPDATE_ADD_SETTINGS",
    payload: data,
  };
};
const selectAbTest = (data) => {
  return {
    type: "SELECT_AB_TEST",
    payload: data,
  };
};
const funnelStage = (data) => {
  return {
    type: "SELECT_FUNNEL_STAGE",
    payload: data,
  };
};
const initiateAb = (data) => {
  return {
    type: "INITIATE_AB",
    payload: data,
  };
};
const setStoreAbTest = (data) => {
  return {
    type: "SET_STORE_AB_TEST",
    payload: data,
  };
};
const changeAdType = (data) => {
  return {
    type: "CHANGE_AD_TYPE",
    payload: data,
  };
};
const generateRandomNumberForCopyType = (currIndex, possibleIndexes) => {
  return {
    type: "RANDOMIZE_NUMBER_COPYTYPE",
    payload: () => {
      if (possibleIndexes?.length) {
        const count = possibleIndexes.filter((value) => value).length;
        //
        var someOtherIndex = currIndex;
        if (count === 0) return currIndex;
        if (count >= 2) {
          do {
            someOtherIndex = Math.floor(Math.random() * 9) + 0;
          } while (
            !possibleIndexes[someOtherIndex] ||
            someOtherIndex === currIndex
          );
        } else {
          do {
            someOtherIndex = Math.floor(Math.random() * 9) + 0;
          } while (!possibleIndexes[someOtherIndex]);
        }
        return someOtherIndex;
      } else if (currIndex) {
        let someOtherIndex = currIndex;
        do {
          someOtherIndex = Math.floor(Math.random() * 9) + 0;
        } while (someOtherIndex === currIndex);
        return someOtherIndex;
      }
      return Math.floor(Math.random() * 9) + 0;
    },
  };
};
const handleBackward = (data) => {
  return {
    type: "GO_BACKWARD",
    payload: data,
  };
};

const slectDirection = (data) => {
  return {
    type: "SELECT_DIRECTION",
    payload: data,
  };
};
const storeSecondVaration = (data) => {
  return {
    type: "STORE_SECOND_VARIATION",
    payload: data,
  };
};
const savePrevPost = (data) => {
  return {
    type: "SAVE_PREV_POST",
    payload: data,
  };
};
const incrementIndex = (data) => {
  return {
    type: "INCREMENT_INDEX",
    payload: data,
  };
};
const goto = (data) => {
  return {
    type: "GO_TO",
    payload: data,
  };
};
const resetCopyIndex = () => {
  return {
    type: "RESET_INDEX",
  };
};
const decrementIndex = () => {
  return {
    type: "DECREMENT_INDEX",
  };
};
const setMax = (x) => {
  return {
    type: "SET_MAX",
    payload: x,
  };
};

const postAction = {
  adPostData,
  setPostSettings,
  selectAbTest,
  updateAddSetting,
  funnelStage,
  initiateAb,
  setStoreAbTest,
  changeAdType,
  generateRandomNumberForCopyType,
  handleBackward,
  slectDirection,
  storeSecondVaration,
  savePrevPost,
  incrementIndex,
  resetCopyIndex,
  decrementIndex,
  setMax,
  goto,
};

export default postAction;
