import React from "react";
import "./scss/index.scss";
import { Col, Row } from "antd";
import { AgencyPlan, GrowthPlan, PowerPlan } from "../../../components";
import { authService } from "../../../services";
import { products } from "../../packages";

export const Plans = () => {
  const createCheckoutSessionGrowth = async () => {
    try {
      const formData = {
        priceId: products[0].priceId,
        prod_id: products[0].id,
        type: "growth",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionPower = async () => {
    try {
      const formData = {
        priceId: products[1].priceId,
        prod_id: products[1].id,
        type: "power",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionAgency = async () => {
    try {
      const formData = {
        priceId: products[2].priceId,
        prod_id: products[2].id,
        type: "agency",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="mt-8 w-full container mx-auto  text-center pb-10">
      <div className="mt-10">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row className="flex justify-center items-center">
              <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <GrowthPlan
                  createCheckoutSession={createCheckoutSessionGrowth}
                />
              </Col>
              <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <AgencyPlan
                  createCheckoutSession={createCheckoutSessionPower}
                />
              </Col>
              <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <PowerPlan
                  createCheckoutSession={createCheckoutSessionAgency}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
