import React, { useState } from "react";
// import { LinkedAccounts } from "../account-setting";
import "./scss/index.scss";
import { Col, Row } from "antd";
// import { settings, referrals, rewards } from "../../../assests/images";
import { ProfileSetting } from "../profile-setting";

export const GeneralSetting = () => {
  // const sidenav = ["Profile"];
  // const sideIcons = [settings, referrals, rewards];
  const [activeIndex] = useState(0);
  // const handleOnClick = (i) => {
  //   setActiveIndex(i);
  // };
  return (
    <div className="container">
      <Row>
        {/* <Col lg={5} md={6} sm={8} xs={24}>
          <div className="pt-5 border-r-2 h-full">
            <div>
              {sidenav.map((v, i) => {
                return (
                  <div key={i}>
                    <div
                      onClick={() => handleOnClick(i)}
                      className={`${
                        activeIndex === i ? "activetab" : "notactive"
                      }  hover:bg-gray-200 mb-1 text-sm font-normal flex items-center flex-wrap py-3 px-4 border-b-2  cursor-pointer rounded-lg`}
                    >
                      <img
                        src={sideIcons[i]}
                        width="7%"
                        style={{ color: "black", marginRight: "10px" }}
                      />{" "}
                      {v}
                    </div>
                  </div>
                );
              })}
            </div>

            <LinkedAccounts />
          </div>
        </Col> */}

        <Col lg={24} xl={24} md={24} sm={24} xs={24}>
          {activeIndex === 0 ? <ProfileSetting /> : <></>}
          {/* {activeIndex === 1 ? (
            <div className="formDiv">
              <div className="p-2 pt-5 inputdiv">
                <h2>Referrals</h2>
              </div>
            </div>
          ) : (
            <></>
          )} */}
          {/* {activeIndex === 2 ? (
            <div className="formDiv">
              <div className="p-2 pt-5  inputdiv">
                <h2>Rewards</h2>
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </Col>
      </Row>
    </div>
  );
};
