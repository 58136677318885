import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";
import { gpt3 } from "../../../services";
// import { Spin } from "antd";
import ReactLoading from "react-loading";
import { DeleteOutlined, RedoOutlined } from "@ant-design/icons";

export const History = ({ reGenerate }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await gpt3.fetchHistory();
    console.log(res);
    setHistory(res.data?.history);
    setLoading(false);
  };

  const handleDelete = async (e, elem) => {
    setLoading(true);
    elem.stopPropagation();
    console.log(e);
    await gpt3.deleteSingleHistory({ id: e?._id });
    console.log(history);
    let newArr = history.filter((c) => c._id !== e._id);
    setHistory(newArr);
    setLoading(false);
  };

  const clearHistory = async () => {
    setLoading(true);
    await gpt3.deleteAllHistory({ id: localStorage.getItem("uid") });
    setHistory([]);
    setLoading(false);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center mt-40">
        <ReactLoading
          className="mx-auto my-10"
          type="bars"
          color="black"
          height={14}
          width={50}
          delay={1}
        />
      </div>
    );
  return (
    <div className="p-2">
      <div
        className={`text-right ${history?.length == 0 ? "hidden" : null}`}
        onClick={clearHistory}
      >
        <p className="text-xs font-medium px-3 hover:text-black cursor-pointer rounded-sm text-gray-400">
          Clear History
        </p>
      </div>
      {history?.length == 0 ? (
        <div className="text-center flex justify-center items-center font-semibold my-10">
          <p>Your browsing history appears here</p>
        </div>
      ) : (
        history.map((e, i) => {
          return (
            <div
              onClick={(event) => {
                event.stopPropagation();
                reGenerate(e?.data, e?.image, e?.name);
              }}
              className="flex items-center justify-between  bg-white px-3 rounded-lg shadow-sm mt-2 cursor-pointer"
              key={i}
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">
                  <img src={e?.image} width="30px" alt="Logo" />
                </div>

                <p className="mt-3">{e.url}</p>
              </div>
              <div className="flex">
                <div
                  className="rounded-3xl bg-gray-100 px-2.5 py-1 mx-1 hover:bg-gray-400 hover:text-white cursor-pointer"
                  onClick={() => reGenerate(e?.data, e?.image)}
                >
                  <RedoOutlined />
                </div>
                <div
                  onClick={(elem) => handleDelete(e, elem)}
                  className="rounded-full bg-gray-100 px-2.5 py-1.5 mx-1 hover:bg-red-400 hover:text-white cursor-pointer"
                >
                  <DeleteOutlined />
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

History.propTypes = {
  reGenerate: PropTypes.func,
};
