import React, { useCallback, useEffect, useState } from "react";
import { FacebookAbTest } from "../../posts/facebook-ab-test";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import postAction from "../../../store/actions/post.actions";
import {
  // Btn,
  DropDown,
} from "../../forms";
import { next, previous } from "../../../assests/images";
import { Col, Row } from "antd";
import vsGif from "../../../assests/images/icons/vs.gif";

export const AbTest = ({
  state,
  setAbTests,
  load,
  abTests,
  abTypeA,
  abTypeB,
  headlineAbTestB,
  advanceObj,
  IndexChanger,
  type,
  // copyTypeSecondDimension,
  copyTypeFirstDimension,
  handleAbTestRemix,
  google,
  // handleNextDimesion,
  // handleSecondDimension,
  handleAbRemix,
  funnelStage,
  setFunnelStage,
  setAbTestSelect,
  setAbTesting,
  abTestSelected,
}) => {
  const post = useSelector((e) => e.post);
  const [currentOptionIndex, setCurrentOptionINdex] = useState(0);
  // const [checkNext, setCheckNext] = useState(true);
  // const [checkPrev, setCheckPrev] = useState(true);
  const [abTestOptions, setOptions] = useState([]);
  const prevAbText = () => {
    if (abTestSelected) {
      let selectedIndex = abTestOptions.find((x) => x.value === abTestSelected);
      console.log("aaaaa", selectedIndex);
      selectedIndex = selectedIndex.index;
      // if (selectedIndex < 5) {
      //   setCheckNext(true);
      // }
      if (selectedIndex > 0) {
        if (selectedIndex < abTestOptions.length) {
          setCurrentOptionINdex(selectedIndex - 1);
          setAbTestSelect(abTestOptions[selectedIndex - 1].value);
        }
      }
      // if (selectedIndex == 0) {
      //   setCheckPrev(false);
      // }
      // if (selectedIndex > 0) {
      //   setCheckPrev(true);
      // }
    }
  };
  const nexAbTest = () => {
    if (abTestSelected) {
      if (abTestSelected !== 0) {
        let selectedIndex = abTestOptions.find(
          (x) => x.value === abTestSelected
        );
        selectedIndex = selectedIndex.index;
        if (selectedIndex < abTestOptions.length - 1) {
          setCurrentOptionINdex(selectedIndex + 1);
          setAbTestSelect(abTestOptions[selectedIndex + 1].value);
        }
        // if (selectedIndex == 5) {
        //   setCheckNext(false);
        // }
        // if (selectedIndex > 0) {
        //   setCheckPrev(true);
        // }
        // if (selectedIndex < 5) {
        //   setCheckNext(true);
        // }
      } else {
        setCurrentOptionINdex(1);
        setAbTestSelect(abTestOptions[1].value);
      }
      if (currentOptionIndex + 1 < abTestOptions.length) {
        //find the index of current value and go to next from there.
      }
    }
  };
  const [, setAbTestVisible] = useState(true);

  const dispatch = useDispatch();
  const initAbTest = useCallback(() => {
    const type = post.abTest.type;
    if (type === "template/google") {
      setAbTestVisible(false);
    }
  }, [post.abTest.type]);

  useEffect(() => {
    initAbTest();
  }, [initAbTest]);

  useEffect(() => {
    const type = post.abTest.type;
    if (type === "fb" || type === "insta") {
      setOptions(fb);
    } else if (type === "twitter") {
      // setOptions(twitter);
    } else if (type === "tiktok") {
      // setOptions(tiktok);
    } else if (type === "ld") {
      setOptions(fb);
    } else if (type === "pinterest") {
      // setOptions(pinterest);
    } else if (type === "template/google") {
      // to-do if google
    }
  }, [post.abTest.type]);
  useEffect(() => {
    dispatch(postAction.selectAbTest({ value: abTestSelected }));
  }, [abTestSelected, dispatch]);
  console.log(abTests);
  return (
    <div className="text-center">
      <div
        style={{ backgroundColor: "#f0f2f5" }}
        className="flex fixed justify-between min-w-full border-b-2 z-10"
      >
        <div className="p-1">
          <p
            className="mt-3 cursor-pointer"
            onClick={() => setAbTesting((state) => !state)}
          >
            ← Quit A/b Testing menu
          </p>
        </div>

        <div className="flex items-center sticky  right-0">
          <div className="flex items-center mx-5">
            <DropDown
              selected={funnelStage}
              setSelected={(e) => {
                setAbTestSelect("");
                setFunnelStage(e);
              }}
              options={funnel}
              width="210px"
              value={funnelStage ? funnelStage : "Select a funnel"}
            />
          </div>
          <div className="flex items-center mx-4  ">
            <button
              onClick={prevAbText}
              className="p-2 bg-gray-900 h-9 text-white rounded-md"
            >
              <img src={previous} height="12" width="17" className="" />
            </button>
            <DropDown
              selected={funnelStage}
              width="410px"
              setSelected={(e) => {
                setFunnelStage("");
                setAbTestSelect(e);
              }}
              options={abTestOptions}
              value={abTestSelected ? abTestSelected : "Select Copy Type"}
            />
            <button
              onClick={nexAbTest}
              className="p-2 bg-gray-900 h-9 text-white text-center rounded-md"
            >
              <img src={next} height="12" width="17" />
            </button>
          </div>
        </div>
      </div>
      <div className="text-center py-10">
        {!funnelStage ? (
          <>
            {abTests &&
              !!abTests[0]?.length &&
              !!abTests[1]?.length &&
              abTests[0].map((elem, i) => {
                return (
                  <div className="h-full my-16" key={i}>
                    <Row>
                      <Col lg={24} xl={24} md={24} sm={12} xs={24}>
                        <div className="">
                          <div
                            style={{ minHeight: "27rem" }}
                            className="flex mt-0 justify-between mx-12 items-center text-left pr-12"
                          >
                            <Row
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Col lg={11} xl={11} md={24} sm={11} xs={22}>
                                <div className="p-2">
                                  <FacebookAbTest
                                    pcg={false}
                                    logo={state?.kalazify?.logo}
                                    text={state?.productName}
                                    setAbTests={setAbTests}
                                    load={load}
                                    d={elem}
                                    state={state}
                                    type={type}
                                    second={true}
                                    abType={abTypeA}
                                    headline={headlineAbTestB}
                                    handleAbRemix={handleAbTestRemix}
                                    IndexChanger={IndexChanger}
                                    advanceObj={advanceObj}
                                    abTypeA={abTypeA}
                                    primaryImg={google}
                                    changeHeadline={
                                      IndexChanger.changeHeadlineB
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={2} xl={2} md={24} sm={2} xs={2}>
                                <div className="text-center font-medium text-xl">
                                  <img src={vsGif} alt="vs" />
                                </div>
                              </Col>
                              <Col lg={11} xl={11} md={24} sm={11} xs={22}>
                                <div className="p-2">
                                  <FacebookAbTest
                                    logo={state?.kalazify?.logo}
                                    text={state?.productName}
                                    setAbTests={setAbTests}
                                    load={load}
                                    d={
                                      abTests[1].length >= i
                                        ? abTests[1][i]
                                        : abTests[1][0]
                                    }
                                    state={state}
                                    type={type}
                                    second={true}
                                    abType={abTypeB}
                                    headline={headlineAbTestB}
                                    handleAbRemix={handleAbRemix}
                                    IndexChanger={IndexChanger}
                                    advanceObj={advanceObj}
                                    primaryImg={google}
                                    abTypeA={abTypeA}
                                    changeHeadline={
                                      IndexChanger.changeHeadlineB
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </>
        ) : (
          <>
            {abTests &&
              !!abTests?.length &&
              !!abTests[0]?.length &&
              abTests[0].map((elem, i) => {
                return (
                  <div key={i} className="h-full my-16">
                    <Row>
                      <Col lg={24} xl={24} md={24} sm={12} xs={24}>
                        <div className="">
                          <div
                            style={{ minHeight: "27rem" }}
                            className="flex mt-0 justify-center mx-12 items-center pr-12"
                          >
                            <Row className="flex mt-0 justify-center mx-12 items-center pr-12">
                              <Col
                                lg={11}
                                xl={11}
                                md={24}
                                sm={11}
                                xs={22}
                                style={{
                                  minWidth: 440,
                                }}
                              >
                                <div className="p-2">
                                  <FacebookAbTest
                                    pcg={false}
                                    logo={state?.kalazify?.logo}
                                    text={state?.productName}
                                    setAbTests={setAbTests}
                                    load={load}
                                    d={abTests[copyTypeFirstDimension]}
                                    state={state}
                                    type={type}
                                    second={true}
                                    abType={abTypeA}
                                    headline={headlineAbTestB}
                                    handleAbRemix={handleAbTestRemix}
                                    IndexChanger={IndexChanger}
                                    advanceObj={advanceObj}
                                    abTypeA={abTypeA}
                                    primaryImg={google}
                                    changeHeadline={
                                      IndexChanger.changeHeadlineB
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </>
        )}
        {/* {abTests && abTests[0]?.length && abTests[1]?.length && (
          <div
            style={{ marginBottom: 45 }}
            className="bg-gray-100 p-4 rounded-sm   flex fixed left-20 right-0 bottom-0 justify-between"
          >
            <div>
              <Btn
                onClick={handleSecondDimension}
                // disabled={index > 0 ? false : true}
                value="previous"
                bgColor="#0E1526"
                color="white"
                load={false}
              />
            </div>
            <div>
              <Btn
                onClick={handleNextDimesion}
                // disabled={index >= maxIndex ? true : false}
                value="Next"
                bgColor="#0E1526"
                color="white"
                load={false}
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

AbTest.propTypes = {
  state: PropTypes.object,
  pcg: PropTypes.any,
  setAbTesting: PropTypes.any,
  setAbTests: PropTypes.any,
  load: PropTypes.any,
  d: PropTypes.any,
  type: PropTypes.any,
  second: PropTypes.any,
  abType: PropTypes.any,
  headline: PropTypes.any,
  handleAbRemix: PropTypes.any,
  IndexChanger: PropTypes.any,
  advanceObj: PropTypes.any,
  changeHeadlinechangeHeadlineB: PropTypes.any,
  template: PropTypes.any,
  copyTypeSecondDimension: PropTypes.any,
  copyTypeFirstDimension: PropTypes.any,
  abTypeA: PropTypes.any,
  abTypeB: PropTypes.any,
  abTests: PropTypes.any,
  headlineAbTestB: PropTypes.any,
  handeAbTestRemixFor2ndCard: PropTypes.any,
  google: PropTypes.any,
  handleAbTestRemix: PropTypes.any,
  handleNextDimesion: PropTypes.any,
  handleSecondDimension: PropTypes.any,
  funnelStage: PropTypes.any,
  setFunnelStage: PropTypes.any,
  abTestSelected: PropTypes.any,
  setAbTestSelect: PropTypes.any,
};

const fb = [
  {
    value: "Qualifying question VS Feature based copy",
    name: `1. Question-based copy VS Feature-based copy`,
    index: 0,
  },
  {
    value: "Qualifying question VS Narrative based copy",
    name: `2. Question-based copy VS Storytelling-based copy`,
    index: 1,
  },
  {
    value: "Qualifying question VS Testimonial based",
    name: `3.   Question-based copy VS Benefit-based copy`,
    index: 2,
  },
  {
    value: "Feature based copy VS Narrative based copy",
    name: `4.  Feature-based copy VS Storytelling-based copy`,
    index: 3,
  },
  {
    value: "Narrative based copy VS Testimonial based copy",
    name: `5.   Feature-based copy VS Benefit-based copy`,
    index: 4,
  },
  {
    value: "Long Form vs Short form copy",
    name: `6. Storytelling-based copy VS Benefit-based copy`,
    index: 5,
  },
];

const funnel = [
  {
    value: "Top of funnel",
    name: "Top of funnel",
  },
  {
    value: "Middle of funnel",
    name: "Middle of funnel",
  },
  {
    value: "Bottom of funnel",
    name: "Bottom of funnel",
  },
];
