import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";

export const AdPrimaryAbTest = ({ d, primaryImg, setCopyText, copyText }) => {
  const normalizeAbTest = () => {
    let text = "";
    // console.clear()
    d.length &&
      d.some((e, i) => {
        console.log("====> > >> ", e);
        if (e.isEdited) {
          if (i === 0) {
            text += e.onlyText;
            return true;
          }
        } else if (e?.template?.type === "template/pgc2") {
          let divided = e?.onlyText.split(":");
          let res = divided.reduce((accumaltor, current) => {
            let rep = current.replace("Pain", "");
            rep = rep.replace("Gain", "");
            rep = rep.replace("Claim", "");
            let fnf = (accumaltor += rep).trim();
            return fnf;
          }, "");
          text += res;
        } else if (e?.template?.type === "template/listoffeatures_s") {
          text += formAtGpt3(e?.onlyText);
        } else {
          text += i === d.length - 1 ? e?.onlyText : e?.onlyText + "<br />"; // removing the extra space after end
        }
        return false;
      });
    return text;
  };
  const sperateInputBySpecialCharacter = (string) => {
    if (!string) return string;
    var formattedString = string;
    formattedString = formattedString.split("! ").join(".<br />");
    formattedString = formattedString.split("? ").join(".<br />");
    formattedString = formattedString.split(".\n");

    formattedString = formattedString.join(".<br />");

    if (
      formattedString[formattedString.length - 1] !== "." &&
      formattedString[formattedString.length - 1] !== "!" &&
      formattedString[formattedString.length - 1] !== "?" &&
      formattedString[formattedString.length - 1] !== ">"
    ) {
      formattedString += ".";
    }

    return formattedString;
  };
  const structureHtml = (primaryText) => {
    // primart text here is array of strings
    if (primaryText?.length) {
      let result = `<div
      style="line-height: 1.5;margin-bottom: 0.5rem; text-align: left"
      class="rotate-scale-up-ver"
      >
        ${sperateInputBySpecialCharacter(primaryText)} <br />
     
      </div>`;
      return result;
    }
    return primaryText;
  };

  const generateEditableContent = () => {
    if (!d?.length) return;
    const content = structureHtml(normalizeAbTest());
    return content;
  };
  const formAtGpt3 = (string) => {
    if (!string) return string;
    var formattedString;
    formattedString = string?.split("✅");
    if (formattedString.length > 1) {
      let temp = "";
      formattedString.forEach((e, i) => {
        if (i !== 0 && i !== 1) {
          temp += `<br />✅${e}`;
        } else if (i === 1) {
          temp += `✅${e}`;
        }
      });
      formattedString = temp;
      return String(formattedString);
    }
    return string;
  };
  useEffect(() => {
    let editableContent = generateEditableContent();
    setCopyText(editableContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);
  return (
    <div className="ml-2">
      <ContentEditable
        html={copyText} // innerHTML of the editable div
      />
      <div>
        <img
          src={primaryImg}
          className="w-full h-48 image-body"
          alt="profile"
        />
      </div>
    </div>
  );
};

AdPrimaryAbTest.propTypes = {
  d: PropTypes.any,
  primaryImg: PropTypes.any,
  copyText: PropTypes.any,
  setCopyText: PropTypes.any,
};
