import React, { useState } from "react";
import { TextInput, Btn } from "../../../components/forms";
import { Link } from "react-router-dom";
import "./scss/index.scss";
import { emailSchema } from "../../../Validations/UserValidation";
import { authService } from "../../../services";
import { Navbar } from "../../../components";
import { useDispatch } from "react-redux";
import { modalAction } from "../../../store/actions";

export const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const handleForGetPassword = async () => {
    try {
      const isValid = await emailSchema.validate({ email });
      if (!isValid) return;
      const data = { email: email.toLocaleLowerCase() };
      const response = await authService.resetPassword(data);
      if (response?.data?.msg) {
        alert(response.data.msg);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        dispatch(
          modalAction.error({
            title: err.errors,
          })
        );
      }
    }
  };
  return (
    <>
      <Navbar signout={true} />
      <div className="flex flex-col justify-center items-center h-screen xs:overflow-hidden w-screen">
        <div className="card p-10">
          <h1>Forget Password</h1>
          <p>
            Oops! Looks like you forgot your password, enter your email to
            Reset.
          </p>
          <div className="w-full pb-2 pt-2">
            <label htmlFor="email">Email address</label>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="pb-2 pt-2 text-center">
            <Btn
              onClick={handleForGetPassword}
              value="Send Link"
              bgColor="#1e2a3b"
              color="#ffffff"
            />
            <Link to="/" className="mt-5 homeBtn">
              Go Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
