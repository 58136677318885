import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import "./scss/index.scss";
import { coin, logoGif } from "../assests/images";
import { logoName } from "../assests/images";
import { UserPanelRouting } from "./user-panel-routing";
import { Brain } from "../modules";
import { MenuItems } from "./sidebar-data/menu-items";
import { authActions } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { MobileSidebar } from "./sidebar-data/mobileSidebar";

export const ApplicationLayout = () => {
  const [collapsed, setCollasped] = useState(true);
  const [renderBrain, setRenderBrain] = useState(false);
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const dispatch = useDispatch();
  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("auth"));
    if (!user) {
      dispatch(authActions.getUser(u?.user?._id, "_"));
    }
  }, [dispatch, user]);
  return (
    <>
      {!renderBrain ? (
        <>
          <MobileSidebar className="my-5" />
          <Layout style={{ minHeight: "auto" }} className="sidebar ">
            {/* <MobileSidebar /> */}

            <Layout.Sider
              collapsible
              collapsed={collapsed}
              onCollapse={() => setCollasped(!collapsed)}
              className="sidebar-menu "
            >
              <span className="justify-between-full ">
                <span className="displaySideba">
                  <div
                    className={
                      collapsed ? "uni-icon-small uni-icon " : "uni-icon flex"
                    }
                  >
                    <img src={logoGif} className="cursor-pointer h-16" />
                    {collapsed ? (
                      <></>
                    ) : (
                      <img
                        src={logoName}
                        width="120px"
                        className="mt-3 logo-name"
                      />
                    )}
                  </div>
                  <hr />
                  <MenuItems />
                </span>
                <div
                  className={
                    collapsed
                      ? "bottom-10 left-4 absolute flex justify-center"
                      : "bottom-10 absolute ml-2 flex items-center customColor"
                  }
                >
                  <img
                    src={coin}
                    width="50px"
                    className="cursor-pointer h-16"
                  />
                  {collapsed ? (
                    <></>
                  ) : user?.planType === "agency" ? (
                    <div className="justify-center flex align-self-end">
                      <p style={{ fontSize: 25, marginTop: 20 }}>∞</p>
                    </div>
                  ) : (
                    <>{user?.coins}</>
                  )}
                </div>
              </span>
            </Layout.Sider>

            <Layout.Content
              className={
                collapsed ? "collapsed-content-layout" : "content-layout"
              }
            >
              <UserPanelRouting
                path="/userpanel"
                setRenderBrain={setRenderBrain}
              />
            </Layout.Content>
          </Layout>
        </>
      ) : (
        <Brain setRenderBrain={setRenderBrain} renderBrain={renderBrain} />
      )}
    </>
  );
};
