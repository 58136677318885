import React from "react";
import "./scss/index.scss";
import { Avatar } from "antd";
import PropTypes from "prop-types";

export const UserProfile = (props) => {
  return (
    <div className="flex justify-center items-center">
      <div className="hidden sm:block">
        {props.img ? (
          <img
            src={
              props.img
                ? props.img
                : "https://avatars.dicebear.com/api/personas/${Math.random()}.svg"
            }
            className={`w-20 xs:w-10 sm:w-10` + props?.class}
          />
        ) : (
          <Avatar
            size={"large"}
            src={
              <img
                src={
                  props.img
                    ? props.img
                    : `https://avatars.dicebear.com/api/personas/${Math.random()}.svg`
                }
                className={
                  `xs:w-10 sm:w-10` + props.class ? `w-20` : props?.class
                }
              />
            }
            className={props.img ? "" : "avatar-img"}
          />
        )}
      </div>
      <div className="sm:pl-3 headings">
        <h1>{props.title}</h1>
        <h2> {props.subtitle} </h2>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  img: PropTypes.string,
  class: PropTypes.string,
};
