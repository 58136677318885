import React from "react";
import ContentEditable from "react-contenteditable";
import _ from "lodash";
import PropTypes from "prop-types";

class EditableContentGoogle extends React.Component {
  constructor() {
    super();
    this.contentEditable = React.createRef();
    this.state = {
      html: "",
      color: "#222222",
    };
  }

  componentDidMount() {
    if (this.props.color === "white") {
      this.setState({ color: "white" });
    } else {
      this.setState({ color: "#222222" });
    }
    this.structureHtml();
  }

  handleChange = (evt) => {
    this.setState({ html: evt.target.value });
    this.props.setCopyText(evt.target.value);
    // this.props.saveCurrentEditedText(evt.target.value);
  };

  createHtml = () => {
    this.structureHtml();
  };

  structureHtml = () => {
    const { data } = this.props;
    if (data?.length) {
      let result = `<div
      style="color:${this.state.color};line-height: 1.9rem;
      class="scale-up-center"
      >
        ${data} <br />
      </div>`;
      this.setState({ html: result });
    }
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.createHtml(true, true, true, true);
    }
  }

  formAtGpt3 = (string) => {
    if (!string) return string;
    var formattedString;
    formattedString = string?.split("✅");
    if (formattedString.length > 1) {
      let temp = "";
      formattedString.forEach((e, i) => {
        if (i !== 0 && i !== 1) {
          temp += `<br />✅${e}`;
        } else if (i === 1) {
          temp += `✅${e}`;
        }
      });
      formattedString = temp;
      return this.sperateInputBySpecialCharacter(formattedString);
    }
    return this.sperateInputBySpecialCharacter(string);
  };

  sperateInputBySpecialCharacter = (string) => {
    if (!string) return string;
    var formattedString = string.split(". ").join(".");
    formattedString = formattedString.split("! ").join(".<br />");
    formattedString = formattedString.split("? ").join(".<br />");
    formattedString = formattedString.split(".\n");
    let temp = "";
    formattedString.forEach((e, i) => {
      if (i !== 0 && i !== formattedString.length - 1) {
        temp += `.<br/>${e}`;
      } else {
        temp += e;
      }
    });
    formattedString = temp;
    if (
      formattedString[formattedString.length - 1] !== "." &&
      formattedString[formattedString.length - 1] !== "!" &&
      formattedString[formattedString.length - 1] !== "?"
    ) {
      formattedString += ".";
    }

    return formattedString;
  };

  render = () => {
    return (
      <>
        {this.state.html && (
          <ContentEditable
            innerRef={this.contentEditable}
            html={this.state.html} // innerHTML of the editable div
            disabled={false} // use true to disable editing
            onChange={this.handleChange} // handle innerHTML change
            tagName="article" // Use a custom HTML tag (uses a div by default)
            style={{
              color: this.state.color,
            }}
          />
        )}
      </>
    );
  };
}

export default EditableContentGoogle;

EditableContentGoogle.propTypes = {
  color: PropTypes.any,
  setCopyText: PropTypes.any,
  data: PropTypes.any,
};
