import React, { useEffect, useState } from "react";
import { AdHeader } from "../post-components/header";
import { AdHeadline } from "../post-components/headline";
import { AdPrimary } from "../post-components/primary";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Bubble from "../../../modules/Buble";
import CommentNLikes from "../like-comments";

export const LinkedInAdCopy = ({
  logo,
  state,
  text,
  primaryImg,
  primaryText,
  headline,
  setHeadlineIndex,
  onClick,
  score,
  wirteClipboardDesc,
  wirteClipboardHeadline,
  type,
  advanceObj,
  IndexChanger,
  showCase,
  handleShowCase,
  commentState,
  loadShowCase,
  view,
}) => {
  const [, setIsPrev] = useState(false);
  const [, setIsEidted] = useState(false);
  const { copyIndex } = useSelector((e) => e.post);

  let d = state?.statticType;

  useEffect(() => {
    setIsEidted(false);
    setIsPrev(false);
  }, [d, copyIndex]);
  return (
    <div
      style={{ boxShadow: "0px 1px 3px rgb(0 0 0 / 20%)", maxWidth: 440 }}
      className="w-100 border-2 mx-auto container bg-white rounded-lg"
    >
      {" "}
      <AdHeader
        onClick={onClick}
        title={text}
        score={score}
        logo={logo}
        handleShowCase={handleShowCase}
        wirteClipboardDesc={wirteClipboardDesc}
        showCase={showCase}
        view={view}
        loadShowCase={loadShowCase}
      />
      <AdPrimary
        primaryImg={primaryImg}
        primaryText={primaryText}
        type={type}
        advanceObj={advanceObj}
        IndexChanger={IndexChanger}
      />
      <AdHeadline
        setHeadlineIndex={setHeadlineIndex}
        wirteClipboardHeadline={wirteClipboardHeadline}
        headline={headline}
        advanceObj={advanceObj}
        IndexChanger={IndexChanger}
      />
      {showCase && <Bubble audience={state?.showcase?.audience} />}
      {showCase && <CommentNLikes commentState={commentState} misc={true} />}
    </div>
  );
};

LinkedInAdCopy.propTypes = {
  state: PropTypes.any,
  text: PropTypes.any,
  primaryImg: PropTypes.any,
  primaryText: PropTypes.any,
  headline: PropTypes.any,
  logo: PropTypes.any,
  setHeadlineIndex: PropTypes.any,
  onClick: PropTypes.any,
  score: PropTypes.number,
  wirteClipboardDesc: PropTypes.func,
  wirteClipboardHeadline: PropTypes.func,
  type: PropTypes.any,
  IndexChanger: PropTypes.any,
  view: PropTypes.number,
  showCase: PropTypes.bool,
  advanceObj: PropTypes.any,
  handleShowCase: PropTypes.any,
  commentState: PropTypes.any,
  loadShowCase: PropTypes.any,
};
