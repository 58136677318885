import { Select } from "antd";

const { Option } = Select;
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import postAction from "../../../store/actions/post.actions";

export const DropDown = ({
  options,
  defaultValue,
  setSelected,
  width,
  value,
}) => {
  const dispatch = useDispatch();

  function handleChange(value) {
    setSelected(value.value);
    dispatch(postAction.funnelStage({ value: value.label }));
  }
  return (
    <Select
      placeholder="select"
      labelInValue
      // defaultValue={{ value: defaultValue }}
      value={value}
      defaultValue={defaultValue}
      style={{ width: width }}
      onChange={(e) => handleChange(e)}
    >
      {!!options?.length &&
        options.map((e, i) => (
          <Option key={i} value={e.value}>
            {e.name}
          </Option>
        ))}
    </Select>
  );
};

DropDown.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  setSelected: PropTypes.func,
};
