import { Divider, Badge } from "antd";
import React, { useState } from "react";
import { Btn } from "../../forms";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Points } from "./points";

export const AgencyPlan = ({ createCheckoutSession }) => {
  const [load, setLoad] = useState(false);
  const { loggedInUser } = useSelector((e) => e.authentication);

  const handlePlan = () => {
    setLoad(true);
    createCheckoutSession("agency");
  };
  const features = [
    "Everything in UnicornCopy.AI 🦄",
    "Unlimited Ad Creative Insights searches p/mo",
    "+8 most decisive Ad Creative Strategy insights",
    "All market niches available inside",
    "Ad Types university (private course)",
    "+23.8% Ad Creative Performance on average",
  ];
  return (
    <Badge.Ribbon text="Recommended" color="cyan">
      <div className="agencyplan-card p-1 flex flex-col justify-center items-center rounded-full">
        <div className="bg-white p-1 flex flex-col justify-center items-center rounded-sm">
          <h1>Agency Plan</h1>
          <h6>
            For agencies looking to serve their clients with state-of-the-art Ad
            Creative strategies.
          </h6>
          <Divider />
          <div className="flex items-baseline justify-center">
            <h2>$199</h2> <h3>/month</h3>
          </div>
          <h6>Never have a failed Ad Creative again.</h6>
          <div className="p-5">
            {features?.map((value, index) => {
              return <Points key={index} point={value} />;
            })}
            <div className="p-3">
              <Btn
                disabled={
                  loggedInUser.user?.planType === "agency" ? true : false
                }
                load={load}
                onClick={
                  loggedInUser.user?.planType === "agency" ? null : handlePlan
                }
                value={
                  loggedInUser.user?.planType === "agency"
                    ? "Subcribed"
                    : loggedInUser.user?.planType === "growth"
                    ? "Upgrade your plan"
                    : "Go with this one"
                }
                color="#ffffff"
                bgColor="linear-gradient(132deg, rgba(58,168,180,1) 1%, rgba(131,58,180,1) 32%, rgba(253,29,29,1) 62%, rgba(252,176,69,1) 100%)"
              />
            </div>
          </div>
        </div>
      </div>
    </Badge.Ribbon>
  );
};

AgencyPlan.propTypes = {
  createCheckoutSession: PropTypes.any,
};
