import { MainRouting } from "./layout/router";
import "./global.css";
import "./app.scss";
function App() {
  return (
    <>
      <MainRouting />
    </>
  );
}

export default App;
