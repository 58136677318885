import React from "react";
import { UserPanelMenuItems } from "../user-panel-routing";

export const MenuItems = () => {
  // const pathname = window.location.pathname;
  // let pathForSideMenu = pathname.replace("/unicorncopy/", "");
  return (
    <div>
      <UserPanelMenuItems />
    </div>
  );
};
