import React, { useEffect, useState } from "react";
import "./scss/index.scss";
import { showcaseNotFound } from "../../assests/images";
import { showcaseService } from "../../services";
import { Btn, Content } from "../../components";
import { logoGif, showcaseImg } from "../../assests/images";
import Headershowcase from "../../components/Header-showcase";
import { UserProfile } from "../../components/user-profile";
import { useNavigate } from "react-router-dom";
import { FilterShowCase } from "../Filter-showcase";
import MobileDropdown from "../../components/mobile-Dropdown";
// import ReactPaginate from "react-paginate";
// import NEXT_ICON from "../../assests/images/icons/next-icon.png";
// import PREV_ICON from "../../assests/images/icons/Prev.png";

export const Showcase = () => {
  const [, setShowCase] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const navigate = useNavigate();
  const [currentComment, setCurrentComment] = useState("");
  const [currIndex, setCurrIndex] = useState({
    start: 0,
    end: 10,
  });

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const handlePageClick = (prev) => {
    if (prev.length) {
      let temp = [];
      let end = prev.length >= 10 ? 10 : prev.length;
      for (let i = 0; i < end; i++) {
        temp.push(prev[i]);
      }
      setCurrentItems((e) => [...e, ...prev]);
    }
    if (originalData?.length && currIndex.end < originalData?.length) {
      let temp = [];
      let end =
        currIndex.end + 10 > originalData?.length
          ? originalData?.length
          : currIndex.end;
      for (let i = currIndex.start; i < end; i++) {
        temp.push(originalData[i]);
      }
      setCurrentItems((e) => [...e, ...temp]);
    }
    setCurrIndex((e) => ({
      start: e.start + 10,
      end: e.end + 10,
    }));
  };
  const getData = async () => {
    try {
      setLoading(true);
      const res = await showcaseService.get();
      console.log("data => ", res);
      if (!res?.data?.result) {
        setLoading(false);
        return;
      }
      setShowCase(res?.data?.result);
      let types = [...new Set(res?.data?.result.map((e) => e.type))];
      let renamedTypes = types.map((e) => {
        switch (e) {
          case "fb_primary":
            return { name: "Facebook Primary", type: e };
          case "tiktok_ad_copy":
            return { name: "Tiktok", type: e };
          case "fb":
            return { name: "Facebook", type: e };
          case "fb_headline":
            return { name: "Facebook Headline", type: e };
          case "unique":
            return { name: "Unique value proposition", type: e };
          case "perfect_headline":
            return { name: "Perfect headline", type: e };
          case "google_headline":
            return { name: "Google headline", type: e };
          case "ld":
            return { name: "Linkedin", type: e };
          default:
            return { name: e, type: e };
        }
      });
      setFilters([{ name: "All", type: "All" }, ...renamedTypes]);
      setOriginalData(res?.data?.result);
      setLoading(false);
      handlePageClick(res?.data?.result);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = (filter_type) => {
    if (!filter_type) return;
    if (filter_type === "All") {
      setShowCase(originalData);
      // return;
      setCurrentItems(originalData);
    } else {
      let filteredData = originalData.filter((e) => e.type === filter_type);
      setShowCase(filteredData);
      setCurrentItems(filteredData);
    }
    setSelectedFilter(filter_type);
  };

  const handleSearch = async (keyword) => {
    console.log(keyword);
    setLoading(true);
    const res = await showcaseService.search({ audience: keyword });
    if (!res?.data?.result) {
      setLoading(false);
      return;
    }
    let types = [...new Set(res?.data?.result.map((e) => e.type))];
    let renamedTypes = types.map((e) => {
      switch (e) {
        case "fb_primary":
          return { name: "Facebook Primary", type: e };
        case "tiktok_ad_copy":
          return { name: "Tiktok", type: e };
        case "fb":
          return { name: "Facebook", type: e };
        case "fb_headline":
          return { name: "Facebook Headline", type: e };
        case "unique":
          return { name: "Unique value proposition", type: e };
        case "perfect_headline":
          return { name: "Perfect headline", type: e };
        case "google_headline":
          return { name: "Google headline", type: e };
        case "ld":
          return { name: "Linkedin", type: e };
        default:
          return { name: e, type: e };
      }
    });
    // console.log(res?.data?.result);
    setFilters([{ name: "All", type: "All" }, ...renamedTypes]);
    setOriginalData(res?.data?.result);
    setShowCase(res?.data?.result);
    setLoading(false);
  };
  return (
    <>
      <div className="mt-10 lg:ml-10 haeder-container-showcase flex flex-wrap justify-between">
        <UserProfile
          title={`Discover Creative Generation`}
          subtitle=" The first creative community for generations, built by Unicorns,
          like you, yes, you."
          img={showcaseImg}
        />

        <div className="brain rounded-lg generat-showcase">
          <Btn
            value="Generate New"
            bgColor="#f0f2f5"
            color="black"
            border="8px"
            onClick={() => {
              // props.setRenderBrain(true);
              navigate("/unicorncopy/userpanel/templates");
            }}
            cards
            icon={<img src={logoGif} width="20px" />}
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-between mt-10">
        <div className="search-main-contianer">
          {!!currentItems?.length && (
            <>
              <Headershowcase
                selectedFilter={selectedFilter}
                filters={filters}
                handleFilter={handleFilter}
              />
              <MobileDropdown
                selectedFilter={selectedFilter}
                filters={filters}
                handleFilter={handleFilter}
              />
            </>
          )}
        </div>

        <div className="search-input-main-container">
          <FilterShowCase handleSearch={handleSearch} />
        </div>
      </div>
      <div className="imgDiv">
        {/* <img src={showcaseBackground} alt="coming soon" width="900px" /> */}
        {loading && (
          <img
            src={logoGif}
            width="100px"
            className="h-24 mr-auto ml-auto mt-10 object-cover"
          />
        )}
        {!currentItems?.length && !loading && (
          <>
            <div className="flex flex-col justify-center	items-center	">
              <img src={showcaseNotFound} width="400px" />
              <div className="brain mt-5 rounded-lg generat-showcase">
                <Btn
                  start={{ width: 200 }}
                  value="Generate Now "
                  bgColor="#f0f2f5"
                  color="black"
                  border="8px"
                  onClick={() => {
                    // props.setRenderBrain(true);
                    navigate("/unicorncopy/userpanel/templates");
                  }}
                  cards
                  icon={<img src={logoGif} width="20px" />}
                />
              </div>
            </div>
          </>
        )}
        {!!currentItems?.length &&
          !loading &&
          currentItems.map((e, i) => {
            return (
              <span className="container-show-Case" key={i}>
                <Content
                  data={e.data}
                  t={e.type}
                  cardData={e?.data}
                  view={e.view}
                  commentState={{
                    currentComment,
                    setCurrentComment,
                    _id: e?._id,
                    uid: localStorage.getItem("uid"),
                    liked: e?.liked,
                    like_doc: e?.like_doc,
                    currentItems,
                    setCurrentItems,
                    index: i,
                    likes: e?.likes,
                    commentsNumber: e?.commentsNumber,
                  }}
                  headline={e?.headline}
                  logo={e.logo}
                  state={{
                    kalazify: { logo: e.logo },
                    productName: e?.uid?.username,
                    google: e?.googleCopy,
                    googleBody: e?.googleBody,
                    showcase: {
                      audience: e?.audience,
                    },
                  }}
                  google={e.google}
                  showCase={true}
                />
              </span>
            );
          })}
      </div>
      {!loading &&
        !!currentItems?.length &&
        currIndex.end < originalData.length && (
          <div className="loader" key={0}>
            <Btn
              cards
              bgColor="#f0f2f5"
              color="black"
              border="8px"
              value={"Load More"}
              onClick={handlePageClick}
            />
          </div>
        )}
      {/* {!!showcase?.length && currentItems?.length && (
        <div className="w-full flex justify-center mt-10">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex justify-between container-paginate"
            // onPageChange={handlePageClick}
            // pageRangeDisplayed={5}
            // pageCount={pageCount}
            onPageChange={handlePageClick}
            renderOnZeroPageCount={null}
            // pageCount={pageCount}
            pageLinkClassName="pageClass"
            activeClassName="active_link"
            nextLabel={
              <button>
                <img src={NEXT_ICON} alt="next-icon" style={{ width: 80 }} />
              </button>
            }
          />
        </div>
      )} */}
    </>
  );
};
