import { PromiseModal } from "../promise-modal/index.js";
import { useDispatch, useSelector } from "react-redux";
import { modalAction } from "../../store/actions";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
export const TransitionModal = () => {
  const modal = useSelector((e) => e.modal);
  const dispatch = useDispatch();
  const handleModal = () => dispatch(modalAction.close());
  // const handleExternelFunction = () => {
  //   modal?.data?.handleOk();
  //   handleModal(false);
  // };
  return (
    <PromiseModal
      modalType={modal?.type}
      icon={
        modal?.type === "success" ? (
          <CheckCircleTwoTone twoToneColor={"#4BB543"} />
        ) : modal?.type === "warning" ? (
          <ExclamationCircleTwoTone twoToneColor={"#EED202"} />
        ) : (
          <CloseCircleTwoTone twoToneColor={"#FF9494"} />
        )
      }
      title={modal?.data?.title}
      content={modal?.data?.content}
      closable={true}
      onCancel={() => handleModal(false)}
      centered={true}
      visible={modal.show}
    />
  );
};
