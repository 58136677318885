import { SendOutlined } from "@ant-design/icons";
import { Avatar, Comment, Input, Tooltip } from "antd";
import React, { useState } from "react";
import { showcaseService } from "../../../services";
import PropTypes from "prop-types";
import { formatDistance } from "date-fns";

const Comments = ({ commentState }) => {
  const [comment, setComment] = useState("");
  const { uid, _id, data, handleComment } = commentState;
  const handleComments = async () => {
    if (!comment) return;
    const res = await showcaseService.addComment({
      uid,
      showcase_id: _id,
      comment,
    });
    console.log(res);
    setComment();
    handleComment();
  };
  return (
    <div className="auto-comment-scroll">
      <div className="flex align-middle items-center">
        <Input
          placeholder="Write a comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="comment-ipn"
        />
        <span className="border-send-btn mx-2">
          <SendOutlined onClick={handleComments} style={{ color: "white" }} />
        </span>
      </div>
      {!!data?.length &&
        data.map((e, i) => {
          return (
            <Comment
              key={i}
              author={<a>{e?.uid?.fullname}</a>}
              avatar={
                <Avatar
                  src={`https://avatars.dicebear.com/api/personas/${Math.random()}.svg`}
                  alt="Han Solo"
                  className="commentAuthorAvatar"
                />
              }
              content={<p className="semibold600 ">{e?.comment}</p>}
              datetime={
                <Tooltip title="2016-11-22 11:22:33">
                  <span>
                    {formatDistance(new Date(e?.createdAt), new Date())}
                  </span>
                </Tooltip>
              }
            />
          );
        })}
    </div>
  );
};

export default Comments;
Comments.propTypes = {
  commentState: PropTypes.any,
};
