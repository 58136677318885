import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import {
  facebookAd,
  facebookHeadline,
  facebookPrimeryText,
  linkedInAdCopy,
  googleAdCopy,
  googleAdHeadline,
  googleAdDescription,
  tiktokAdCopy,
  aida,
  problemAagitateSolution,
  featureToBenefit,
  engagingQues,
  perfectHeadline,
  persuasiveBulletPoint,
  uniqueValue,
  component270_29,
} from "../../../../assests/images";
import FooterGenerations from "../../../../modules/ad-footer";
import { renderToString } from "react-dom/server";

export const AdPrimary = ({
  primaryText,
  primaryImg,
  type,
  imageHidden,
  advanceObj,
  IndexChanger,
}) => {
  const [, setTypeImage] = useState(facebookAd);
  const sperateInputBySpecialCharacter = (string) => {
    if (!string || !string?.length) return string;
    var formattedString = string;
    formattedString = formattedString.split("! ").join(".<br />");
    formattedString = formattedString.split("? ").join(".<br />");
    formattedString = formattedString.split(".\n");

    formattedString = formattedString.join(".<br />");

    if (
      formattedString[formattedString.length - 1] !== "." &&
      formattedString[formattedString.length - 1] !== "!" &&
      formattedString[formattedString.length - 1] !== "?" &&
      formattedString[formattedString.length - 1] !== ">"
    ) {
      formattedString += ".";
    }

    return formattedString;
  };
  const FreeShipping = () =>
    renderToString(
      <>
        {!!advanceObj?.freeShipping?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableFreeShipping}
            noIcon={true}
            title={advanceObj.freeShipping}
          />
        )}
      </>
    );

  // const CTAUrl = () =>
  //   renderToString(
  //     <>
  //       {!!advanceObj?.ctaUrl?.text?.length && (
  //         <FooterGenerations
  //           noInput={true}
  //           changeStatus={IndexChanger.disableDiscount}
  //           noIcon={true}
  //           title={advanceObj?.ctaUrl}
  //         />
  //       )}
  //     </>
  //   );
  const Discount = () =>
    renderToString(
      <>
        {!!advanceObj?.discount?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableDiscount}
            noIcon={true}
            title={advanceObj?.discount}
          />
        )}
      </>
    );
  const Location = () =>
    renderToString(
      <>
        {!!advanceObj?.location?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableLocation}
            title={advanceObj?.location}
            noIcon={true}
          />
        )}
      </>
    );
  const structureHtml = (primaryText) => {
    // primart text here is array of strings
    if (primaryText?.length) {
      let result = primaryText.reduce((accumlator, currentValue) => {
        if (currentValue.pain || currentValue.claim || currentValue.gain) {
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.pain} <br />
            </p>`;
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.gain} <br />
          </p>`;
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.claim} <br />
            </p>`;
        } else {
          accumlator += currentValue;
        }
        return accumlator;
      }, "");
      result += FreeShipping();
      result = Location() + result;
      result += Discount();
      return result;
    }
    return primaryText;
  };

  const generateEditableContent = () => {
    let content = sperateInputBySpecialCharacter(structureHtml(primaryText));
    return content;
  };
  useEffect(() => {
    if (type === "fb") {
      setTypeImage(facebookAd);
    } else if (type === "ld") {
      setTypeImage(linkedInAdCopy);
    } else if (type === "google") {
      setTypeImage(googleAdCopy);
    } else if (type === "tiktok_ad_copy") {
      setTypeImage();
    }
    switch (type) {
      case "fb":
        setTypeImage(facebookAd);
        break;
      case "ld":
        setTypeImage(linkedInAdCopy);
        break;
      case "google":
        setTypeImage(googleAdCopy);
        break;
      case "tiktok_ad_copy":
        setTypeImage(tiktokAdCopy);
        break;
      case "fb_primary":
        setTypeImage(facebookPrimeryText);
        break;
      case "fb_headline":
        setTypeImage(facebookHeadline);
        break;
      case "google_headline":
        setTypeImage(googleAdHeadline);
        break;
      case "perfect_headline":
        setTypeImage(perfectHeadline);
        break;
      case "website_sub_headline":
        setTypeImage(component270_29);
        break;
      case "google_description":
        setTypeImage(googleAdDescription);
        break;
      case "aida":
        setTypeImage(aida);
        break;
      case "pas":
        setTypeImage(problemAagitateSolution);
        break;
      case "ftb":
        setTypeImage(featureToBenefit);
        break;
      case "engaging_question":
        setTypeImage(engagingQues);
        break;
      case "persuasive_bullet_points":
        setTypeImage(persuasiveBulletPoint);
        break;
      case "unique":
        setTypeImage(uniqueValue);
        break;
      default:
        setTypeImage(facebookAd);
        break;
    }
  }, [type]);

  return (
    <div>
      <div className="px-2">
        <ContentEditable
          html={generateEditableContent()} // innerHTML of the editable div
        />
      </div>
      {!imageHidden && (
        <div
        // style={{
        //   backgroundImage: `url(${primaryImg})`,
        //   backgroundPosition: "center center",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
        // className="w-full h-48"
        >
          <img
            src={primaryImg}
            className="w-full h-full image-body"
            alt="profile"
          />
        </div>
      )}
    </div>
  );
};

AdPrimary.propTypes = {
  primaryText: PropTypes.any,
  primaryImg: PropTypes.any,
  type: PropTypes.any,
  imageHidden: PropTypes.Boolean,
  IndexChanger: PropTypes.any,
  advanceObj: PropTypes.any,
};
