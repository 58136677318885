import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import "./scss/index.scss";

export const TextArea = (props) => {
  const { TextArea } = Input;
  return (
    <TextArea
      disabled={props.disabled}
      rows={props.rows}
      id={props.id}
      placeholder={props.placeholder}
      className="input"
      resize="none"
      onChange={props.onChange}
      maxLength={props.maxLength}
      bordered={false}
      value={props.value}
    />
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.any.isRequired,
  rows: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  disabled: PropTypes.any,
  value: PropTypes.string,
  id: PropTypes.any,
};
