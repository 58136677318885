import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const check = (p) => {
  const token = localStorage.getItem("token");
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (token && auth?.user?.planType === "free_trial") {
    if (p.pathname === "/userpanel/payments") return true;
    const subEnd = new Date(auth.user.free_trial_ends);
    const now = new Date();
    //
    if (dateDiffInDays(now, subEnd) >= 0) return true;
    // const path = global.location.pathname;
    window.location.href = "/packages?free_trial=true";
    return true;
  } else if ((token && auth.user.subcriptionId) || p.pathname === "payments") {
    return true;
  } else {
    if (token) {
      window.location.href = "unicorncopy/userpanel";
    }
  }
  return false;
};

export const PrivateRoute = ({ redirectTo }) =>
  check(location.pathname) ? <Outlet /> : <Navigate to={redirectTo} />;

PrivateRoute.propTypes = {
  children: PropTypes.any,
  redirectTo: PropTypes.any,
};
