import React from "react";
import { changer, textCopy } from "../../../../assests/images";
import PropTypes from "prop-types";
import FooterGenerations from "../../../../modules/ad-footer";
import Views from "../View";

export const AdHeadline = (props) => {
  return (
    <div className="px-4">
      <div className="flex justify-between items-center py-5">
        <div>{props?.headline?.text[props.headline.index]}</div>
        {!props.noIcon && (
          <div className="flex justify-between items-center">
            {!props.showCase && (
              <div
                className="mx-2 cursor-pointer hover:bg-gray-100 rounded-full p-2"
                onClick={props.setHeadlineIndex}
              >
                <img
                  src={changer}
                  className="cursor-pointer hover:shadow-sm rounded-md headerIcon min-wd-15"
                  width={"14px"}
                />
              </div>
            )}

            <div className="cursor-pointer hover:bg-gray-100 rounded-full p-2">
              <img
                src={textCopy}
                className="cursor-pointer headerIcon min-wd-15"
                width={"15px"}
                onClick={props.wirteClipboardHeadline}
              />
            </div>
          </div>
        )}
      </div>
      {!!props?.advanceObj?.ocassion?.text?.length && (
        <FooterGenerations
          changeStatus={props?.IndexChanger.disableOcassion}
          title={props.advanceObj?.ocassion}
          noIndex={false}
          changeIndex={props.IndexChanger.changeIndexOcassion}
        />
      )}
      {false && <Views view={props.view} />}
    </div>
  );
};

AdHeadline.propTypes = {
  headline: PropTypes.any,
  setHeadlineIndex: PropTypes.any,
  wirteClipboardHeadline: PropTypes.func,
  noIcon: PropTypes.bool,
  advanceObj: PropTypes.any,
  IndexChanger: PropTypes.any,
  showCase: PropTypes.bool,
  view: PropTypes.number,
};
