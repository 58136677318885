import React, { useEffect, useState } from "react";
import rs from "text-readability";
import sanitizeHtml from "sanitize-html";
import PropTypes from "prop-types";
import { AdHeader, AdHeadline } from "../post-components";
import { AdPrimaryAbTest } from "../post-components/primary-abtest";

export const FacebookAbTest = ({
  load,
  d,
  changeHeadline,
  state,
  type,
  abType,
  handleAbRemix,
  IndexChanger,
  headline,
  advanceObj,
  setAbTests,
  primaryImg,
  text,
  logo,
}) => {
  const [copyText, setCopyText] = useState("");
  const [score, setScore] = useState(0);
  useEffect(() => {
    if (d && d.length) {
      const text = d.reduce((onlyText, text) => {
        return onlyText + text.onlyText + " \n";
      }, "");
      let grade = rs.fleschKincaidGrade(text);
      let formula = grade * 10 + 5.1;
      let sc = Math.round(formula > 100 ? 100 : formula < 0 ? 0 : formula);
      if (sc < 80) sc = 80;
      setScore(sc);
    }
  }, [d]);
  const wirteClipboard = () => {
    const sanitizeConf = {
      allowedTags: [],
      allowedAttributes: {},
    };

    let sanitized = sanitizeHtml(copyText, sanitizeConf);
    sanitized = sanitized.trim().replace(/^\s+|\s+$/gm, "");
    navigator.clipboard.writeText(sanitized).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  return (
    <div
      style={{ boxShadow: "0px 1px 3px rgb(0 0 0 / 20%)" }}
      className="px-2 py-1 border-2 container bg-white rounded-lg"
    >
      <div>
        <div>
          <AdHeader
            load={load}
            state={state}
            title={text}
            score={score}
            logo={logo}
            pcg={false}
            d={d?.length && d[0]?.primaryText}
            type={type}
            setCopyText={setCopyText}
            wirteClipboardDesc={wirteClipboard}
            onClick={handleAbRemix}
            //   addToShowCase={addToShowCase}
            template={type}
            headline={headline}
            copyText={copyText}
          />
          <div>
            <AdPrimaryAbTest
              primaryText={d?.length && d[0]?.primaryText}
              state={state}
              d={d}
              pading={true}
              copyText={copyText}
              setCopyText={setCopyText}
              advanceObj={advanceObj}
              setAbTests={setAbTests}
              IndexChanger={IndexChanger}
              primaryImg={primaryImg}
            />
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${state.image})` }}
          className="flex justify-center"
        >
          <div>{abType}</div>
        </div>

        <div>
          {!!headline?.text?.length && (
            <AdHeadline
              changeStatus={IndexChanger.disableDiscount}
              title={headline}
              changeIndex={changeHeadline}
              noIndex={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

FacebookAbTest.propTypes = {
  load: PropTypes.any,
  d: PropTypes.any,
  logo: PropTypes.any,
  changeHeadline: PropTypes.any,
  state: PropTypes.any,
  type: PropTypes.any,
  text: PropTypes.any,
  abType: PropTypes.any,
  handleAbRemix: PropTypes.func,
  headline: PropTypes.any,
  advanceObj: PropTypes.any,
  setAbTests: PropTypes.any,
  IndexChanger: PropTypes.any,
  primaryImg: PropTypes.any,
};
