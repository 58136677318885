import { Link } from "react-router-dom";
import "./index.scss";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  tutorial,
  logout,
  help,
  dashboard,
  setting,
  template,
  showcase,
  logo,
} from "../../assests/images";
import { Menu } from "antd";
import { authActions } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentKey } from "../../store/actions/sidebarActions";

export const SidebarDataForUserPanel = [
  {
    title: "Dashboard",
    route: "",
    icon: <img src={dashboard} width="22px" />,
    key: "dashboard",
  },
  {
    title: "Templates",
    route: "templates",
    icon: <img src={template} width="22px" height="30px" />,
    key: "templates",
  },
  {
    title: "Showcase",
    route: "showcase",
    icon: <img src={showcase} width="22px" height="30px" />,
    key: "showcase",
  },
  {
    title: "Settings",
    route: "settings",
    icon: <img src={setting} width="22px" height="30px" />,
    key: "settings",
  },
  {
    title: "Help",
    route: "help",
    icon: <img src={help} width="22px" height="30px" />,
    key: "help",
  },
  {
    title: "Tutorial",
    route: "tutorial",
    icon: <img src={tutorial} width="22px" height="30px" />,
    key: "tutorial",
  },
  {
    title: "Logout",
    route: "logout",
    icon: <img src={logout} width="22px" height="30px" />,
    key: "logout",
  },
];
export const MobileSidebar = () => {
  let [menu, setMenu] = useState(true);
  let [activeMenu, setActiveMenu] = useState("navLinks");

  useEffect(() => {
    menu ? setActiveMenu("navLinks") : setActiveMenu("navLinks active");
  }, [menu]);
  const currentKey = useSelector((state) => state.sideBarReducer.currentKey);
  const dispatch = useDispatch();

  function handleKeyChange(key) {
    dispatch(updateCurrentKey(key));
  }
  let pathForSideMenu = "/unicorncopy/userpanel";

  return (
    <>
      <div className="mobileNav">
        <div
          className="navBar"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            <img src={logo} alt="Logo" width="50px" />
          </div>
          <div>
            <ul className={activeMenu}>
              {SidebarDataForUserPanel.map((item) => {
                return (
                  <Link key={item.key} to={`${pathForSideMenu}/${item.route}`}>
                    <Menu className="menus-lists">
                      <Menu.Item
                        key={item.key}
                        className={`meun-li customColor ${
                          item.key === currentKey
                            ? "ant-menu-items-selected"
                            : "notselected"
                        } `}
                        icon={item.icon}
                        onClick={() => {
                          if (item.title === "Logout") authActions.logout();
                          handleKeyChange(item.key);
                        }}
                      >
                        <div
                          onClick={() => {
                            setMenu(!menu);
                          }}
                        >
                          {item.title}
                        </div>
                      </Menu.Item>
                    </Menu>
                  </Link>
                );
              })}
            </ul>
            <div className="icon">
              {menu ? (
                <MenuIcon
                  onClick={() => setMenu(!menu)}
                  sx={{ color: "white" }}
                />
              ) : (
                <CloseIcon
                  onClick={() => setMenu(!menu)}
                  sx={{ color: "white" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
