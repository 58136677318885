import { PromiseModal } from "../promise-modal/index.js";
import PropTypes from "prop-types";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../services/auth.services.js";
import { modalAction } from "../../store/actions/modal.action.js";
import { authActions } from "../../store/actions/auth.actions.js";
export const CancelPlanModal = ({ modal, setModal, title }) => {
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const handleModal = () => setModal(false);
  const dispatch = useDispatch();
  const handleCancelSub = async () => {
    const data = {
      id: user?.subcriptionId,
      user,
    };
    const res = await authService.cancelSub(data);
    console.log(res);
    if (res.status === 200) {
      if (res.data?.deleted) {
        dispatch(
          modalAction.success({ title: "subscription canceled", warn: true })
        );
      } else {
        dispatch(
          modalAction.success({ title: "something went wrong", warn: true })
        );
      }
      if (user?.planType === "agency") {
        authActions.logout();
      }
    } else {
      dispatch(modalAction.error({ title: res.data }));
    }
    handleModal();
  };
  return (
    <PromiseModal
      modalType={"warning"}
      icon={
        modal?.type === "success" ? (
          <CheckCircleTwoTone twoToneColor={"#4BB543"} />
        ) : modal?.type === "warning" ? (
          <ExclamationCircleTwoTone twoToneColor={"#EED202"} />
        ) : (
          <CloseCircleTwoTone twoToneColor={"#FF9494"} />
        )
      }
      title={title}
      content={modal?.data?.content}
      closable={true}
      onCancel={() => handleModal(false)}
      centered={true}
      visible={modal}
      onOk={handleCancelSub}
    />
  );
};

CancelPlanModal.propTypes = {
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  title: PropTypes.string,
};
