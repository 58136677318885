import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import BrainCaption from "../../cards/brain-caption";

export const ApexChart = (props) => {
  let state = {
    series: props.series,
    width: "100%",
    options: {
      chart: {
        height: 380,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: props.color,
      labels: props.label,
      legend: {
        show: false,
        floating: true,
        fontSize: "16px",
        position: "top",
        horizontalAlign: "right",
        offsetX: 160,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      responsive: [
        {
          breakpoint: 500,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };
  return (
    <span
      style={
        props.subType === "eighty"
          ? {
              display: "flex",
              width: "100%",
              justifyContent:
                props.type === "C" ? "space-evenly" : "space-between",
              alignItems: "center",
            }
          : {}
      }
    >
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={props.height}
        width={props.subType === "eighty" ? 400 : ""}
      />
      <BrainCaption
        bottomText={props.bottomText}
        bottomText2={props.bottomText2}
        color="#68b2ba"
        color2="#eaa6aa"
        mostValuableAdTypesLabels={props.mostValuableAdTypesLabels}
        series={props.type || "C"}
      />
    </span>
  );
};

ApexChart.propTypes = {
  series: PropTypes.any,
  color: PropTypes.any,
  label: PropTypes.any,
  height: PropTypes.any,
  bottomText: PropTypes.any,
  bottomText2: PropTypes.any,
  mostValuableAdTypesLabels: PropTypes.array,
  type: PropTypes.string,
  subType: PropTypes.string,
};
