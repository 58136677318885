import { Divider } from "antd";
import React, { useState } from "react";
import { Btn } from "../../forms";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Points } from "./points";

export const GrowthPlan = ({ createCheckoutSession }) => {
  const [load, setLoad] = useState(false);
  const { loggedInUser } = useSelector((e) => e.authentication);
  const handlePlan = () => {
    setLoad(true);
    createCheckoutSession("growth");
  };
  const features = [
    "30 Ad Copy generations per month",
    "Done-For-You Ad Copy A/b testing",
    "All Ad Copy Formulas",
    "Generate Ad Copy for any domain",
  ];
  return (
    <div className="plan-card p-2 flex flex-col justify-center items-center">
      <h1>Growth User</h1>
      <h6>For brands looking to scale their online ads</h6>
      <Divider />

      <div className="flex items-baseline justify-center">
        <h2>$25</h2> <h3>/month</h3>
      </div>
      <h6>Less than $1 per day</h6>
      <div className="p-5">
        {features?.map((value, index) => (
          <Points key={index} point={value} />
        ))}
        <div className="p-3">
          <Btn
            load={load}
            disabled={loggedInUser.user?.planType === "growth" ? true : false}
            onClick={
              loggedInUser.user?.planType === "growth" ? null : handlePlan
            }
            value={
              loggedInUser.user?.planType === "growth"
                ? "Subcribed"
                : "Go with this one"
            }
            color="#ffffff"
            bgColor="linear-gradient(132deg, rgba(58,168,180,1) 1%, rgba(131,58,180,1) 32%, rgba(253,29,29,1) 62%, rgba(252,176,69,1) 100%)"
          />
        </div>
      </div>
    </div>
  );
};
GrowthPlan.propTypes = {
  createCheckoutSession: PropTypes.any,
};
