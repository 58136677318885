import React, { useCallback, useEffect, useState } from "react";
import {
  TextInput,
  Btn,
  TextArea,
  SwitchBtn,
  UserProfile,
  ScrollIndicator,
} from "../../components";
import "./scss/index.scss";
import { useNavigate } from "react-router-dom";
import {
  abTestingIcon,
  copyType,
  copyTypeColor,
  google,
  tooltip,
  copyTypeRed,
} from "../../assests/images";
import { Menu, Popover, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import { Check, Content, History } from "../../components";
import { postService } from "../../services/post.services";
import { useDispatch, useSelector } from "react-redux";
import { authService, gpt3, showcaseService } from "../../services";
import { authActions, modalAction } from "../../store/actions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import postAction from "../../store/actions/post.actions";
import { AbTest } from "../../components/cards/ab-test";
import AdOptimization from "../ad-optimizations";
import ReactPaginate from "react-paginate";
import NEXT_ICON from "../../assests/images/icons/next-icon.png";
import PREV_ICON from "../../assests/images/icons/Prev.png";
export const ContentGeneration = () => {
  // const [count, setCount] = useState(0);
  const [copyTypeDisable, setCopyTypeDisable] = useState(false);
  // const [outputDisable, setOutputDisable] = useState(true);yy
  const [, setCount1] = useState(0);
  const [, setCount2] = useState(0);
  const [moreAdOptimization, setMoreAdOptimization] = useState(false);
  const [scrapContents, setScrapContents] = useState(false);
  const [inputDisable, setInputDisable] = useState(true);
  const [discount, setDiscount] = useState({
    index: 0,
    text: "",
    disable: false,
  });
  const [abTesting, setAbTesting] = useState(false);
  const [url, setUrl] = useState("");
  const { maxIndex } = useSelector((e) => e.post);
  const [load, setLoad] = useState(false);
  const [allData, setAllData] = useState(false);
  const [isPopoverActiveAdType, setIsPopOverActiveAdType] = useState(false);
  const [state, setState] = useState({
    opengraph: "",
    statticType: [],
    pcg: [],
    ftb: [],
    pd: [],
    lof: [],
    tl: [],
    qq: [],
    qrsftb: [],
    pcgList: [],
    ftbList: [],
    qqList: [],
    lofList: [],
    tlList: [],
    google: [],
    types: [],
    descCopy: "",
    desc: "",
    allGeneratedData: [],
  });
  const [abTests, setAbTests] = useState([]);
  const [abTypeA, setAbTypeA] = useState("");
  const [abTypeB, setAbTypeB] = useState("");
  const [copyTypeFirstDimension, setCopyTypeFirstDimension] = useState(0);
  const [copyTypeSecondDimension, setCopyTypeSecondDimension] = useState(0);
  const [, setDisableNextAdTest] = useState(false);
  const [, setdisablePrevAbest] = useState(false);
  const [abTestSelected, setAbTestSelect] = useState(
    "Qualifying question VS Feature based copy"
  );
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const post = useSelector((e) => e.post);
  const strings = post?.strings;

  const handlePrevGoogle = () => {
    if (index > 0) {
      dispatch(postAction.decrementIndex());
    } else {
      dispatch(postAction.goto(2));
    }
  };

  const { copyIndex } = useSelector((e) => e.post);
  useEffect(() => {
    if (state.productName && state.desc && state.descCopy && state.audience) {
      setScrapContents(true);
    }
  }, [state?.productName, state.audience, state.desc, state.descCopy]);
  const [subHeadline, setSubHeadline] = useState({
    productName: undefined,
    audience: undefined,
    description: undefined,
  });
  const [gpt3Loading, setGpt3Loading] = useState(false);
  const [advanceSetting, setAdvanceSettings] = useState({
    freeShipping: {
      active: false,
      discount: "",
    },
    discount: {
      active: false,
      offType: "",
      value: "",
      discount: "",
      promo: "",
      per: "",
    },
    ocassion: {
      active: false,
      discount: "",
      name: "",
    },
    location: {
      active: false,
      discount: "",
      name: "",
    },
  });
  const [headline, setHeadline] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [headlineAbTestA, setHeaalineAbTestA] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [headlineAbTestB, setHeaalineAbTestB] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [freeShipping, setFreeShipping] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [ocassion, setOcasstion] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [ctaUrl, setCtaUrl] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [location, setLocation] = useState({
    index: 0,
    text: [],
    disable: false,
  });
  const [typeState, setTypeState] = useState({
    qrsftb: false, //quote review sim
    pcg: true, // pain claim game => storyTelling
    qq: true, // qualyfying quesitons => question based
    ftb: true, // feature to benefite => feature based copy
    pd: false, //pure description
    lof: true, //list of features => benefit based copy
    tl: false, //tagline
  });
  const [, setGpt3Data] = useState([]);
  const [, setData] = useState([]);
  const [funnelStage, setFunnelStage] = useState("");
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currIndex, setCurrIndex] = useState({
    start: 0,
    end: 10,
  });

  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const goBack = () => {
    navigate(-1);
  };
  const tabs = ["Output", "History"];
  const [active, setActive] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loadShowCase, setLoadShowCase] = useState(false);
  const [currentStaticTypes, setCurrentStaticTypes] = useState([]);
  const type = routeLocation.state.type;
  const handleGoogleNext = () => {
    if (index > 2) {
      dispatch(postAction.resetCopyIndex());
    } else {
      dispatch(postAction.incrementIndex());
    }
  };
  const handlePageClick = (event) => {
    let newOffset = 0;
    if (type !== "google") {
      newOffset = (event.selected * 1) % state.statticType?.length;
    } else {
      newOffset = (event.selected * 1) % state.google?.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    if (type === "google") {
      handleGoogleNext();
      return;
    }
    onNext();
  };
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 1;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // setCurrentItems(items.slice(itemOffset, endOffset));
    if (type !== "google") {
      setPageCount(Math.ceil(state.statticType?.length / 1));
    } else {
      setPageCount(Math.ceil(state.google?.length / 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, state.statticType, state.google]);

  const createSingleAbTest = (single) => {
    let temp = [];
    single.forEach((e) => {
      temp.push([e]);
    });
    return temp;
  };
  const questionBasedAbTest = () => {
    //Qualifying Question + List of Features
    // loop around the type to create the array of combinations
    const qqAndFtb = createTwoPairsForAbTest(state.qq, state.ftb);
    const qqAndPd = createTwoPairsForAbTest(state.qq, state.pd);
    const qqAndLof = createTwoPairsForAbTest(state.qq, state.lof);
    // const qq = createSingleAbTest(state.qq);
    return fisherYates([...qqAndFtb, ...qqAndPd, ...qqAndLof]);
  };
  const fetureBasedAbTest = () => {
    const ftbAndPd = createTwoPairsForAbTest(state.ftb, state.pd);

    return fisherYates([...ftbAndPd]);
  };
  const storytellingBasedAbTest = () => {
    const pcg = createSingleAbTest(state.pcg);
    return [...pcg];
  };
  const benefitBasedAbTest = () => {
    const ftb = createSingleAbTest(state.ftb);
    const ftbAndPd = createTwoPairsForAbTest(state.ftb, state.pd);
    const ftbAndLof = createTwoPairsForAbTest(state.ftb, state.lof);
    return fisherYates([...ftb, ...ftbAndPd, ...ftbAndLof]);
  };
  useEffect(() => {
    const val = post.funnelStage?.value;
    // uncertain Types
    const tempType =
      post.abTest?.type === "twitter" ||
      post.abTest?.type === "tiktok" ||
      post.abTest?.type === "ld" ||
      post.abTest?.type === "pinterest";
    if (
      val === "Top of funnel" &&
      (post.abTest?.type === "fb" || post.abTest?.type === "insta" || tempType)
    ) {
      topOfTheFunnelForFbInstaAndLinkedIn();
    }
    if (
      val === "Middle of funnel" &&
      (post.abTest?.type === "fb" || post.abTest?.type === "insta" || tempType)
    ) {
      middleOfFunnelForFbInstaAndLinkedIn();
    }
    if (
      val === "Bottom of funnel" &&
      (post.abTest?.type === "fb" || post.abTest?.type === "insta" || tempType)
    ) {
      bottomOfFunnelForFbInstaAndLinkedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.funnelStage?.value, post.abTest?.type, funnelStage]);
  // three dimensional array to get the index plus get the add
  // now changed to three dimesional array due to changes requested ab test 2
  const topOfTheFunnelForFbInstaAndLinkedIn = () => {
    let data = [
      createpairCombination(state.pcg),
      createpairCombination(state.ftb),
      createpairCombination(state.qrsftb),
      createpairCombination(state.lof),
      createpairCombination(state.qq),
      createpairCombination(state.tl),
    ];
    setAbTests(data);
  };

  const middleOfFunnelForFbInstaAndLinkedIn = () => {
    let data = [
      createpairCombination(state.pcg),
      createpairCombination(state.ftb),
      createpairCombination(state.qrsftb),
      createpairCombination(state.lof),
      createpairCombination(state.qq),
      createpairCombination(state.tl),
    ];
    setAbTests(data);
  };
  const bottomOfFunnelForFbInstaAndLinkedIn = () => {
    let data = [
      createpairCombination(state.ftb),
      createpairCombination(state.qrsftb),
      createpairCombination(state.lof),
      createpairCombination(state.pd),
      createpairCombination(state.qq),
      createpairCombination(state.tl),
    ];
    setAbTests(data);
  };

  const lfvssf = () => {
    let data = [storytellingBasedAbTest(), benefitBasedAbTest()];

    // naming is confusing
    setAbTypeA("Storytelling-based copy");
    setAbTypeB("Benefit-based copy");
    setAbTests(data);
  };
  const fbvstb = () => {
    let data = [fetureBasedAbTest(), benefitBasedAbTest()];
    setAbTypeA("Storytelling-based copy");
    setAbTypeB("Benefit-based copy");
    setAbTests(data);
  };
  const nvbstb = () => {
    let data = [fetureBasedAbTest(), benefitBasedAbTest()];
    setAbTypeA("Feature-based copy");
    setAbTypeB("Benefit-based copy");
    setAbTests(data);
  };
  const fbvsnb = () => {
    let data = [fetureBasedAbTest(), storytellingBasedAbTest()];
    setAbTypeA("Feature-based copy");
    setAbTypeB("Storytelling-based copy");
    setAbTests(data);
  };
  const qqvstb = () => {
    let data = [questionBasedAbTest(), benefitBasedAbTest()];
    setAbTypeA("Question-based copy");
    setAbTypeB("Benefit-based copy");
    setAbTests(data);
  };
  const qqvstl = () => {
    let data = [
      [createpairCombination(state.qq)],
      [createpairCombination(state.tl)],
    ];
    setAbTypeA("Question-based copy");
    setAbTypeB("Tag-based copy");
    setAbTests(data);
  };
  const ftbvstl = () => {
    let data = [
      [createpairCombination(state.ftb)],
      [createpairCombination(state.tl)],
    ];
    setAbTypeA("Feature-based copy");
    setAbTypeB("Tag-based copy");
    setAbTests(data);
  };
  const qqvsnb = () => {
    let data = [questionBasedAbTest(), storytellingBasedAbTest()];
    setAbTypeA("Question-based copy");
    setAbTypeB("Storytelling-based copy");
    setAbTests(data);
  };

  const qqvsfbc = () => {
    // Qualifying Question + Pure Description

    let data = [questionBasedAbTest(), fetureBasedAbTest()];
    setAbTypeA("Question-based copy");
    setAbTypeB("Feature-based copy");
    setAbTests(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    const val = post.abTest.value;
    setCopyTypeFirstDimension(0);
    setCopyTypeSecondDimension(0);
    val === "Qualifying question VS Feature based copy" && qqvsfbc();
    val === "Qualifying question VS Narrative based copy" && qqvsnb();
    val === "Qualifying question VS Testimonial based" && qqvstb();
    val === "Feature based copy VS Narrative based copy" && fbvsnb();
    val === "Feature based copy VS Testimonial based copy" && fbvstb();
    val === "Narrative based copy VS Testimonial based copy" && nvbstb();
    val === "Long Form vs Short form copy" && lfvssf();
    val === "Qualifying question VS Tagline based" && qqvstl();
    val === "Feature based copy VS Tagline based copy" && ftbvstl();
    if (!val) {
      // qqvsfbc();
      // dispatch(
      //   postAction.funnelStage({
      //     value: "Qualifying question VS Feature based copy",
      //   })
      // );
      // dispatch(
      //   postAction.selectAbTest({
      //     value: "Qualifying question VS Feature based copy",
      //   })
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.abTest.value, post.abTest.selected, abTesting]);
  const createTwoPairsForAbTest = (pairA, pairB) => {
    let temp = [];
    let temp2 = [];
    pairA.forEach((e) => {
      temp.push(e);
    });
    pairB.forEach((e) => {
      temp2.push(e);
    });

    let combinations = [];
    temp.forEach((e) => {
      temp2.forEach((j) => {
        combinations.push([e, j]);
      });
    });
    return combinations;
  };
  const createpairCombination = (a, b) => {
    const verifyAB = a?.length && b?.length ? true : false;
    const verifyA = a && a?.length ? true : false;
    if (verifyAB) {
      return [a[0], b[0]];
    } else if (verifyA) {
      return [a[0]];
    }
  };
  const getStoreAbTest = () => {
    const data = JSON.parse(localStorage.getItem("abTest"));
    if (data?.length && data[0]?.type === routeLocation?.state?.type) {
      let temp = [];
      data.forEach((e) => {
        temp.push(e.data);
      });
      if (temp.length) setAbTests(temp);
    }
  };
  useEffect(() => {
    // initate ab Tests
    dispatch(postAction.initiateAb());
    dispatch(
      postAction.setStoreAbTest({
        getStoreAbTest: getStoreAbTest,
        type: routeLocation?.state?.type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onNext = () => {
    if (index >= post.maxIndex) {
      dispatch(postAction.resetCopyIndex());
    }
    if (index < cardData.length - 1) setIndex(index + 1);
    dispatch(postAction.slectDirection("right"));
    setHeadlineIndex();
    generatedata();
    dispatch(postAction.incrementIndex(copyIndex));
  };
  const onPrevious = () => {
    if (index > 0) setIndex(index - 1);
    setHeadlineIndex();
    dispatch(postAction.decrementIndex());
  };
  const handleShowCase = async (data) => {
    console.log(data);

    setLoadShowCase(true);
    let oldDAta = [...state.statticType];
    if (oldDAta[copyIndex]) {
      oldDAta[copyIndex].fav = true;
      setState((e) => ({
        ...e,
        statticType: oldDAta,
      }));
    }
    // let s = true;
    // if (s) return;
    const res = await showcaseService.addToShowCase({
      ...data,
      uid: localStorage.getItem("uid"),
    });
    console.log(res);
    dispatch(
      modalAction.success({
        title: "Added to showcase!",
      })
    );
    setLoadShowCase(false);
  };
  const text = (
    <span className="flex items-center w-64">
      <img src={copyTypeColor} width="35px" className="mr-1" />
      What Type of Ad Copy do you want to see when you click next?
    </span>
  );
  const getNewKlazifyCategory = async (cat) => {
    if (cat) {
      const res = await postService.validateKlazify({ categories: cat });
      if (res.data) {
        const newCat = res?.data["New Categories"];
        let tabId = newCat?.split("/").pop();
        setState((e) => ({ ...e, audience: tabId }));
        setSubHeadline((e) => ({ ...e, audience: false }));
      } else {
        setSubHeadline((e) => ({ ...e, audience: true }));
      }
    } else {
      setSubHeadline((e) => ({ ...e, audience: true }));
    }
  };

  const cleanUrlForSerpSite = (url) => {
    let temp = url;
    let n = temp.split("?");
    if (n.constructor === Array) {
      n = n.filter((e) => e !== "utm");
      if (n.length) {
        n = n.join("");
        temp = n;
      }
    }

    temp = "site:" + temp;
    return temp;
  };
  function isSiteOnline(url, callback) {
    var timer = setTimeout(function () {
      // timeout after 5 seconds
      callback(false);
    }, 10000);
    fetch(url, { mode: "no-cors" })
      .then(() => {
        console.log("reachable");
        clearTimeout(timer);
        callback(true);
      })
      .catch(() => {
        console.log("not there");
        clearTimeout(timer);
        callback(false);
      });
  }
  const handleScrape = async () => {
    console.log("==> > ", scrapContents);
    if (scrapContents) {
      setSubHeadline({
        productName: undefined,
        audience: undefined,
        description: undefined,
      });
      setScrapContents(false);
      setInputDisable(true);
      setInputDisable(false);
      return;
    }
    if (!url) return;
    setLoad(true);
    isSiteOnline(url, async function (found) {
      if (found) {
        // site is online
        setState((e) => ({
          ...e,
          productName: "",
          audience: "",
          desc: "",
          descCopy: "",
          pd: [],
          lof: [],
          qrsftb: [],
          tl: [],
          pcg: [],
          ftb: [],
          qq: [],
          types: [],
          statticType: [],
          kalazify: {},
        }));
        setCardData([]);
        setCount1(0);
        setCount2(0);
        let data = { url };
        const opengraph = await postService.scrapDataWithog(data);
        if (opengraph?.data)
          setState((e) => ({
            ...e,
            opengraph,
            productName: opengraph?.data?.htmlInferred?.site_name,
          }));
        if (opengraph?.data?.htmlInferred?.description?.length > 140) {
          const dbKLzaify = await postService.getKlazify(data);
          let klazify = "";
          if (dbKLzaify.data && dbKLzaify.data !== 404) {
            //
            const cat =
              dbKLzaify.data?.categories?.length &&
              dbKLzaify.data?.categories[0];
            if (cat?.confidence >= 0.49) {
              await getNewKlazifyCategory(cat?.name);
            }

            klazify = dbKLzaify.data;
            setState((e) => ({ ...e, kalazify: dbKLzaify.data }));
          } else {
            const k = await postService.scrapFromKlazify(data);
            if (k.data) {
              const cat =
                k?.data?.domain?.categories?.length &&
                k?.data?.domain?.categories[0];

              if (cat?.confidence >= 0.49) {
                await getNewKlazifyCategory(cat?.name);
              }
              klazify = k.data;
              setState((e) => ({ ...e, kalazify: k.data }));
            }
          }
          data = {
            url,
            og: opengraph?.data,
            state: state,
            kalazify: klazify,
          };
          setAllData(data);
          optimizeData(data);
        }
        const serp = await postService.scrapWithSerp(data);
        const serpSite = await postService.scrapWithSerp({
          url: cleanUrlForSerpSite(data.url),
        });
        //
        if (serpSite?.data) {
          setState((e) => ({ ...e, serpite: serpSite?.data }));
        }
        if (serp?.data) {
          setState((e) => ({ ...e, serp: serp?.data }));
        }
        const scrapDiffBot = await postService.scrapDiffBot(data);

        // klazify
        const dbK = await postService.getKlazify(data);
        let klazify = "";
        if (dbK.data && dbK.data !== 404) {
          //
          const cat = dbK.data?.categories?.length && dbK.data?.categories[0];
          if (cat?.confidence >= 0.49) {
            getNewKlazifyCategory(cat?.name);
          }

          klazify = dbK.data;
          setState((e) => ({ ...e, kalazify: dbK.data }));
        } else {
          const k = await postService.scrapFromKlazify(data);
          if (k.data) {
            const cat =
              k?.data?.domain?.categories?.length &&
              k?.data?.domain?.categories[0];
            //
            if (cat?.confidence >= 0.49) {
              getNewKlazifyCategory(cat?.name);
            }
            klazify = k.data;
            setState((e) => ({ ...e, kalazify: k.data }));
          }
        }
        console.log("===>>>opengraph ", opengraph);
        console.log("===>>>serp ", serp);
        console.log("===>>>scrapDiffBot ", scrapDiffBot);
        console.log("===>>>kalazify ", klazify);
        if (!opengraph?.data || (!klazify?._id && !klazify?.data)) {
          setSubHeadline({
            productName: undefined,
            audience: undefined,
            description: undefined,
          });
          setScrapContents(false);
          setInputDisable(true);
          // handleShowErrorModal();
        }
        data = {
          url,
          og: opengraph?.data,
          state: state,
          serpite: serp?.data,
          serp: serp?.data,
          scrapDiffBot: scrapDiffBot.data,
          kalazify: klazify,
        };
        setAllData(data);
        optimizeData(data);
        unLoad();
      } else {
        unLoad();
        dispatch(
          modalAction.success({
            title: `"Unfortunately , this website is down, please try with diffrent Active Website OR use our "I dont have landing page options".`,
          })
        );
        // site is offline (or favicon not found, or server is too slow)
      }
    });
  };
  const handleShowErrorModal = () => {
    if (!gpt3Loading)
      dispatch(
        modalAction.success({
          title:
            "Whoops, looks like that landing page isn't allowing our friendly A.I. to read it. Please fill out the forms yourself. 😢 ",
        })
      );
  };

  useEffect(() => {
    generatedata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.statticType, copyIndex]);
  useEffect(() => {
    // this being undefined means that our all code has run over them;
    // console.log(subHeadline);
    if (
      // subHeadline.audience !== undefined && //temporary remove klazify
      subHeadline.description !== undefined &&
      subHeadline.productName !== undefined
    ) {
      setScrapContents(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subHeadline,
    subHeadline.audience,
    subHeadline.description,
    subHeadline.productName,
  ]);

  const changeSecondINdexOfSecondDimensionOfAdCopy = () => {
    if (abTests[1]?.length - 1 <= copyTypeSecondDimension) return false;
    setCopyTypeSecondDimension((e) => e + 1);
    return true;
  };

  const handeAbTestRemixFor2ndCard = () => {
    const isSecondRow = changeSecondINdexOfSecondDimensionOfAdCopy();
    if (!isSecondRow) {
      setCopyTypeSecondDimension(0);
    }
  };

  const titleRules = (title, og, serp) => {
    let temp = false;
    if (title) {
      const words = title?.split(" ");
      if (words?.length) {
        if (og) {
          if (title?.length >= 70) {
            temp = true;
          }
          if (words?.length > 25) {
            temp = true;
          }
        } else if (serp) {
          if (words?.length && !isNaN(words[0])) {
            temp = true;
          }
          if (
            words &&
            words?.length &&
            typeof words == "string" &&
            words?.include(`" "`)
          ) {
            temp = true;
          }
        }
      }
    }
    //pass if return false
    return temp;
  };
  const setHeadlineIndex = () => {
    if (headline.index < headline.text.length - 1)
      setHeadline({ ...headline, index: headline.index + 1 });
    else setHeadline({ ...headline, index: 0 });
  };
  const halfShortning = (title) => {
    let wordsToDiscard = [
      "Shop – ",
      "SHOP ALL ",
      "Shop ",
      "Shop for ",
      "Products – ",
      " LLC",
      " LLC.",
      " LLC",
      " LLC.",
      " INC",
      " INC.",
      " INC",
      " INC.",
      " ltd",
      " ltd.",
      " ltd",
      " ltd.",
      " online",
      "®",
    ];
    let temp = title;
    // half is for both title
    if (temp) {
      wordsToDiscard.forEach((e) => {
        if (e !== " online") {
          temp = temp.replaceAll(e, " ");
        } else if (temp.endsWith(e)) {
          temp = temp.replaceAll(e, " ");
        }
      });
    }
    return temp;
  };
  const checkUrl = (url) => {
    // if true then home page url
    var res = false;
    if (url) {
      res =
        url.endsWith("com", url.length - 1) ||
        url.endsWith("com", url) ||
        url.endsWith("com/");
    } else {
      res = false;
    }
    return res;
  };
  const fullInputBlackListedWordRule = (name) => {
    let balckListedWord = [
      "Shop",
      "Shop All",
      "Product",
      "Products",
      "all",
      "All Products",
      "shop",
      "New",
      "New In",
      "New Arrivals",
      "Products list",
      "Shop Products",
      "Shop now",
      "Shop Collection",
      "Shop Collections",
      "American Express + VISA",
      "Home page",
      "Homepage",
      "Home",
      "Choose",
      " your ",
      " you ",
      // " – ",  // conflicting with "|" this rule
      ", ",
      "}",
      "404",
      "</",
      ".",
      "Welcome",
      "|",
    ];
    let temp = false;
    if (name) {
      balckListedWord.forEach((e) => {
        if (name.search(e) > 0) {
          if (e !== "Choose" && e !== "Welcome") {
            temp = true;
            //
          }
        } else if (e === "Choose") {
          if (name.startsWith(e)) {
            temp = true;
          }
        } else if (e === "Welcome" && matchUrl(state.url, name)) {
          temp = true;
        }
      });
    }
    //return false for success
    return temp;
  };
  const matchSiteName = (site_name, url) => {
    if (!site_name) return site_name;
    site_name = site_name.toLowerCase();
    const params = new URL(url);
    let host = params.host;
    host = host.split(".");
    if (!host.length) return site_name;
    host = host[0];
    if (site_name?.search(host) >= 0) return host;
    return site_name;
  };
  const matchUrl = (url, title) => {
    let temp = false;

    if (url) {
      const splitonDot = url.split(".");
      const splitonDash = url.split("-");
      const splitonDoubleDash = url.split("--");
      const splitonor = url.split("|");
      const splitonBlank = url.split("___");
      const splitoncoma = url.split(",");
      const splitonColon = url.split(":");
      let rule = [
        splitonDot,
        splitonDash,
        splitonDoubleDash,
        splitonor,
        splitonBlank,
        splitoncoma,
        splitonColon,
      ];
      //
      rule.forEach((e) => {
        e.forEach((j) => {
          if (j === title) {
            temp = true;
          }
        });
      });
    }
    return temp;
  };
  const serSerptitle = (data) => {
    const name = data?.organic_results[0].title;
    if (name) {
      if (!titleRules(name, false, true)) {
        setState((e) => ({
          ...e,
          productName: name,
        }));
        setSubHeadline((e) => ({ ...e, productName: false }));
      } else {
        // no titlte found
        handleShowErrorModal();

        setSubHeadline((e) => ({ ...e, productName: true }));
      }
    } else {
      // no titlte found
      setSubHeadline((e) => ({ ...e, productName: true }));
    }
  };
  const titleOfProduct = async (url, data) => {
    // 1- checking for home page url

    if (checkUrl(url)) {
      // 2- if its homepage use og sitename from hybrid graph
      //
      let name = data?.openGraph?.site_name;
      name = name ? name : data?.htmlInferred?.site_name;
      const title = data?.htmlInferred?.title;
      const indexOfDash = title.search("-");
      //
      if (
        indexOfDash > 0 &&
        title[indexOfDash + 1] !== " " &&
        title[indexOfDash - 1] !== " "
      ) {
        name = title;
      }

      // matching sitename with url ;
      name = matchSiteName(name, url);
      // i- matching url for home page
      //
      if (!matchUrl(url, name) && !titleRules(name, true, false)) {
        // a - apply rules here
        const shortClean = halfShortning(name);
        //
        // b - set name here
        if (!fullInputBlackListedWordRule(shortClean)) {
          //
          setState((e) => ({
            ...e,
            productName: shortClean,
          }));
          setSubHeadline((e) => ({ ...e, productName: false }));
        } else {
          // no title again
          setSubHeadline((e) => ({ ...e, productName: true }));
        }
      } else {
        // use serp title here

        if (!state.serp) {
          var serp = await postService.scrapWithSerp({
            url: state?.url,
          });
          setState((e) => ({
            ...e,
            serp: serp?.data,
          }));
          serSerptitle(serp.data);
          setSubHeadline((e) => ({ ...e, productName: false }));
        } else {
          serSerptitle(state.serp);
          setSubHeadline((e) => ({ ...e, productName: false }));
        }
      }
    } else {
      // 3- not an homepage we use og sitename form inferred html
      let name = data?.openGraph?.site_name;
      name = name ? name : data?.htmlInferred?.site_name;
      const title = data?.htmlInferred?.title;
      const indexOfDash = title?.search("-");
      //
      if (
        indexOfDash > 0 &&
        title[indexOfDash + 1] !== " " &&
        title[indexOfDash - 1] !== " "
      ) {
        name = title;
      }
      //
      // a - apply rules here
      const shortClean = halfShortning(name);
      //
      // b - set name here
      if (!fullInputBlackListedWordRule(shortClean) && shortClean) {
        setState((e) => ({
          ...e,
          productName: shortClean,
        }));
        setSubHeadline((e) => ({ ...e, productName: false }));
      } else {
        // 4- if this is discarded fallback to sitename hybrid graph
        let name = data?.openGraph?.site_name;
        name = name ? name : data?.htmlInferred?.site_name;
        const shortClean = halfShortning(name);
        //
        // b - set name here
        if (!fullInputBlackListedWordRule(shortClean) && shortClean) {
          setState((e) => ({
            ...e,
            productName: shortClean,
          }));
          setSubHeadline((e) => ({ ...e, productName: false }));
        } else {
          // no title again
          setSubHeadline((e) => ({ ...e, productName: true }));
        }
      }
    }
  };
  const scrapDataInputShorteningRule = (text) => {
    let temp = text;
    if (temp && typeof temp === "string") {
      temp = temp.replaceAll("|", ". ");
      temp = temp.replaceAll("- ", ". ");
      temp = temp.replaceAll("And", ". ");
      temp = temp.replaceAll(";", ". ");
      // temp = temp.replaceAll("'", ".");
      let n = temp.split("");
      let indexOFExpected;
      for (let i = n.length; i >= 0; i--) {
        if (n[i] === "." || n[i] === "?" || n[i] === "!" || n[i] === ")") {
          indexOFExpected = i;
          i = -1;
        }
      }
      if (indexOFExpected) {
        n.length = indexOFExpected + 1;
        temp = n.join("");
      }
    }
    return temp;
  };

  const cleanOgData = (text) => {
    let temp = text;
    if (temp && typeof temp === "string") {
      if (
        temp.endsWith("..") ||
        temp.endsWith("…") ||
        temp.endsWith("..." || temp.endsWith("...." || temp.endsWith(".....")))
      ) {
        //found the conditions
        let n = temp.split("");
        let indexOFExpected;
        for (let i = n.length; i >= 0; i--) {
          if (n[i] === "." || n[i] === "?" || n[i] === "!" || n[i] === ")") {
            indexOFExpected = i;
            i = -1;
          }
        }
        if (indexOFExpected) {
          n.length = indexOFExpected + 1;
          temp = n.join("");
        }
        temp.replaceAll("|", ".");
        temp.replaceAll("•", ".");
      }
      let splibYthreeDots = text.split("...");
      if (splibYthreeDots.length > 1) {
        temp = splibYthreeDots.join(". ");
      }
    }
    return temp;
  };
  const combineSerpWithWebScrap = (text) => {
    let temp = text;
    if (text && typeof temp === "string") {
      let n = temp?.split(" ");
      let searchFor = `${n[n.length - 4]} ${n[n.length - 3]} ${
        n[n.length - 2]
      }`;
      if (allData?.scrapDiffBot) {
        if (allData?.scrapDiffBot.search(searchFor) >= 0) {
          if (allData?.scrapDiffBot.length) {
            // comibing both serp and web conditionally with no rules check! to-do
            // temp += " " + boiler;
            temp += allData?.scrapDiffBot;
          }
        } else if (allData?.scrapDiffBot.length) {
          temp = allData?.scrapDiffBot;
        }
      }
    }
    return temp;
  };
  function countCharacters(char, string) {
    return string
      .split("")
      .reduce((acc, ch) => (ch === char ? acc + 1 : acc), 0);
  }
  function isLetter(str) {
    return str.length === 1 && str.match(/[a-z]/i);
  }

  const applySerpDiscardingRules = (text) => {
    let temp = text;
    console.log("text -> ", text);
    if (temp && typeof temp === "string") {
      // if (text.length < 55) {
      //   temp = false;
      // }
      // const n = text.split(" ");
      // if (n.length < 4) {
      //   temp = false;
      // }
      // let blackListedWord = [
      //   // "Javascript enable",
      //   // "Javascript disable",
      //   // "enable Javascript",
      //   // "disable Javascript",
      //   // "@",
      //   // "Load More",
      //   // "Click here",
      //   // "Read more",
      //   // "See more",
      //   // "Click Enter",
      //   // "Copyright",
      //   // "copyrights",
      //   // "privacy policy",
      //   // "Cookies website",
      //   // "Cookies browser",
      //   // "Cookies device",
      //   // "Cookies browser’s",
      //   // "Cookies site",
      //   // "}",
      //   // "Newsletter",
      //   // "All Rights Reserved",
      //   // "</",
      //   // "©",
      //   // "Lorem",
      //   // "Thank you",
      //   // "Email Address",
      //   // "Terms &",
      //   // "Terms and",
      //   // "Subscribe + offers/deals/product",
      //   // "add to cart",
      //   // "mailing list",
      //   // "Sign up",
      //   // "Please + use/refer/reference",
      //   // "further notice",
      //   // "create an account",
      //   // "Monday-Friday",
      //   // "%%",
      //   // "This page",
      //   // "COVID",
      //   // "Disclaimer",
      //   // "Email list",
      //   // "Cookies + website/browser/device",
      //   // ".com",
      //   // ".net",
      //   // ".co",
      //   // ".io",
      //   // ".org",
      //   // ".mp3",
      //   // ".mp4",
      //   // ".png",
      //   // ".svg",
      //   // ".jpeg",
      //   // ".eu",
      //   // "Http",
      //   // "Https",
      //   // "LLC",
      //   // "Filter by",
      //   // "Sort By",
      //   // "Select Options",
      //   // "·",
      //   // "· ",
      //   //"...", // removing this temprorily
      // ];
      // blackListedWord.forEach((e, i) => {
      //   if (text.split(e).length > 1) {
      //     if (e === "..." && i !== blackListedWord.length) {
      //       temp = false;
      //     } else {
      //       temp = false;
      //     }
      //   }
      // });
      countCharacters("$", temp);
      // if (
      //   temp?.length &&
      //   (!isLetter(temp[0]) ||
      //     temp.match(/^\d/) ||
      //     temp[0] !== temp[0].toUpperCase() ||
      //     countCharacters("$", temp) > 2 ||
      //     countCharacters(",", temp) > 10 ||
      //     countCharacters(";", temp) > 5 ||
      //     countCharacters("|", temp) > 6 ||
      //     countCharacters("-", temp) > 7)
      // ) {
      //   temp = false;
      // }
    }

    // temp &&
    // !temp &&
    //return false if discarded
    console.log(
      temp ? "No Blacllisted word found!" : "balcklisted found ",
      `word ->${temp}<-`
    );
    return temp;
  };
  const applySerpRulesOnly = (text) => {
    // replacements
    let temp = text;
    if (text?.length && typeof text === "string") {
      temp = temp.replaceAll("|", ".");
      temp = temp.replaceAll("- ", ".");
      temp = temp.replaceAll("And", ".");
      temp = temp.replaceAll(";", ".");
      // temp = temp.replaceAll("'", ".");

      /**  unless not found on website scrapping **/
      let n = temp.split("");
      let indexOFExpected;
      for (let i = n.length; i >= 0; i--) {
        if (n[i] === "." || n[i] === "?" || n[i] === "!" || n[i] === ")") {
          indexOFExpected = i;
          i = -1;
        }
      }
      if (indexOFExpected) {
        n.length = indexOFExpected + 1;
        temp = n.join("");
      }
      /**  unless not found on website scrapping **/
    }
    return temp;
  };
  const formAtGpt3 = (string) => {
    if (!string || !string.length) return string;

    var formattedString = string.split(". ").join(".\n");
    formattedString = formattedString.split("! ").join(".\n");
    formattedString = formattedString.split("? ").join(".\n");
    var formattedStringWithExceptions = string.split(". ");
    let temp = "";
    formattedStringWithExceptions.forEach((e) => {
      if (e.length > 5 && e.substr(e.length - 3) === "U.S") {
        let s = e.replace("\n", " ");
        temp += s + ". ";
      } else {
        temp += e + ".\n";
      }
    });
    formattedString = temp;
    return String(finalCleaning(formattedString));
  };
  const unLoad = () => setLoad(false);

  const finalCleaning = (text) => {
    let input = text;
    if (input && typeof input === "string") {
      var indices = [];
      for (var i = 0; i < input.length; i++) {
        if (input[i] === ".") indices.push(i);
        if (input[i] === ".") indices.push(i);
        if (input[i] === "?") indices.push(i);
        if (input[i] === "!") indices.push(i);
        if (input[i] === ")") indices.push(i);
      }

      // trying to split the text
      const expectedLastWord = text[text.length - 1];
      //
      // looping to reduce the ifs eg .===.
      let specChar = ["."];
      let indice = text;
      specChar.forEach((specialChar) => {
        if (expectedLastWord !== specialChar) {
          let n = indice.split(specialChar);
          //
          if (!n.length || n.length === 1) return;
          n.length = n.length - 1;
          n = n.join(specialChar);
          indice = n;
        }
      });
      input = indice;

      //seprtting input by "." and removing the last index if < then n
      let sepratedByComma = input.split(".");
      let n = 3;
      if (sepratedByComma[sepratedByComma.length - 1].length <= n) {
        sepratedByComma.length = sepratedByComma.length - 1;
        sepratedByComma = sepratedByComma.join(".");
        input = sepratedByComma;
      }
      // trimimng sentence to 320
      if (input.length > 319) {
        trimingString(input);
      }

      if (
        input[input.length - 1] !== "." &&
        input[input.length - 1] !== "!" &&
        input[input.length - 1] !== "?"
      ) {
        input += ".";
      }
      if (input.search("  ") >= 0) {
        input = "";
      }
      unLoad();
      if (input.length > 319) {
        trimingString(input);
      }
      if (!input.length) setSubHeadline((e) => ({ ...e, description: true }));
      setState((e) => ({
        ...e,
        desc: (input = input?.charAt(0).toUpperCase() + input.slice(1)),
      }));
      setSubHeadline((e) => ({ ...e, description: false }));
      return (input = input?.charAt(0).toUpperCase() + input.slice(1));
      // setState(e=>({...e, desc: input}))
    }
  };
  function trimingString(input) {
    if (!input) return input;
    let n = input.split(".");
    n.length = n.length - 1;
    input = n.join(".");
    if (input.length > 319) {
      trimingString();
    }
  }
  const cleanWebsiteScrapData = (text) => {
    //
    let blackListWords = [
      "Customer Service",
      "Customer Care",
      "Cookies",
      "Order",
      "Orders",
      "share facebook",
      "share twitter",
      "Share pinterest",
      "Follow us",
      "Like us",
      "Returns",
      "@",
      "gift card",
      "page refresh",
      "Terms",
      "Newsletter",
      "I ",
      "Discount",
      "% off",
      "$ off",
      "Refund",
      "Refunds",
      "Warranty",
      "Warranties",
      "customer success",
      "create an account",
      "check out",
      "Donate",
      "donating",
      "Address",
      "Checkout",
      "Community",
      "exclusive deal",
      "exclusive deals",
      "Shipping",
      "I’ve",
      "I’ll",
      "Instructions",
      "Medical",
      "Food and Drug Administration",
      "FDA",
      "Thank you for",
      "contact us",
      "Call us",
      "COVID",
      "COVID-19",
      "size chart",
      "product page",
      "Sizing chart",
      "More info",
      "are you sure",
      "Want to continue",
      "See more",
      "See less",
      "Disclaimer",
      "add to cart",
      "LLC",
      "email list",
      "reach out",
      "Please",
      "Copyright",
      "Privacy Policy",
      "Lorem",
      "Warning",
      "Click Enter",
      "Information",
      "mailing list",
      "Delivery",
      "We will",
      "Browse",
      "Products",
      "}",
      "Scroll",
      "Read more",
      "Continue Reading",
      "Your preferences",
      "enable Javascript",
      "Javascript enable",
      "disable Javascript",
      "Javascript disable",
      "Click here",
      "privacy policy",
      ".com",
      ".net",
      ".co",
      ".io",
      ".org",
      ".mp3",
      ".mp4",
      ".png",
      ".svg",
      ".jpeg",
      ".eu",
      "Click The",
      "Button Below",
    ];
    let temp = text;
    if (temp && temp?.length) {
      blackListWords.forEach((e) => {
        if (temp?.split(e).length > 1) {
          temp = "";
        }
      });
      if (temp?.length && (!isLetter(temp[0]) || temp.match(/^\d/))) {
        temp = "";
      }
      if (
        !temp.endsWith("?") ||
        !temp.endsWith(".") ||
        !temp.endsWith("!" || !temp.endsWith(")"))
      ) {
        //found the conditions
        let n = temp.split("");
        let indexOFExpected;
        for (let i = n.length; i >= 0; i--) {
          if (n[i] === "." || n[i] === "?" || n[i] === "!" || n[i] === ")") {
            indexOFExpected = i;
            i = -1;
          }
        }
        if (indexOFExpected) {
          n.length = indexOFExpected + 1;
          temp = n.join("");
        }
      }
      if (
        temp.endsWith("..") ||
        temp.endsWith("…") ||
        temp.endsWith("..." || temp.endsWith("...." || temp.endsWith(".....")))
      ) {
        //found the conditions
        let n = temp.split("");
        let indexOFExpected;
        for (let i = n.length; i >= 0; i--) {
          if (n[i] === "." || n[i] === "?" || n[i] === "!" || n[i] === ")") {
            indexOFExpected = i;
            i = -1;
          }
        }
        if (indexOFExpected) {
          n.length = indexOFExpected + 1;
          temp = n.join("");
        }
      }
      let words = text.split(" ");
      if (words.length < 4) {
        temp = "";
      }
      if (text.length < 135) {
        temp = "";
      }
    }
    console.log("serp ... rule empty if discarded ", temp);
    // return empty if discarded
    return temp;
  };
  useEffect(() => {
    // disable the next ab test button if limit is reached
    if (
      abTests[0]?.length - 1 <= copyTypeFirstDimension &&
      abTests[1]?.length - 1 <= copyTypeSecondDimension
    ) {
      setDisableNextAdTest(true);
    } else {
      setDisableNextAdTest(false);
    }
  }, [abTests, copyTypeFirstDimension, copyTypeSecondDimension]);

  useEffect(() => {
    if (copyTypeFirstDimension <= 0 && copyTypeSecondDimension <= 0) {
      setdisablePrevAbest(true);
    } else {
      setdisablePrevAbest(false);
    }
  }, [copyTypeFirstDimension, copyTypeSecondDimension]);

  useEffect(() => {
    if (state.desc) {
      finalCleaning(state.desc);
    }
    // eslint-disable-next-line
  }, [state.desc]);
  const oGNotRespected = useCallback(
    async (arg) => {
      var serp;
      if (!state.serp) {
        serp = await postService.scrapWithSerp(arg);
      } else {
        serp = state.serp;
      }
      const s1 =
        serp?.data?.organic_results?.length && serp?.data?.organic_results[0];
      var cleanInput = {
        text: s1?.snippet,
      };
      const clean = await postService.clean(cleanInput);
      if (serp && clean.data !== "data with the faulty input") {
        //
        const moreClean = scrapDataInputShorteningRule(
          combineSerpWithWebScrap(
            applySerpDiscardingRules(applySerpRulesOnly(clean?.data))
          )
        );
        if (moreClean?.length > 55) {
          setState((e) => ({
            ...e,
            desc: formAtGpt3(moreClean),
            serp: serp?.data,
          }));
        } else {
          if (moreClean) {
            // todo: check if this is the right place to do this
          } else {
            const web = scrapDataInputShorteningRule(
              cleanWebsiteScrapData(allData.scrapDiffBot)
            );
            //
            if (web) {
              setState((e) => ({
                ...e,
                desc: formAtGpt3(web),
              }));
              setSubHeadline((e) => ({ ...e, description: false }));
            } else {
              setSubHeadline((e) => ({ ...e, description: true }));
            }
          }
        }
      } else {
        const web = scrapDataInputShorteningRule(
          cleanWebsiteScrapData(allData.scrapDiffBot)
        );

        if (web) {
          setState((e) => ({
            ...e,

            desc: formAtGpt3(web),
          }));
          setSubHeadline((e) => ({ ...e, description: false }));
        } else {
          setSubHeadline((e) => ({ ...e, description: true }));
        }
        // dispatch(
        // );
      }
    },
    // eslint-disable-next-line
    [state?.serp]
  );
  let advanceObj = {
    discount,
    setDiscount,
    location,
    setLocation,
    ocassion,
    setOcasstion,
    freeShipping,
    setFreeShipping,
    headline,
    setHeadline,
    ctaUrl,
    setCtaUrl,
  };
  const combineOgWithSerp = (text) => {
    let temp = text;

    const serp = allData.serp;
    if (serp) {
      const s1 = serp?.organic_results?.length && serp?.organic_results[0];
      //
      if (s1 !== text && s1?.snippet && temp?.length) {
        let words = s1?.snippet.split(" ");
        words.length = 3;
        let threeWords = words.join(" ");
        words = temp.split(" ");
        words.length = 3;
        let threeWordsText = words.join(" ");
        //
        let n = applySerpDiscardingRules(temp + " " + s1?.snippet);
        if (n && threeWords !== threeWordsText) {
          temp = temp + " " + s1?.snippet;
        }
      }
    }
    return temp;
  };
  const validateOgRules = (text) => {
    if (!text) return text;
    let temp = text;
    if (temp && typeof temp === "string") {
      let blacklisteWords = [
        // "Javascript enable",
        // "Javascript disable",
        // "Javascript disabled",
        // "Javascript enabled",
        // "Javascript browser",
        // "Javascript browser’s",
        // "Javascript site",
        // "Javascript website",
        // "@ .com/",
        // "@ net/",
        // "@ org/",
        // "@ io/",
        // "@ uk/",
        // "Click here",
        // "Read more",
        // "See more",
        // "Load More",
        // "Click Enter",
        // "Copyright",
        // "copyrights",
        // "privacy policy",
        // "Cookies website",
        // "Cookies browser",
        // "Cookies device",
        // "Cookies browser’s",
        // "Cookies site",
        // "}",
        // "Newsletter",
        // "All Rights Reserved",
        // "</",
        // "©",
        // "Lorem",
        // "Thank you",
        // "Terms &",
        // "Terms and",
        // "Subscribe offers",
        // "Subscribe deals",
        // "Subscribe product",
        // "add to cart",
        // "mailing list",
        // "Sign up email",
        // "Please use",
        // "Please reference",
        // "Please refer",
        // "further notice",
        // "create an account",
        // "Monday-Friday",
        // "%%",
        // "This page",
        // "COVID",
        // "Disclaimer",
        // "Email list/information",
        // "Filter by",
        // "Sort By",
      ];
      blacklisteWords.forEach((e) => {
        if (temp.search(e) >= 0 && typeof temp === "string") {
          temp = false;
        }
      });
    }
    return temp;
  };
  const ogRespected = async (clean, o) => {
    let desc = combineOgWithSerp(
      validateOgRules(cleanOgData(clean.data ? clean.data : clean))
    );

    setState((e) => ({
      ...e,
      desc: desc,
      descCopy: desc,
      openGraph: o?.data,
    }));
    // history.push("/userpanel/AdOptimization", { ...state, desc: desc });
  };
  const optimizeData = async (d) => {
    const serp = d?.serp;
    const openGraph = d.og;
    const boiler =
      d?.scrapDiffBot?.objects?.length && d?.scrapDiffBot?.objects[0].text;
    if (d?.url === "NO_URI" || !d?.url) {
      setLoad(false);
      setState((e) => ({ ...e, url: "" }));
      return;
    } else {
      var cleanInput = {
        text: "",
      };
      if (serp) {
        setState((e) => ({ ...e, serp: serp }));
      }

      // cause : {} == boiler /= exception
      if (boiler && typeof boiler === "string") {
        setState((e) => ({ ...e, boiler: boiler }));
      }

      cleanInput.text = openGraph?.htmlInferred?.description;
      if (openGraph) {
        titleOfProduct(d.url, openGraph);
      }
      if (cleanInput.text) {
        const clean = await postService.clean(cleanInput);

        if (!clean.data || clean?.data === "data with the faulty input") {
          oGNotRespected(d);
        } else {
          ogRespected(clean.data, openGraph);
        }
      } else {
        oGNotRespected(d);
      }
      if (openGraph) {
        setState((e) => ({ ...e, openGraph: openGraph }));
      }
    }
    return;
  };
  const filterGpt3Data = (arr) => {
    if (!arr?.length) return arr;
    // cant use reduce sometime we need to stop it from returning
    let temp = [];
    arr.forEach((e) => {
      if (
        e?.onlyText !== "DANGEROUS_CONTENT" &&
        e?.onlyText !== "DANGEROUS_CONTENT!" &&
        e?.onlyText !== "DANGEROUS_CONTENT" &&
        e?.onlyText !== "DANGEROUS_CONTENT." &&
        e?.onlyText.search("#") < 0
      )
        temp.push(e);
    });
    return temp;
  };
  const generateAdCopy = async () => {
    try {
      let data = {
        state: { ...state, desc: state.desc ? state.desc : state.descCopy },
        authToken: localStorage.getItem("gpt3token"),
        uid: localStorage.getItem("uid"),
        rcToken: localStorage.getItem("gpt3token"),
        url,
      };
      setGpt3Loading(true);
      setScrapContents(true);
      if (user?.coins < 10) {
        // alert("Not Enough coins");
        dispatch(
          modalAction.success({
            title: "Not Enough coins",
            content: (
              <button onClick={() => navigate("/packages")}>
                Buy more coins
              </button>
            ),
          })
        );
        setGpt3Loading(false);
        return;
      }
      setState((e) => ({
        ...e,
        pd: [],
        lof: [],
        qrsftb: [],
        tl: [],
        pcg: [],
        ftb: [],
        qq: [],
        types: [],
        statticType: [],
        allGeneratedData: [],
      }));
      setCurrentStaticTypes([]);
      setCurrIndex({
        start: 0,
        end: 10,
      });
      let typeOFCopy = routeLocation?.state?.type;
      if (
        typeOFCopy === "google" ||
        typeOFCopy === "google_headline" ||
        typeOFCopy === "google_description"
      ) {
        data = {};
        data.type = "demo/google_ads_title";
        data.text = `Name:\n${state?.openGraph?.htmlInferred?.site_name}\n\nCategory:\n${state.audience}\n\nDescription:\n${state.desc}`;
        data.numChoices = 3;
        const res = await gpt3.generateGoogleAd({ ...data });

        data.type = "demo/google_ads_body";
        const res2 = await gpt3.generateGoogleAd({ ...data });
        setState((e) => ({
          ...e,
          google: filterGpt3Data(res.data),
          googleBody: filterGpt3Data(res2.data),
        }));

        setLoad(false);
        setGpt3Loading(false);
        return;
      }
      const res = await gpt3.generateBatch1(data);
      // console.log(res);
      // const res = await gpt3.fetchGpt3DataFromServer(data);

      if (res.data) {
        setGpt3Data(res.data);
        var temp = [];
        res.data.forEach((data) => {
          return (temp = [...temp, ...data]);
        });
        setCardData(temp);
        setLoad(false);
        /* @ 
    . ftb = pd/  ftb + pd/ ftb + lof/ qq + pd / qq + lof 
    . bbc = ftb/ ftb +pd/ ftb + lof/ qq + ftb
    . story = pcg
    . qq / qq + ftb / qq + pd/ qq + lof
      */
        // setting batch data;
        // 0-    "template/pitch_s",
        // 1-  "template/listoffeatures_s",
        // 2-  "template/tagline",
        // 3-   "template/question_s",
        // 4-  "template/pgc2",
        // 5-  "template/featuretobenefit_s",
        const filteredPd = filterGpt3Data(res?.data[0]);
        const filteredlof = filterGpt3Data(res?.data[1]);
        const filteredqrsft = filterHeadline(filterGpt3Data(res?.data[2]));
        const filteredtl = filterGpt3Data(res?.data[2]);
        const filteredpcg = filterGpt3Data(res?.data[4]);
        const filteredftb = filterGpt3Data(res?.data[5]);
        const filteredqq = filterGpt3Data(res?.data[3]);
        let type = [
          [filteredPd], //0 fbc
          [filteredftb], //5  bbc
          [filteredftb, filteredPd], //5 0 fbc bbc
          [filteredftb, filteredlof], //5 1 fbc bbc
          [filteredpcg], //4 sbc
          [filteredqq], //3 qqbc
          [filteredqq, filteredftb], //3 5 bbc  qqbc
          [filteredqq, filteredPd], //3 0  fbc  qqbc
          [filteredqq, filteredlof], //3 1 fbc  qqbc
        ]; //dimensinal array because it can be multiple types as per v3
        // modified linked list insted of one dictionary key it has multiple
        let staticType = [
          {
            a: filteredPd,
            type: 0,
          },
          {
            a: filteredftb,
            type: 1,
          },
          {
            a: filteredftb,
            b: filteredPd,
            type: 2,
          },
          {
            a: filteredftb,
            b: filteredlof,
            type: 3,
          },
          {
            a: filteredpcg,
            type: 4,
          },
          // {
          //   a: filteredqq,
          //   type: 5,
          // },
          {
            a: filteredqq,
            b: filteredftb,
            type: 6,
          },
          {
            a: filteredqq,
            b: filteredPd,
            type: 7,
          },
          {
            a: filteredqq,
            b: filteredlof,
            type: 8,
          },
        ];
        const statictype = fisherYates(generateStaticCombinations(staticType));
        // await
        let allGeneratedData = [];
        statictype.forEach((elem) => {
          allGeneratedData.push(generateDataWithoutPointer(elem));
        });
        setState((e) => ({
          ...e,
          pd: filteredPd,
          lof: filteredlof,
          qrsftb: filteredqrsft,
          tl: filteredtl,
          pcg: filteredpcg,
          ftb: filteredftb,
          qq: filteredqq,
          types: type,
          statticType: statictype,
          allGeneratedData,
        }));

        if (filteredqrsft && filteredqrsft.length) {
          let temp = [];
          filteredqrsft.forEach((e) => temp.push(e.onlyText));
          setHeadline((e) => ({ ...e, text: temp }));
          setHeaalineAbTestA((e) => ({ ...e, text: temp }));
          setHeaalineAbTestB((e) => ({ ...e, text: temp }));
        }
        setGpt3Loading(false);
        loadFunc(allGeneratedData);
      }
      const uid = localStorage.getItem("uid");

      authService.removeCoin({
        coin: 10,
        id: uid,
      });
      handleRefreshAdCopy();
      dispatch(authActions.getUser(uid));
      // dispatch(postAction.setPostSettings(data));
      setGpt3Loading(false);
    } catch (err) {
      console.log("err ==> ", err);
    }
  };
  const reGenerate = async (prev, image, productName) => {
    if (!prev) return;
    setActive(0);
    setGpt3Loading(true);
    setGpt3Data(prev);
    var temp = [];
    prev.forEach((data) => {
      return (temp = [...temp, ...data]);
    });
    setCardData(temp);
    setLoad(false);
    /* @ 
    . ftb = pd/  ftb + pd/ ftb + lof/ qq + pd / qq + lof 
    . bbc = ftb/ ftb +pd/ ftb + lof/ qq + ftb
    . story = pcg
    . qq / qq + ftb / qq + pd/ qq + lof
      */
    // setting batch data;
    // 0-    "template/pitch_s",
    // 1-  "template/listoffeatures_s",
    // 2-  "template/tagline",
    // 3-   "template/question_s",
    // 4-  "template/pgc2",
    // 5-  "template/featuretobenefit_s",
    const filteredPd = filterGpt3Data(prev[0]);
    const filteredlof = filterGpt3Data(prev[1]);
    const filteredqrsft = filterHeadline(filterGpt3Data(prev[2]));
    const filteredtl = filterGpt3Data(prev[2]);
    const filteredpcg = filterGpt3Data(prev[4]);
    const filteredftb = filterGpt3Data(prev[5]);
    const filteredqq = filterGpt3Data(prev[3]);
    let type = [
      [filteredPd], //0 fbc
      [filteredftb], //5  bbc
      [filteredftb, filteredPd], //5 0 fbc bbc
      [filteredftb, filteredlof], //5 1 fbc bbc
      [filteredpcg], //4 sbc
      [filteredqq], //3 qqbc
      [filteredqq, filteredftb], //3 5 bbc  qqbc
      [filteredqq, filteredPd], //3 0  fbc  qqbc
      [filteredqq, filteredlof], //3 1 fbc  qqbc
    ]; //dimensinal array because it can be multiple types as per v3
    // modified linked list insted of one dictionary key it has multiple
    let staticType = [
      {
        a: filteredPd,
        type: 0,
      },
      {
        a: filteredftb,
        type: 1,
      },
      {
        a: filteredftb,
        b: filteredPd,
        type: 2,
      },
      {
        a: filteredftb,
        b: filteredlof,
        type: 3,
      },
      {
        a: filteredpcg,
        type: 4,
      },
      // {
      //   a: filteredqq,
      //   type: 5,
      // },
      {
        a: filteredqq,
        b: filteredftb,
        type: 6,
      },
      {
        a: filteredqq,
        b: filteredPd,
        type: 7,
      },
      {
        a: filteredqq,
        b: filteredlof,
        type: 8,
      },
    ];
    const statictype = fisherYates(generateStaticCombinations(staticType));
    // await

    setState((e) => ({
      ...e,
      pd: filteredPd,
      lof: filteredlof,
      qrsftb: filteredqrsft,
      tl: filteredtl,
      pcg: filteredpcg,
      ftb: filteredftb,
      qq: filteredqq,
      types: type,
      statticType: statictype,
      kalazify: { logo: image },
      productName,
      openGraph: {
        openGraph: {
          image: {
            url: image,
          },
        },
      },
    }));
    if (filteredqrsft && filteredqrsft.length) {
      let temp = [];
      filteredqrsft.forEach((e) => temp.push(e.onlyText));
      setHeadline((e) => ({ ...e, text: temp }));
      setHeaalineAbTestA((e) => ({ ...e, text: temp }));
      setHeaalineAbTestB((e) => ({ ...e, text: temp }));
    }
    setGpt3Loading(false);
  };
  const generateStaticCombinations = (arr) => {
    let temp = [];
    arr.forEach((e) => {
      //slecting type to make switches work based on their index add type more then one is possible using array;

      //if it has b corrdinate
      // the system a and b
      if (e.b) {
        e.a.forEach((x) => {
          e.b.forEach((y) => {
            temp.push({ a: x, b: y, types: e.type });
          });
        });
      } else {
        e.a.forEach((s) => {
          temp.push({ a: s, types: e.type });
        });
      }
    });
    dispatch(postAction.setMax(temp.length - 1));
    return temp;
  };
  const filterHeadline = (tl) => {
    const res = tl.reduce((accu, curr) => {
      let noOfWords = curr?.onlyText.split(" ");
      if (noOfWords.length < 11) return [curr, ...accu];
      return accu;
    }, []);
    return res;
  };
  function fisherYates(array) {
    var count = array.length,
      randomnumber,
      temp;
    while (count) {
      randomnumber = (Math.random() * count--) | 0;
      temp = array[count];
      array[count] = array[randomnumber];
      array[randomnumber] = temp;
    }
    return array;
  }
  const IndexChanger = {
    changeIndexDiscount: () => {
      if (discount.disable) return;
      setDiscount((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeIndexOcassion: () => {
      if (ocassion.disable) return;
      setOcasstion((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeHeadline: () => {
      if (discount.disable) return;
      setHeadline((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeHeadlineA: () => {
      if (headlineAbTestA.disable) return;
      setHeaalineAbTestA((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeHeadlineB: () => {
      if (headlineAbTestB.disable) return;
      setHeaalineAbTestB((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeIndexFreeShipping: () => {
      if (freeShipping.disable) return;
      setFreeShipping((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeIndexCtaUrl: () => {
      if (ctaUrl.disable) return;
      setCtaUrl((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    changeIndexLocation: () => {
      if (location.disable) return;
      setLocation((e) => ({
        ...e,
        index: e.index >= e.text?.length - 1 ? 0 : e.index + 1,
      }));
    },
    disableLocation: () => {
      setLocation((e) => ({
        ...e,
        disable: !e.disable,
      }));
    },
    disableFreeShipping: () => {
      setFreeShipping((e) => ({
        ...e,
        disable: !e.disable,
      }));
    },
    disableCtaUrl: () => {
      setCtaUrl((e) => ({
        ...e,
        disable: !e.disable,
      }));
    },
    disableDiscount: () => {
      setDiscount((e) => ({
        ...e,
        disable: !e.disable,
      }));
    },
    disableOcassion: () => {
      setOcasstion((e) => ({
        ...e,
        disable: !e.disable,
      }));
    },
  };
  const handleChange = (event, name) => {
    let limit = 2;
    const type = post.abTest?.type;
    //some bug causing the integer to overflow or js to loose stack in heap?
    if (type === "linkedin" || type === "tiktok") limit = 3;

    let isEnabled = 0;
    const allStates = Object.keys(typeState);
    allStates.forEach((e) => {
      if (typeState[e] === true) {
        isEnabled += 1;
      }
    });
    if (isEnabled < limit && !event) return;
    setTypeState({ ...typeState, [name]: event });
    handleRefreshAdCopy();
  };
  const handleRefreshAdCopy = () => {
    dispatch(postAction.slectDirection("right"));
    // adding param to minimize the probability of of same ad first argument
    // second argument is for removing possibility for uncheck system
    let possibleIndex = new Array(9);
    if (typeState.ftb) {
      possibleIndex[0] = true;
      possibleIndex[1] = true;
      possibleIndex[2] = true;
      possibleIndex[3] = true;
    } else if (!typeState.ftb) {
      possibleIndex[0] = false;
      possibleIndex[1] = false;
      possibleIndex[2] = false;
      possibleIndex[3] = false;
    }
    if (typeState.lof) {
      possibleIndex[1] = true;
      possibleIndex[2] = true;
      possibleIndex[3] = true;
      possibleIndex[6] = true;
    } else if (!typeState.lof) {
      possibleIndex[1] = false;
      possibleIndex[2] = false;
      possibleIndex[3] = false;
      possibleIndex[6] = false;
    }
    if (typeState.pcg) {
      possibleIndex[4] = true;
    } else {
      possibleIndex[4] = false;
    }
    if (typeState.qq) {
      possibleIndex[5] = true;
      possibleIndex[6] = true;
      possibleIndex[7] = true;
      possibleIndex[8] = true;
    } else {
      possibleIndex[5] = false;
      possibleIndex[6] = false;
      possibleIndex[7] = false;
      possibleIndex[8] = false;
    }
    let typeOFCopy = routeLocation?.state?.type;
    if (typeOFCopy === "aida" || typeOFCopy === "pas") {
      possibleIndex[0] = false;
      possibleIndex[1] = false;
      possibleIndex[2] = false;
      possibleIndex[3] = false;
      possibleIndex[4] = true;
      possibleIndex[5] = false;
      possibleIndex[6] = false;
      possibleIndex[7] = false;
      possibleIndex[8] = false;
    }
    if (typeOFCopy === "ftb" || typeOFCopy === "unique") {
      possibleIndex[0] = true;
      possibleIndex[1] = true;
      possibleIndex[2] = true;
      possibleIndex[3] = true;
      possibleIndex[4] = false;
      possibleIndex[5] = false;
      possibleIndex[6] = false;
      possibleIndex[7] = false;
      possibleIndex[8] = false;
    }
    if (typeOFCopy === "engaging_question") {
      possibleIndex[0] = false;
      possibleIndex[1] = false;
      possibleIndex[2] = false;
      possibleIndex[3] = false;
      possibleIndex[4] = false;
      possibleIndex[5] = true;
      possibleIndex[6] = true;
      possibleIndex[7] = true;
      possibleIndex[8] = true;
    }
    if (typeOFCopy === "persuasive_bullet_points") {
      possibleIndex[0] = false;
      possibleIndex[1] = true;
      possibleIndex[2] = true;
      possibleIndex[3] = true;
      possibleIndex[4] = false;
      possibleIndex[5] = false;
      possibleIndex[6] = true;
      possibleIndex[7] = false;
      possibleIndex[8] = false;
    }
    //checking for available ad types
    generatedata();
    console.log(possibleIndex);
    dispatch(
      postAction.generateRandomNumberForCopyType(post.copyIndex, possibleIndex)
    );
  };
  const findEnableCopyIndex = () => {
    let possibleIndex = new Array(9);
    //

    if (typeState.ftb) {
      possibleIndex[0] = true;
      possibleIndex[2] = true;
      // possibleIndex[3] = true;
      // possibleIndex[7] = true;
      // possibleIndex[8] = true;
      possibleIndex[9] = true;
    } else if (!typeState.ftb) {
      possibleIndex[0] = false;
      possibleIndex[2] = false;
      possibleIndex[3] = false;
      possibleIndex[8] = false;
      possibleIndex[7] = false;
      possibleIndex[9] = false;
    }
    if (typeState.pcg) {
      possibleIndex[4] = true;
    } else {
      possibleIndex[4] = false;
    }
    if (typeState.qq) {
      possibleIndex[5] = true;
      possibleIndex[6] = true;
      possibleIndex[7] = true;
      possibleIndex[8] = true;
    } else {
      possibleIndex[5] = false;
      possibleIndex[6] = false;
      if (!typeState.ftb) {
        if (!typeState.qq) {
          possibleIndex[7] = false;
          possibleIndex[8] = false;
        }
      }
    }
    if (typeState.lof) {
      possibleIndex[1] = true;
      possibleIndex[2] = true;
      possibleIndex[3] = true;
      // possibleIndex[6] = true;
    } else if (!typeState.lof) {
      possibleIndex[1] = false;
      if (!typeState.ftb) {
        possibleIndex[2] = false;
        possibleIndex[3] = false;
      }
      if (!typeState.qq) {
        possibleIndex[6] = false;
      }
    }
    return possibleIndex;
  };
  const findPointer = (types) => {
    let rec = copyIndex;
    let lim = 0;
    let copyType = false;
    do {
      rec += 1;
      lim += 1;
      if (rec >= post.maxIndex) rec = 0;
      copyType = state.statticType[rec].types;
      copyType = types[copyType];
      if (copyType === true) {
        lim = 1000;
        break;
      }
      if (lim > 1000) break;
    } while (!copyType);
    return rec;
  };
  function generateDataWithoutPointer(elem) {
    let a = elem.a;
    let b = elem.b;
    let temp = [];
    if (a && b) {
      let temp2 = "";
      if (a && a.template?.type === "template/pgc2") {
        let divided = a?.onlyText.split(":");
        if (divided.length === 4) {
          let types = {
            pain: divided[1].replace("Gain", ""),
            gain: divided[2].replace("Claim", ""),
            claim: divided[3],
          };
          temp2 += types;
        }
      } else {
        if (
          a.template?.type === "template/featuretobenefit_s" ||
          a.template?.type === "template/question_s"
        ) {
          temp2 += a.onlyText + "<br /><br />";
        } else {
          temp2 += a.onlyText + "<br />";
        }
      }
      if (b && b.template?.type === "template/pgc2") {
        let divided = b?.onlyText.split(":");
        if (divided.length === 4) {
          let types = {
            pain: divided[1].replace("Gain", ""),
            gain: divided[2].replace("Claim", ""),
            claim: divided[3],
          };
          temp2 += types;
        }
      } else if (b.onlyText) {
        //
        if (b.template.type === "template/listoffeatures_s") {
          temp2 += formatLof(b?.onlyText);
        } else {
          temp2 += b.onlyText;
        }
      }

      temp.push(formAtGpt3(temp2));
      // if only a is there
    } else if (a && a.template?.type === "template/pgc2") {
      let divided = a?.onlyText.split(":");
      if (divided.length === 4) {
        let types = {
          pain: divided[1].replace("Gain", ""),
          gain: divided[2].replace("Claim", ""),
          claim: divided[3],
        };
        temp.push(types);
      }
    } else {
      temp.push(formAtGpt3(a.onlyText));
    }
    if (typeof temp[0] === "object") {
      let result = temp.reduce((accumlator, currentValue) => {
        if (currentValue.pain || currentValue.claim || currentValue.gain) {
          accumlator += currentValue.pain;
          accumlator += currentValue.gain;
          accumlator += currentValue.claim;
        } else {
          formAtGpt3(currentValue);
        }
        return accumlator;
      }, "");
      //   appending cta url
      // if (url && !isEdited) {
      //   result += FreeShipping();
      //   result = Location() + result;
      //   result += Discount();
      //   // result += CTAUrl();
      // }
      temp = [result];
    }
    return temp;
  }
  const generatedata = () => {
    let temp = [];
    if (post.direction === "right" || post.direction === "left") {
      const types = findEnableCopyIndex();
      const stateType = state.statticType;
      if (!stateType.length) return;
      if (!state.statticType[copyIndex]) return;
      let a = stateType[copyIndex].a;
      let b = stateType[copyIndex].b;
      let copyType = stateType[copyIndex].types;
      if (!types[copyType] && post.direction !== "left") {
        const pointerToGO = findPointer(types, stateType);
        dispatch(postAction.goto(pointerToGO));
        return;
      }
      if (a && b) {
        let temp2 = "";
        if (a && a.template?.type === "template/pgc2") {
          let divided = a?.onlyText.split(":");
          if (divided.length === 4) {
            let types = {
              pain: divided[1].replace("Gain", ""),
              gain: divided[2].replace("Claim", ""),
              claim: divided[3],
            };
            temp2 += types;
          }
        } else {
          if (
            a.template?.type === "template/featuretobenefit_s" ||
            a.template?.type === "template/question_s"
          ) {
            temp2 += a.onlyText + "<br /><br />";
          } else {
            temp2 += a.onlyText + "<br />";
          }
        }
        if (b && b.template?.type === "template/pgc2") {
          let divided = b?.onlyText.split(":");
          if (divided.length === 4) {
            let types = {
              pain: divided[1].replace("Gain", ""),
              gain: divided[2].replace("Claim", ""),
              claim: divided[3],
            };
            temp2 += types;
          }
        } else if (b.onlyText) {
          //
          if (b.template.type === "template/listoffeatures_s") {
            temp2 += formatLof(b?.onlyText);
          } else {
            temp2 += b.onlyText;
          }
        }

        temp.push(formAtGpt3(temp2));
        // if only a is there
      } else if (a && a.template?.type === "template/pgc2") {
        let divided = a?.onlyText.split(":");
        if (divided.length === 4) {
          let types = {
            pain: divided[1].replace("Gain", ""),
            gain: divided[2].replace("Claim", ""),
            claim: divided[3],
          };
          temp.push(types);
        }
      } else {
        temp.push(formAtGpt3(a.onlyText));
      }
      if (typeof temp[0] === "object") {
        let result = temp.reduce((accumlator, currentValue) => {
          if (currentValue.pain || currentValue.claim || currentValue.gain) {
            accumlator += currentValue.pain;
            accumlator += currentValue.gain;
            accumlator += currentValue.claim;
          } else {
            formAtGpt3(currentValue);
          }
          return accumlator;
        }, "");
        //   appending cta url
        // if (url && !isEdited) {
        //   result += FreeShipping();
        //   result = Location() + result;
        //   result += Discount();
        //   // result += CTAUrl();
        // }
        temp = [result];
      }

      setData(temp);
      // gernrateScore(temp);
    }
  };
  const handleAbTestRemix = () => {
    const isFirstRow = changeFirstIndexOfFirstDimensionOFAdCopy();
    if (!isFirstRow) {
      setCopyTypeFirstDimension(0);
    }
  };

  const changeFirstIndexOfFirstDimensionOFAdCopy = () => {
    if (abTests[0]?.length - 1 <= copyTypeFirstDimension) return false;
    setCopyTypeFirstDimension((e) => e + 1);
    return true;
  };
  const handleNextDimesion = () => {
    const firstresponse = changeFirstIndexOfFirstDimensionOFAdCopy();
    const secondresponse = changeSecondINdexOfSecondDimensionOfAdCopy();
    if (secondresponse || firstresponse) {
      IndexChanger.changeHeadlineA();
      IndexChanger.changeHeadlineB();
    }
  };
  const handleSecondDimension = () => {
    const firstresponse = changeFirstIndexOfFirstDimensionOFAdCopyNegative();
    const secondresponse = changeSecondINdexOfSecondDimensionOfAdCopyNegative();
    if (secondresponse || firstresponse) {
      IndexChanger.changeHeadlineA();
      IndexChanger.changeHeadlineB();
    }
  };
  const changeFirstIndexOfFirstDimensionOFAdCopyNegative = () => {
    if (copyTypeFirstDimension <= 0) return;
    setCopyTypeFirstDimension((e) => e - 1);
    return true;
  };
  const changeSecondINdexOfSecondDimensionOfAdCopyNegative = () => {
    if (copyTypeSecondDimension <= 0) return;
    setCopyTypeSecondDimension((e) => e - 1);
    return true;
  };
  const formatLof = (string) => {
    var formattedString;
    // var formattedString = string?.split(".").join(".\n<br />");
    formattedString = string?.split("✅");
    if (formattedString.length > 1) {
      // formattedString.shift();
      let temp = "";
      formattedString.forEach((e, i) => {
        if (i !== 0 && i !== 1) {
          temp += `<br />✅${e}`;
        } else if (i === 1) {
          temp += `✅${e}`;
        }
      });
      formattedString = temp;
      return formattedString;
    }
  };

  //Discount
  useEffect(() => {
    if (advanceSetting == null) return;
    if (advanceSetting?.discount?.active) {
      const discount = advanceSetting.discount;
      const offType = discount.offType === "dollar_off" ? "$" : "%";
      const value = offType === "$" ? discount.discount : discount.per;
      let temp = [];
      discount.promo &&
        strings?.discount.forEach((string) => {
          temp.push(string(value + offType, discount.promo));
        });
      !discount.promo &&
        strings?.discountWithOutPromo.forEach((string) => {
          discount.offType === "dollar_off"
            ? temp.push(string(offType + discount.discount))
            : temp.push(string(discount.per + offType));
        });
      setDiscount((e) => ({ ...e, text: temp }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceSetting, strings.discount]);

  //Free Shipping
  useEffect(() => {
    if (advanceSetting == null) return;
    let temp = [];
    if (advanceSetting?.freeShipping?.active) {
      if (
        advanceSetting?.freeShipping.discount &&
        advanceSetting?.freeShipping.discount !== "0"
      ) {
        strings?.freeShippingWithValue.forEach((string) => {
          temp.push(string("$" + advanceSetting.freeShipping.discount));
        });
      }
      if (advanceSetting?.freeShipping.discount === "0") {
        strings?.freeShipping.forEach((string) => {
          temp.push(string);
        });
      }
    }
    setFreeShipping((e) => ({
      ...e,
      text: temp,
    }));
  }, [advanceSetting, strings?.freeShipping, strings.freeShippingWithValue]);

  //CTA URL
  useEffect(() => {
    let temp = [];
    if (state.url) {
      strings?.cta.forEach((string) => {
        temp.push(string(state.url));
      });
    }
    setCtaUrl((e) => ({
      ...e,
      text: temp,
    }));
  }, [strings.cta, state.url]);
  useEffect(() => {
    if (advanceSetting == null) return;
    let temp = [];
    let temp2 = [];
    if (advanceSetting?.location?.active) {
      strings?.locations.forEach((string) => {
        temp.push(string(advanceSetting.location.name));
      });
    }
    if (advanceSetting?.ocassion?.active) {
      strings?.locations?.forEach((string) => {
        temp2.push(string(advanceSetting.ocassion.name));
      });
    }
    setLocation((e) => ({
      ...e,
      text: temp,
    }));
    setOcasstion((e) => ({
      ...e,
      text: temp2,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceSetting, strings?.location]);
  const content = (
    <div>
      <div className="flex">
        <div>
          <SwitchBtn
            checked={typeState.ftb}
            onChange={(e) => handleChange(e, "ftb")}
            name="ftb"
          />
        </div>
        <div className="ml-3">Feature-based copy</div>
      </div>
      <div className="flex mt-3">
        <div>
          <SwitchBtn
            checked={typeState.pcg}
            onChange={(e) => handleChange(e, "pcg")}
            name="pcg"
            className="switch-btn"
          />
        </div>
        <div className="ml-3">Storytelling-based copy</div>
      </div>
      <div className="flex mt-3">
        <div>
          <SwitchBtn
            checked={typeState.qq}
            onChange={(e) => handleChange(e, "qq")}
            name="qq"
            className="switch-btn"
          />
        </div>
        <div className="ml-3">Question-based copy</div>
      </div>
      <div className="flex mt-3">
        <div>
          <SwitchBtn
            checked={typeState.lof}
            onChange={(e) => handleChange(e, "lof")}
            name="lof"
            className="switch-btn"
          />
        </div>
        <div className="ml-3">Benefit-based copy</div>
      </div>
    </div>
  );

  const loadFunc = (allGeneratedData) => {
    // console.log("===> ", currIndex);
    // console.log(currentStaticTypes);
    if (allGeneratedData.length) {
      setCurrentStaticTypes((e) => [
        ...e,
        ...allGeneratedData.splice(currIndex.start, currIndex.end),
      ]);
    }
    if (
      state.allGeneratedData.length &&
      currIndex.end < state.allGeneratedData.length
    ) {
      let temp = [];
      let end =
        currIndex.end + 10 > state.allGeneratedData.length
          ? state.allGeneratedData.length
          : currIndex.end;
      for (let i = currIndex.start; i < end; i++) {
        temp.push(state.allGeneratedData[i]);
      }
      setCurrentStaticTypes((e) => [...e, ...temp]);
    }
    setCurrIndex((e) => ({
      start: e.start + 10,
      end: e.end + 10,
    }));
  };
  return (
    <div className="mx-auto px-3  container">
      <div className="flex header flex-wrap sm:flex-nowrap items-center justify-between pt-5 z-50">
        <div className="flex items-center justify-start header-content-genration">
          <div className="pr-4">
            <UserProfile
              title={routeLocation?.state?.heading}
              subtitle={routeLocation?.state?.description}
              img={routeLocation?.state?.image}
            />
          </div>
        </div>
        <div>
          <Btn
            onClick={() => {
              goBack();
            }}
            value={
              <>
                <ArrowLeftOutlined /> Try some more Ad Copies
              </>
            }
            bgColor="#0E1526"
            color="white"
          />
        </div>
      </div>
      {abTesting ? (
        <AbTest
          pcg={false}
          setAbTests={setAbTests}
          load={load}
          abTests={abTests}
          state={state}
          type={routeLocation?.state?.type}
          second={true}
          setAbTesting={setAbTesting}
          abType={abTypeB}
          headline={headlineAbTestB}
          handleAbRemix={handeAbTestRemixFor2ndCard}
          IndexChanger={IndexChanger}
          advanceObj={advanceObj}
          changeHeadline={IndexChanger.changeHeadlineB}
          template={routeLocation?.state?.type}
          copyTypeSecondDimension={copyTypeSecondDimension}
          copyTypeFirstDimension={copyTypeFirstDimension}
          abTypeA={abTypeA}
          abTypeB={abTypeB}
          handleAbTestRemix={handleAbTestRemix}
          abTestSelected={abTestSelected}
          setAbTestSelect={setAbTestSelect}
          google={
            state?.openGraph?.openGraph?.image?.url
              ? state?.openGraph?.openGraph?.image?.url
              : url
          }
          changeFirstIndexOfFirstDimensionOFAdCopy={
            changeFirstIndexOfFirstDimensionOFAdCopy
          }
          handleNextDimesion={handleNextDimesion}
          handleSecondDimension={handleSecondDimension}
          funnelStage={funnelStage}
          setFunnelStage={setFunnelStage}
        />
      ) : (
        <div>
          <Row
            style={{
              // height: "auto",
              backgroundColor: "#f0f2f5",
              background: "#f0f2f5",
            }}
          >
            <Col className="border-r-2" lg={12} xl={12} md={12} sm={24} xs={24}>
              <div
                style={{
                  backgroundColor: "#f0f2f5",
                  background: "#f0f2f5",
                }}
                className="overflow-scroll height_scroll pr-2"
              >
                <div>
                  <div>
                    <div className="flex mt-2 justify-between ">
                      <h4>What is your Landing Page URL?</h4>
                    </div>
                    <h5 className="description">
                      Copy paste the URL you want to get Ad copy for info the
                      field below
                    </h5>
                  </div>
                  <div>
                    <TextInput
                      defaultValue="https://"
                      disabled={scrapContents}
                      onChange={(e) => setUrl(e.target.value)}
                      // value={url}
                      // placeholder="URL"
                    />
                    <div className="mt-2 flex justify-between ">
                      <Check
                        onClick={() => setInputDisable((e) => !e)}
                        value={
                          <Tooltip
                            placement="bottom"
                            title="Don't have landing page? Don't worry!! you can write your own Description."
                          >
                            {" I don't have a landing page available. 😏"}
                          </Tooltip>
                        }
                        disabled={scrapContents}
                      />
                      <div>
                        <Btn
                          value={scrapContents ? "Edit URL" : "Scrape"}
                          bgColor="#1e2a3b"
                          load={load}
                          color="white"
                          onClick={handleScrape}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className="flex mt-8 justify-between">
                        <h4>Whats the name of what you are promoting?</h4>
                        <div className="flex">
                          <h4 className="flex-grow-0">
                            ({state.productName?.length || 0}/100)
                          </h4>
                          <div>
                            <Tooltip
                              placement="bottom"
                              title="Tip: It’s okay to use either your
                            Brand name or the specific
                            Product/Service name of what you’re
                            promoting - but not having any name
                            decreases performance!"
                            >
                              <img src={tooltip} width="21px" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <h5 className="description">
                        Our A.I automatically identified a clear name for your
                        offer:
                      </h5>
                    </div>
                    <div>
                      <TextInput
                        disabled={
                          scrapContents ? false : !inputDisable ? false : true
                        }
                        placeholder="Title"
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            productName: e.target.value,
                          }))
                        }
                        maxLength={100}
                        value={state?.productName}
                      />
                    </div>
                    <div className="float-right text-xs">
                      (Optional field - but recommended to get better Ad Copy)
                    </div>
                  </div>

                  <div>
                    <div className="flex mt-8 justify-between">
                      <h4>
                        {" "}
                        What is the keyword that exactly describes your audience
                        OR <br />
                        your product/service?
                      </h4>
                      <div className="flex">
                        <h4>({state.audience?.length || 0}/100)</h4>
                        <div>
                          <Tooltip
                            placement="bottom"
                            title="ip: The more customized your Keyword
                          is to what you’re promoting, the
                          better Ad Copy generated will be."
                          >
                            <img src={tooltip} width="21px" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <h5 className="description">
                      Our A.I. automatically picked out a keyword for your
                      offer:
                    </h5>
                    <div>
                      <TextInput
                        disabled={
                          scrapContents ? false : !inputDisable ? false : true
                        }
                        placeholder="Keywords"
                        onChange={(e) => {
                          setState((s) => ({ ...s, audience: e.target.value }));
                          setCount1(e.target.value.length);
                        }}
                        maxLength={100}
                        value={state?.audience}
                      />
                      <div className="float-right  text-xs">
                        (Optional field - but recommended to get better Ad Copy)
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex mt-8 justify-between">
                      <div>
                        <h4>
                          What is the most accurate description of what you are
                          promoting?
                        </h4>
                      </div>
                      <div className="flex">
                        <h4>({state?.descCopy?.length || 0}/320)</h4>
                        <div>
                          <Tooltip
                            placement="bottom"
                            title={
                              <p>
                                To generate the best possible Ad Copy apply this
                                in your Description:
                                <ol>
                                  <li>
                                    Be boringly specific on what matters the
                                    most in your offer - the A.I. will
                                    understand that from you and will focus your
                                    Ad Copy on that.
                                  </li>
                                  <li>
                                    Don’t use “fluffy” words - instead write a
                                    clear description with specific keywords
                                    that fit your offer
                                  </li>
                                  <li>
                                    Use all the Description space available
                                  </li>
                                </ol>
                              </p>
                            }
                          >
                            <img src={tooltip} width="21px" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <h5 className="description">
                      Our A.I. automatically picked out a keyword for your
                      offer:
                    </h5>
                    <div>
                      <TextArea
                        disabled={
                          scrapContents ? false : !inputDisable ? false : true
                        }
                        rows={[4]}
                        placeholder="Description"
                        onChange={(e) => {
                          setState((r) => ({
                            ...r,
                            descCopy: e.target.value,
                            desc: e.target.value,
                          }));
                          setCount2(e.target.value.length);
                        }}
                        maxLength={320}
                        value={state.descCopy}
                      />
                      <div className="float-right  text-xs">
                        (Optional field - but recommended to get better Ad Copy)
                      </div>
                    </div>
                  </div>
                  {moreAdOptimization && (
                    <AdOptimization
                      scrapContents={scrapContents}
                      advanceSetting={advanceSetting}
                      setAdvanceSettings={setAdvanceSettings}
                    />
                  )}
                </div>
                {/* <Row> */}
                {/* <Col xl={24} lg={24}> */}

                {/* </Col>
                </Row> */}
              </div>
            </Col>
            <Col
              style={{ minHeight: "35rem" }}
              lg={12}
              xl={12}
              md={12}
              sm={24}
              xs={24}
            >
              <div className="overflow-scroll height_scroll pr-2">
                <div className="menuDiv flex">
                  <Menu className="w-full links-setting">
                    <div className="flex justify-between ">
                      <div className="flex flex-wrap justify-between flex-1">
                        {tabs.map((v, i) => (
                          <Menu.Item
                            // disabled={i === 0 ? outputDisable : false}
                            onClick={() => {
                              setActive(i);
                              i === 1 ? setCopyTypeDisable(false) : null;
                              cardData.length <= 0
                                ? null
                                : i === 0
                                ? setCopyTypeDisable(true)
                                : null;
                            }}
                            className={`setting-links ${
                              active === i ? "active" : ""
                            }`}
                            key={i}
                          >
                            {v}
                          </Menu.Item>
                        ))}
                        {(post.abTest?.type === "fb" ||
                          post.abTest?.type === "ld") && (
                          <Menu.Item
                            disabled={cardData.length <= 0 ? true : false}
                            className="setting-links"
                          >
                            <div className="flex">
                              <img
                                src={abTestingIcon}
                                style={{ color: "#717171" }}
                                width="15%"
                              />
                              <div
                                className="ml-1"
                                onClick={() =>
                                  cardData.length <= 0
                                    ? null
                                    : (post.abTest?.type === "fb" ||
                                        post.abTest?.type === "ld") &&
                                      setAbTesting((state) => !state)
                                }
                              >
                                A/b Testing
                              </div>
                            </div>
                          </Menu.Item>
                        )}
                        {(post.abTest?.type === "fb" ||
                          post.abTest?.type === "ld") && (
                          <Menu.Item
                            disabled={!copyTypeDisable ? false : true}
                            className="setting-links"
                          >
                            <Popover
                              placement="bottom"
                              title={text}
                              content={content}
                              trigger={!copyTypeDisable ? "click" : ""}
                              onVisibleChange={(e) =>
                                setIsPopOverActiveAdType(e)
                              }
                            >
                              <div
                                className="ant-dropdown-link flex"
                                onClick={(e) => e.preventDefault()}
                              >
                                {!isPopoverActiveAdType ? (
                                  <img
                                    src={copyType}
                                    style={{ color: "#717171" }}
                                    width="10%"
                                  />
                                ) : (
                                  <img
                                    style={{ color: "red" }}
                                    src={copyTypeRed}
                                    width="10%"
                                  />
                                )}
                                <div className="ml-1">copy type</div>
                              </div>
                            </Popover>
                          </Menu.Item>
                        )}
                      </div>
                    </div>
                  </Menu>
                  {/* {location.state.heading === "Facebook Ad Copy" && */}
                </div>
                {active === 0 &&
                  !!currentStaticTypes?.length &&
                  currentStaticTypes.map((e, i) => {
                    return (
                      <Content
                        key={i}
                        loadShowCase={loadShowCase}
                        handleShowCase={handleShowCase}
                        advanceObj={advanceObj}
                        showCase={false}
                        state={state}
                        gpt3Loading={gpt3Loading}
                        headline={headline}
                        onNext={onNext}
                        index={copyIndex}
                        setHeadlineIndex={setHeadlineIndex}
                        google={
                          state?.openGraph?.openGraph?.image?.url
                            ? state?.openGraph?.openGraph?.image?.url
                            : google
                        }
                        cardData={cardData}
                        onPrevious={onPrevious}
                        IndexChanger={IndexChanger}
                        data={e}
                      />
                    );
                  })}

                <>
                  {!!currentStaticTypes?.length &&
                    active === 0 &&
                    currIndex.end < state.allGeneratedData.length && (
                      <>
                        <ScrollIndicator />

                        <div className="loader" id="loader">
                          <Btn
                            bgColor="#0e1526"
                            load={load}
                            color="white"
                            value={"Load More"}
                            onClick={loadFunc}
                            className="flex justify-center items-center w-auto"
                          />
                        </div>
                      </>
                    )}
                </>
                {active === 1 && (
                  <div className="w-full">
                    <History reGenerate={reGenerate} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col
              className="border-r-2"
              style={{ minHeight: "6rem" }}
              lg={12}
              xl={12}
              md={12}
              sm={24}
              xs={24}
            >
              <div className="flex justify-between my-3 absolute bottom-0 left-0 right-0">
                <div>
                  <Btn
                    disabled={scrapContents ? false : true}
                    bgColor="#0E1526"
                    color="white"
                    value={
                      scrapContents
                        ? moreAdOptimization
                          ? "Go Back to Default back option"
                          : "Optimize your Ad copy further (For Advance users)"
                        : "Optimize your Ad copy further (For Advance users)"
                    }
                    onClick={
                      scrapContents
                        ? () => setMoreAdOptimization((state) => !state)
                        : ""
                    }
                  />
                </div>
                <div className="mx-7">
                  <Btn
                    onClick={() => {
                      generateAdCopy();
                      if (
                        routeLocation?.state?.type === "fb" ||
                        routeLocation?.state?.type === "ld"
                      ) {
                        setCopyTypeDisable(false);
                      }
                      // setOutputDisable(false);
                      setActive(0);
                    }}
                    disabled={!scrapContents}
                    value={`Generate ${
                      user?.planType === "growth" ? "(10 coins)" : ""
                    }`}
                    bgColor="#0E1526"
                    color="white"
                    load={gpt3Loading}
                  />
                </div>
              </div>
            </Col>
            {/* orignal active 0 to show */}
            {active === [{}] && (
              <Col
                style={{ minHeight: "6rem" }}
                lg={12}
                xl={12}
                md={12}
                sm={24}
                xs={24}
              >
                <div
                  className="flex justify-between absolute mx-7 my-3 bottom-0 left-0 right-0"
                  style={{ marginBottom: -15 }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    onClick={() => {
                      if (itemOffset >= 73) {
                        return 0;
                      }
                    }}
                    pageLinkClassName="pageClass"
                    containerClassName="flex justify-between container-paginate"
                    nextLabel={
                      <button
                        onClick={() => {
                          if (type === "google") {
                            handleGoogleNext();
                            return;
                          }
                          onNext();
                        }}
                      >
                        <img
                          src={NEXT_ICON}
                          alt="next-icon"
                          style={{ width: 80 }}
                        />
                      </button>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={
                      <button
                        onClick={() => {
                          if (type === "google") {
                            handlePrevGoogle();
                            return;
                          }
                          onPrevious();
                        }}
                      >
                        <img
                          src={PREV_ICON}
                          alt="prev-icon"
                          style={{ width: 80 }}
                        />
                      </button>
                    }
                    renderOnZeroPageCount={null}
                    maxIndex={maxIndex}
                    handleGoogleNext={handleGoogleNext}
                    handlePrevGoogle={handlePrevGoogle}
                    type={type}
                    activeClassName="active_link"
                  />
                  {/* <div>
                    <Btn
                      onClick={
                        type !== "google" ? onPrevious : handlePrevGoogle
                      }
                      disabled={
                        type !== "google" ? (index > 0 ? false : true) : ""
                      }
                      value="previous"
                      bgColor="#0E1526"
                      color="white"
                      load={false}
                    />
                  </div>
                  <div>
                    <Btn
                      onClick={type !== "google" ? onNext : handleGoogleNext}
                      disabled={
                        type !== "google"
                          ? index >= maxIndex
                            ? true
                            : false
                          : ""
                      }
                      value="Next"
                      bgColor="#0E1526"
                      color="white"
                      load={false}
                    />
                  </div> */}
                </div>
              </Col>
            )}
          </Row>
          {/* <Row>
            <Col lg={12} xl={12} md={24} sm={16} xs={24}>
              <div className="flex footer  pt-2 pb-2 pr-2 container mx-auto justify-between bottom-0">
                <div>
                  <Btn
                    disabled={scrapContents ? false : true}
                    bgColor="#0E1526"
                    color="white"
                    value={
                      scrapContents
                        ? moreAdOptimization
                          ? "Go Back to Default back option"
                          : "Optimize your Ad copy further (For Advance users)"
                        : "Optimize your Ad copy further (For Advance users)"
                    }
                    onClick={
                      scrapContents
                        ? () => setMoreAdOptimization((state) => !state)
                        : ""
                    }
                  />
                </div>
                <div>
                  <Btn
                    onClick={generateAdCopy}
                    disabled={scrapContents ? false : true}
                    value="Generate"
                    bgColor="#0E1526"
                    color="white"
                    load={gpt3Loading}
                  />
                </div>
              </div>
            </Col>
          </Row> */}

          {/* <div className="flex footer  pt-2 pb-2 pr-2 flex-col lg:flex-row xl:flex-row container justify-between bottom-1"> */}
        </div>
      )}
    </div>
  );
};
