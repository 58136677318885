import React, { useState } from "react";
import "./scss/index.scss";
import { TextInput, TextArea, Btn } from "../../components";
import { contactSchema } from "../../Validations/ContactValidations";
import { authService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { modalAction } from "../../store/actions";

export const Help = () => {
  const dispatch = useDispatch();
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const [load, setLoad] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: user?.email,
    subject: "",
    message: "",
  });
  const handleChange = (event) => {
    setForm((e) => ({ ...e, [event.target.id]: event.target.value }));
  };
  const handleSubmit = async () => {
    try {
      await contactSchema.validate(form);
      setLoad(true);
      const res = await authService.sendEmailForContact(form);
      console.log(res);
      if (res.data?.success) {
        dispatch(
          modalAction.success({
            title: "Thank you so much for contacting us",
            content:
              "we received your queries and we will get back to you as soon as possible. Thank you Chamber teams",
          })
        );
      } else {
        alert("sometgin went wrong!");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
    setLoad(false);
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen xs:overflow-hidden">
      <div className="card lg:p-10 xs:p-5 rounded-lg">
        <div className="p-2">
          <div className="m-auto max-w-max flex justify-center text-center">
            <div>
              <h1>Contact Us</h1>
              <p>
                We have driven online revenues of over $2 billion for our
                clients. Ready to know how we can help you?
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-5">
                <TextInput
                  id="name"
                  onChange={(e) => handleChange(e)}
                  value={form.name}
                  placeholder="Name"
                  size={"large"}
                />
              </div>
              <div className="m-5">
                <TextInput
                  id="email"
                  disabled={true}
                  value={form.email}
                  placeholder="Email"
                  size={"large"}
                />
              </div>
              <div className="m-5">
                <TextInput
                  id="subject"
                  onChange={(e) => handleChange(e)}
                  value={form.subject}
                  placeholder="Subject"
                  size={"large"}
                />
              </div>
              <div className="m-5">
                <TextArea
                  id="message"
                  onChange={(e) => handleChange(e)}
                  value={form.message}
                  placeholder="Message"
                  rows={4}
                  size={"large"}
                />
              </div>
              <div className="m-5 min-w-max">
                <Btn
                  value="Submit"
                  bgColor="#1e2a3b"
                  color="white"
                  onClick={handleSubmit}
                  load={load}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
