// // Apply Refinements  Action
// export const applyRefinements = (refinements) => {
//   return (dispatch, getState) => {
//     // Make Async call to database
//     // Then dispatch an action
//     dispatch({ type: "APPLY_REFINEMENTS", refinements });
//   };
// };
const applyRefinements = (refinements) => {
  return {
    type: "APPLY_REFINEMENTS",
    payload: refinements,
  };
};

const logOut = () => {
  return {
    type: "LOG_OUT",
  };
};

const allFilterActions = {
  applyRefinements,
  logOut,
};

export default allFilterActions;
