import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import "./scss/index.scss";

import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { authService } from "../../../services";

export const PaymentSetting = () => {
  // const { TabPane } = Tabs;
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const [data, setCusData] = useState([]);

  const getCusomer = useCallback(async () => {
    const data = {
      id: user?.customerId,
    };
    const res = await authService.retrievePayments(data);
    if (res?.data?.payments?.data?.length) {
      let data = res?.data?.payments?.data;
      let temp = [];
      data.forEach((e) => {
        var date = new Date(e.charges.data[0].created * 1000);
        var formattedTime = date.toLocaleDateString();
        console.log(e);
        temp.push({
          ...e,
          amount: `$${e.amount / 100}`,
          planType: user?.planType,
          email: e.charges.data[0].billing_details.email,
          date: formattedTime,
        });
      });
      setCusData(temp);
    }
  }, [user?.customerId, user?.planType]);

  useEffect(() => {
    getCusomer();
  }, [getCusomer]);

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
    },
    {
      title: "Email",

      dataIndex: "email",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];

  // const data = [
  //   {
  //     name: "John Brown",
  //     status: "pending",
  //     address: "New York No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Jim Green",
  //     status: "pending",
  //     address: "London No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Joe Black",
  //     status: "pending",
  //     address: "Sidney No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "John Brown",
  //     status: "pending",
  //     address: "New York No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Jim Green",
  //     status: "pending",
  //     address: "London No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Joe Black",
  //     status: "pending",
  //     address: "Sidney No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "John Brown",
  //     status: "pending",
  //     address: "New York No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Jim Green",
  //     status: "pending",
  //     address: "London No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Joe Black",
  //     status: "pending",
  //     address: "Sidney No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "John Brown",
  //     status: "pending",
  //     address: "New York No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Jim Green",
  //     status: "pending",
  //     address: "London No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  //   {
  //     name: "Joe Black",
  //     status: "pending",
  //     address: "Sidney No. 1 Lake Park",
  //     email: "omer@gmail.com",
  //     date: "22-8-2021",
  //   },
  // ];
  console.log("data => ", data);
  return (
    <div className="container">
      <div className="py-2">
        <div className="menu-div">
          <div className="mx-10 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <Table
              columns={columns}
              locale={{
                emptyText: (
                  <ReactLoading
                    className="mx-auto my-10"
                    type="bubbles"
                    color="black"
                    height={14}
                    width={50}
                    delay={1}
                  />
                ),
              }}
              dataSource={(data?.length && data) || data}
              pagination={data?.length <= 8 ? false : { pageSize: 8 }}
            />
          </div>
          {/* <TabPane tab="Pending" key="2">
              <div className="mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <Table
                  columns={columns}
                  dataSource={(data?.length && data) || data}
                  pagination={data?.length <= 8 ? false : { pageSize: 8 }}
                />
              </div>
            </TabPane>
            <TabPane tab="Active" key="3">
              <div className="mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <Table
                  columns={columns}
                  dataSource={(data?.length && data) || data}
                  pagination={data?.length <= 8 ? false : { pageSize: 8 }}
                />
              </div>
            </TabPane> */}
        </div>
      </div>
    </div>
  );
};
