import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Btn } from "../../components";
import { authService } from "../../services";
import { useNavigate } from "react-router-dom";

export const Payment = () => {
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    handleValidate();
    // eslint-disable-next-line
  }, []);

  const handleValidate = async () => {
    setLoad(true);
    const url = new URL(window.location.href).searchParams;
    const session_id = url.get("session_id");
    console.log(session_id, localStorage.getItem("token"));
    const res = await authService.validateSession(
      session_id,
      localStorage.getItem("token")
    );

    if (!res?.data?.status) {
      setStatus("Error");
      setLoad(false);
      return;
    }
    const user = await authService.getUser(localStorage.getItem("uid"));
    localStorage.setItem("auth", JSON.stringify(user?.data));
    setStatus(res?.data?.status);
    setLoad(false);
    setTimeout(() => {
      navigate("/unicorncopy/userpanel");
    }, 0);
  };
  return (
    <div>
      <Row>
        <Col lg={12} sm={12} xs={24}>
          <Btn load={load} value={status} color="black" />
        </Col>
        <Col lg={12} sm={12} xs={24}></Col>
      </Row>
    </div>
  );
};
