import React from "react";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { Checkbox } from "antd";

export const Check = (props) => {
  function onChange(e) {
    e.target.checked;
  }
  return (
    <div>
      <Checkbox
        disabled={props.disabled}
        onClick={props.onClick}
        onChange={onChange}
      >
        {props.value}
      </Checkbox>
    </div>
  );
};

Check.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.any,
  disabled: PropTypes.any,
};
