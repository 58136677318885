import { API, Headers } from "../config/api";

const scrapDataWithog = (data) =>
  API.post("scrapDataWithog", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const scrapFromKlazify = (data) =>
  API.post("scrapFromKlazify", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const scrapbrainVantage = (data) =>
  API.post("scrapbrainVantage", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const scrapWithSerp = (data) =>
  API.post("scrapWithSerp", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const clean = (data) =>
  API.post("clean", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const getPost = (data) =>
  API.get(
    `https://projectb2.herokuapp.com/get?skip=${data.skip}&limit=${data.limit}`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjEwMjk3ZWEzNWRlMjUwMDA0MGRjNzcwIn0sImlhdCI6MTYyNzU4Mzg2Nn0.T-2v5FFEQArjpYx3MjrbEqpeclzhNOFDXwL_rTeRMJc",
      },
    }
  ).then(
    (res) => res,
    (err) => err
  );
const search = (data) =>
  API.post(
    `https://projectb2.herokuapp.com/filterSearch?skip=${data.skip}&limit=${data.limit}`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjEwMjk3ZWEzNWRlMjUwMDA0MGRjNzcwIn0sImlhdCI6MTYyNzU4Mzg2Nn0.T-2v5FFEQArjpYx3MjrbEqpeclzhNOFDXwL_rTeRMJc",
      },
    }
  ).then(
    (res) => res,
    (err) => err
  );
const filterAnalytics = (data) =>
  // API.post(`http://localhost:4000/filterAnalytics`, data, {
  API.post(`https://projectb2.herokuapp.com/filterAnalytics`, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjEwMjk3ZWEzNWRlMjUwMDA0MGRjNzcwIn0sImlhdCI6MTYyNzU4Mzg2Nn0.T-2v5FFEQArjpYx3MjrbEqpeclzhNOFDXwL_rTeRMJc",
    },
  }).then(
    (res) => res,
    (err) => err
  );
const checkUrl = (data) =>
  API.post(`checkUrl`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getKlazify = (data) =>
  API.post(`getKlazify`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const validateKlazify = (data) =>
  API.post(`validateKlazify`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const scrapBoilerPipe = (data) =>
  API.post(`scrapBoilerPipe`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const scrapScrapeStack = (data) =>
  API.post(`scrapScrapeStack`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const summarizeScrapBot = (data) =>
  API.post(`summarizeScrapBot`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const scrapDiffBot = (data) =>
  API.post(`scrapDiffBot`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const addToFavTemplate = (data) =>
  API.post(`addToFavTemplate`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const removeFromFavTemplate = (data) =>
  API.post(`removeFromFavTemplate`, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

export const postService = {
  scrapDataWithog,
  scrapFromKlazify,
  scrapbrainVantage,
  getPost,
  search,
  filterAnalytics,
  checkUrl,
  scrapWithSerp,
  clean,
  getKlazify,
  validateKlazify,
  scrapBoilerPipe,
  scrapScrapeStack,
  scrapDiffBot,
  summarizeScrapBot,
  addToFavTemplate,
  removeFromFavTemplate,
};
