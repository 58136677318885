import React from "react";
import PropTypes from "prop-types";

export default function Views({ view, misc }) {
  return (
    <div className={` mt-0 ${misc && "ml-5"}`}>
      <p>Views {view}</p>
    </div>
  );
}
Views.propTypes = {
  view: PropTypes.number,
  misc: PropTypes.bool,
};
