import { sideBarConstants } from "../constants/sidebar";

const initialState = {
  currentKey: "",
};

const sideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case sideBarConstants.UPDATE_CURRENT_KEY:
      return {
        ...state,
        currentKey: action.payload,
      };
    default:
      return state;
  }
};

export default sideBarReducer;
