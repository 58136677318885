import React, { useEffect, useRef, useState } from "react";
import "./scss/index.scss";
import { Select, Tag, Input } from "antd";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import allActions from "../../store/actions/filterActions";
// import { BrainCard2 } from "../../components/brain-card-2";

export const Filter = () => {
  const [personName, setPersonName] = useState([]);
  const [adType, setAdType] = useState([]);
  const [editType, setEditType] = useState([]);
  const [Classification, setClassification] = useState([]);
  const [adScore, setAdScore] = useState([]);
  const [, setAdScoreMin] = useState("");
  const [, setAdScoreMax] = useState("");
  const [pricing, setPricing] = useState([]);
  const [pricingMin, setPricingMin] = useState("");
  const [pricingMax, setPricingMax] = useState("");
  const [verifiedFb, setVerifiedFb] = useState();
  const [verifiedIn, setVerifiedIn] = useState();
  const [vidLength, setVidLength] = useState([]);
  const [vidLengthMin, setVidLengthMin] = useState("");
  const [vidLengthMax, setVidLengthMax] = useState("");
  const [likes, setLikes] = useState([]);
  const [comments, setComments] = useState([]);
  const [shares, setShares] = useState([]);
  const [refinements, setRefinements] = useState({
    personName: [],
    Classification: [],
    adScore: [],
    verifiedFb: "",
    verifiedIn: "",
    vidLength: [],
    likes: [],
    comments: [],
    shares: [],
    pricing: [],
  });

  // // REDUX
  const filterDispatch = useDispatch();

  // Use Effect - update refinements each time filter changes with none slider values
  useEffect(() => {
    // console.log("applyed ====", refinements)
    filterDispatch(allActions.applyRefinements(refinements));
  }, [
    personName,
    Classification,
    verifiedFb,
    verifiedIn,
    adType,
    editType,
    pricing,
    adScore,
    filterDispatch,
    refinements,
  ]);

  // handling all the refs of the sliders
  const videRef = useRef(null);
  const scoreRef = useRef(null);
  const likesRef = useRef(null);
  const commentRef = useRef(null);
  const sharesRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // console.log(scoreRef.current)
      if (scoreRef.current && !scoreRef.current.contains(event.target)) {
        document.getElementById("bloc-slider").style = "display: none";
      }
      if (videRef.current && !videRef.current.contains(event.target)) {
        document.getElementById("vide-block").style = "display: none";
      }
      if (likesRef.current && !likesRef.current.contains(event.target)) {
        document.getElementById("likes-block").style = "display: none";
      }
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        document.getElementById("comments-block").style = "display: none";
      }
      if (sharesRef.current && !sharesRef.current.contains(event.target)) {
        document.getElementById("shares-block").style = "display: none";
      }
      if (pricingRef.current && !pricingRef.current.contains(event.target)) {
        document.getElementById("pricing-slider").style = "display: none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scoreRef, videRef]);

  const handleChange = (event) => {
    console.log(event);
    setPersonName(event);
    setRefinements({ ...refinements, personName: event });
  };

  const handleAdType = (event) => {
    setAdType(event);
    setRefinements({ ...refinements, adType: event });
  };

  const handleEditType = (event) => {
    setEditType(event);
    setRefinements({ ...refinements, editType: event });
  };
  const handlePricing = (e, n) => {
    // console.log("pricings")

    let val = [];
    if (e === "min") {
      val[0] = n;
      val[1] = pricingMax;
      setPricingMin(n);
    } else {
      val[0] = pricingMin;
      val[1] = n;
      setPricingMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }
    setPricing(val);
    // console.log(refinements)
    setRefinements({ ...refinements, pricing: val });
    // return;
    filterDispatch(
      allActions.applyRefinements({
        ...refinements,
        pricing: val,
      })
    );
  };
  const handleVid = (e, n) => {
    let val = [];
    if (e === "min") {
      val[0] = n;
      val[1] = vidLengthMax;
      setVidLengthMin(n);
    } else {
      val[0] = vidLengthMin;
      val[1] = n;
      setVidLengthMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }

    setVidLength(val);
    setRefinements({ ...refinements, vidLength: val });
    filterDispatch(
      allActions.applyRefinements({
        ...refinements,
        vidLength: val,
      })
    );
  };
  // Handle Delete functions
  const handleCategoryDelete = (CATEGORY) => {
    let filteredValue = personName.filter((item) => item !== CATEGORY);
    setPersonName(filteredValue);
    setRefinements({ ...refinements, personName: filteredValue });
  };

  const handleAdTypeDelete = (ad) => {
    let filteredValue = adType.filter((item) => item !== ad);
    setAdType(filteredValue);
    setRefinements({ ...refinements, adType: filteredValue });
  };

  const handleEditTypeDelete = (ed) => {
    let filteredValue = editType.filter((item) => item !== ed);
    setEditType(filteredValue);
    setRefinements({ ...refinements, editType: filteredValue });
  };

  const handleClassificationDelete = (CLASSIFICATION) => {
    let filteredValue = Classification.filter(
      (item) => item !== CLASSIFICATION
    );
    setClassification(filteredValue);
    setRefinements({ ...refinements, Classification: filteredValue });
  };

  const handleVerifiedFbDelete = () => {
    setVerifiedFb("");
    setRefinements({ ...refinements, verifiedFb: "" });
  };

  const handleVerifiedInDelete = () => {
    setVerifiedIn("");
    setRefinements({ ...refinements, verifiedIn: "" });
  };

  const handleVideoLenDelete = () => {
    setVidLength([]);
    setRefinements({ ...refinements, vidLength: [] });
  };
  const handleDeletePricing = () => {
    setPricing([]);
    setPricingMax("");
    setPricingMin("");
    setRefinements({ ...refinements, pricing: [] });
  };

  const handleAdScoreDelete = () => {
    setAdScore("");
    setRefinements({ ...refinements, adScore: "" });
    setAdScoreMax("");
    setAdScoreMin("");
    filterDispatch(
      allActions.applyRefinements({
        personName: refinements.personName,
        Classification: refinements.Classification,
        adScore: [],
        verifiedFb: refinements.verifiedFb,
        verifiedIn: refinements.verifiedIn,
        vidLength: refinements.vidLength,
        likes: refinements.likes,
        comments: refinements.comments,
        shares: refinements.shares,
      })
    );
  };

  const handleCommentsDelete = () => {
    setComments([]);
    setRefinements({ ...refinements, comments: [] });
  };

  const handleSharesDelete = () => {
    setShares([]);
    setRefinements({ ...refinements, shares: [] });
  };

  const handleLikesDelete = () => {
    setLikes([]);
    setRefinements({ ...refinements, likes: [] });
  };
  const handleClear = () => {
    filterDispatch(allActions.applyRefinements({}));
    setRefinements({
      personName: [],
      Classification: [],
      adScore: [],
      verifiedFb: "",
      verifiedIn: "",
      vidLength: [],
      likes: [],
      comments: [],
      shares: [],
      pricing: [],
    });
    setLikes([]);
    setPersonName("");
    setEditType();
    setAdType();
    setPricingMin();
    setPricingMax();
    setPricing();
    setVidLengthMin();
    setVidLengthMax();
    setVidLength();
    setAdScore("");
    setAdScoreMax("");
    setAdScoreMin("");
    setShares([]);
    setLikes([]);
    setComments([]);
  };
  return (
    <div>
      <div>
        <div
          onClick={handleClear}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>Clear all</p>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "0%",
            marginRight: "10%",
            padding: "16px",
            marginTop: "32px",
            maxWidth: "1600px",
          }}
        >
          {adType?.map((ad) => {
            return (
              <Tag
                closable
                key={Math.random()}
                onClose={() => handleAdTypeDelete(ad, setAdType)}
              >
                {ad}
              </Tag>
            );
          })}
          {editType?.map((ed) => {
            return (
              <Tag
                closable
                key={Math.random()}
                label={ed}
                onClose={() => handleEditTypeDelete(ed, setEditType)}
              >
                {ed}
              </Tag>
            );
          })}
          {!!personName.length &&
            personName?.map((category) => {
              return (
                <Tag
                  closable
                  key={Math.random()}
                  label={category}
                  onClose={() => handleCategoryDelete(category, setPersonName)}
                >
                  {category}
                </Tag>
              );
            })}
          {Classification.map((e) => (
            <Tag
              closable
              key={Math.random()}
              onClose={() => handleClassificationDelete(e)}
            >
              {e}
            </Tag>
          ))}

          {verifiedFb && (
            <Tag
              closable
              key={Math.random()}
              label={"Facebook verified " + verifiedFb}
              onClose={() => handleVerifiedFbDelete()}
            >
              {"Facebook verified " + verifiedFb}
            </Tag>
          )}

          {verifiedIn && (
            <Tag
              closable
              key={Math.random()}
              label={"Instagram verified " + verifiedIn}
              onClose={() => handleVerifiedInDelete()}
            >
              {"Instagram verified " + verifiedIn}
            </Tag>
          )}
          {!!vidLength && !!vidLength.length && (
            <Tag
              closable
              key={Math.random()}
              onClose={() => handleVideoLenDelete()}
            >
              {"Video length " + vidLength[0] + "-" + vidLength[1]}
            </Tag>
          )}
          {!!pricing && !!pricing.length && (
            <Tag
              closable
              key={Math.random()}
              label={"Pricing $" + pricing[0] + "-$" + pricing[1]}
              onClose={() => handleDeletePricing()}
            >
              {"Pricing $" + pricing[0] + "-$" + pricing[1]}
            </Tag>
          )}
          {comments && !!comments.length && (
            <Tag
              closable
              key={Math.random()}
              onClose={() => handleCommentsDelete()}
            >
              {"Comments " + comments[0] + "-" + comments[1]}
            </Tag>
          )}
          {shares && !!shares.length && (
            <Tag
              closable
              key={Math.random()}
              label={"Shares " + shares[0] + "-" + shares[1]}
              onClose={() => handleSharesDelete()}
            >
              {"Shares " + shares[0] + "-" + shares[1]}
            </Tag>
          )}
          {likes && !!likes.length && (
            <Tag
              closable
              key={Math.random()}
              label={"Likes " + likes[0] + "-" + likes[1]}
              onClose={() => handleLikesDelete()}
            />
          )}
          {adScore && !!adScore.length && (
            <Tag
              closable
              key={Math.random()}
              label={"Ad Score " + adScore[0] + "-" + adScore[1]}
              onClose={() => handleAdScoreDelete()}
            >
              {"Ad Score " + adScore[0] + "-" + adScore[1]}
            </Tag>
          )}
        </div>
      </div>
      <div className="flex container-filter">
        <div className="single-section">
          <p id="demo-mutiple-chip-label">Categories 🌎</p>
          <Select
            mode="multiple"
            className="select-box"
            showArrow
            id="demo-mutiple-chip9"
            value={personName}
            onChange={handleChange}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
              };
              return (
                <Tag
                  val={value}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{ marginRight: 3 }}
                >
                  <span className="black-lable">{label}</span>
                </Tag>
              );
            }}
            options={ctegories}
          />
        </div>

        <div className="single-section">
          <p id="demo-mutiple-chip-label"> Pricing 💰 (Dollars)</p>
          <div className="flex">
            <Input
              className="input-box-half "
              value={pricingMin}
              onChange={(e) => handlePricing("min", e.target.value)}
              type="number"
              min="0"
              max="9999"
              placeholder="Min"
              inputProps={{ "aria-label": "min" }}
            />
            <Input
              className="input-box-half"
              value={pricingMax}
              onChange={(e) => handlePricing("max", e.target.value)}
              type="number"
              max="10000"
              placeholder="Max"
              inputProps={{ "aria-label": "max" }}
            />
          </div>
        </div>

        <div className="single-section">
          <p id="demo-mutiple-chip-label">Ad type 🖼 </p>
          <Select
            className="select-box"
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip9"
            mode="multiple"
            showArrow
            value={adType}
            onChange={handleAdType}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
              };
              return (
                <Tag
                  val={value}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{ marginRight: 3 }}
                >
                  <span>{label}</span>
                </Tag>
              );
            }}
            options={adTypes}
          />
        </div>

        <div className="single-section">
          <p id="demo-mutiple-chip-label"> Types of Editing 📷 </p>
          <Select
            className="select-box"
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip9"
            mode="multiple"
            value={editType}
            onChange={handleEditType}
            input={<Input id="select-multiple-chip1" />}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
              };
              return (
                <Tag
                  val={value}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{ marginRight: 3 }}
                >
                  <span>{label}</span>
                </Tag>
              );
            }}
            options={editTypes}
          />
        </div>

        <div className="single-section">
          <div>
            <p id="demo-mutiple-chip-label">Video Length ⏱ (Seconds)</p>
            <div className="flex">
              <Input
                value={vidLengthMin}
                className="input-box-half "
                onChange={(e) => handleVid("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={vidLengthMax}
                onChange={(e) => handleVid("max", e.target.value)}
                type="number"
                className="input-box-half "
                max="10000"
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </div>
        </div>
        <div id="stats" className="filter-stats-info single-section"></div>
      </div>
    </div>
  );
};

Filter.propTypes = {
  setRenderBrain: PropTypes.any,
};

const ctegories = [
  { value: "Personal Items" },
  { value: "Beauty" },
  { value: "Underwear" },
  { value: "Homeware" },
  { value: "Clothing" },
  { value: "Food & Drinks" },
  { value: "Accessories" },
  { value: "Unique Stores" },
  { value: "Electronics" },
  { value: "Footwear" },
  { value: "Jewelry" },
  { value: "Fitness" },
  { value: "Children" },
  { value: "Pets" },
  { value: "Female Clothing" },
  { value: "Car & Vehicles" },
  { value: "Sports" },
  { value: "Outdoors" },
  { value: "Male Clothing" },
  { value: "Swimwear" },
  { value: "Health & Care" },
];

const editTypes = [{ value: "Raw Cuts" }, { value: "Highly Edited" }];
const adTypes = [
  { value: "Social Proof" },
  { value: "Product Demo" },
  { value: "Lifestyle" },
  { value: "Unboxing" },
  { value: "Spokesperson Anchor" },
  { value: "Dynamic Ad" },
  { value: "Case Study" },
];
