import React from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";
import { DownOutlined } from "@ant-design/icons";

export const ScrollIndicator = () => {
  return (
    <div
      className="p-1 indicator-scroll"
      onClick={() => {
        let y = document.getElementById("loader");
        if (!y) return;
        y.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }}
    >
      <div className="indicator-body">
        <DownOutlined style={{ fontSize: 30 }} />
      </div>
    </div>
  );
};

ScrollIndicator.propTypes = {
  reGenerate: PropTypes.func,
};
