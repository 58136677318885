import React from "react";
import { comingsoonImg } from "../../assests/images";
// import { Card, Avatar } from "antd";
// import { ClockCircleOutlined } from "@ant-design/icons";
import "./scss/index.scss";
// import { logo } from "../../assests/images";

export const Tutorials = () => {
  // const cardInfo = [
  //   {
  //     title: "how to use",
  //     description: "easy to use",
  //   },
  //   {
  //     title: "how to use",
  //     description: "easy to use",
  //   },
  //   {
  //     title: "how to use",
  //     description:
  //       "pisicing In esse deserunt ullamco commodo ipsum Lorem elit velit labore laborum do consectetur. enim amet sit exercitation elit nostrud.",
  //   },
  //   {
  //     title: "how to use",
  //     description: "easy to use",
  //   },
  //   {
  //     title: "how to use",
  //     description: "easy to use",
  //   },
  //   {
  //     title: "how to use",
  //     description: "easy to use",
  //   },
  // ];

  // const { Meta } = Card;

  return (
    // <div className="container p-6 mx-auto">
    <div className="flex items-center justify-center mobImg">
      <img src={comingsoonImg} />
      {/* <div className="flex items-center flew justify-between">
        <div> <h1>Tutorials</h1> </div>
      </div> */}
      {/* <div className="flex flex-wrap pt-10"> */}
      {/* {cardInfo.map((v, i) => {
          return (
            <div key={i} className="pr-5 pb-5">
              <Card
                hoverable
                className="videoCard"
                cover={
                  <img
                    alt="example"
                    src="https://source.unsplash.com/random/270x150"
                  />
                }
              >
                <Meta
                  title={<div>{v.title}</div>}
                  description={
                    <div className="container">
                      <div className="descriptions">
                        <p>{v.description}</p>
                      </div>
                      <div className="flex justify-between videoFooter">
                        <div className="flex flex-wrap items-center">
                          <div className="m-auto">
                            <Avatar
                              src={logo}
                              size={30}
                              className="logoAvatar"
                            />
                          </div>
                          <div className="info">
                            <p>Created By</p>
                            <h3 className="creater">Unicorncopy.AI</h3>
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <div className="mr-1  items-center">
                            <ClockCircleOutlined color="white" />
                          </div>
                          <div>25 min</div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </Card>
            </div>
          );
        })} */}
    </div>
    // </div>
  );
};
