import React from "react";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { Input } from "antd";
import { useState } from "react";

export const FilterShowCase = ({ handleSearch }) => {
  const { Search } = Input;
  const [search, setSearchKey] = useState("");

  return (
    <div className="flex w-full justify-center">
      <Search
        onChange={(event) => {
          setSearchKey(event.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(search);
          }
        }}
        size="large"
        placeholder="search"
        allowClear
        style={{ width: 604, marginRight: 50 }}
      />
    </div>
  );
};

FilterShowCase.propTypes = {
  setRenderBrain: PropTypes.any,
  handleSearch: PropTypes.func,
};
