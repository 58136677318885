import { postConstants } from "../constants";
import { postService } from "../../services";
// import { modalAction } from "./modal.actions";

const get = (data) => {
  const request = () => ({ type: postConstants.GET });
  const success = (post) => ({
    type: postConstants.GET,
    post,
  });
  const failure = (error) => ({ type: postConstants.FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    postService.getPost(data).then(
      (res) => {
        if (res && res.status === 200) {
          dispatch(success({ data: res.data }));
          //
        } else {
          //
          // dispatch(modalAction.show({ title: res.response?.data?.errors[0].msg }))
          // dispatch(failure(res.response?.data?.errors[0].msg));
        }
      },
      (error) => {
        //
        dispatch(failure(error.toString()));
      }
    );
  };
};
const search = (data) => {
  const request = () => ({ type: postConstants.GET_SEARCH, key: data.keyword });
  const success = (search) => ({
    type: postConstants.SEARCH,
    search,
  });
  const failure = (error) => ({ type: postConstants.FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    postService.search(data).then(
      (res) => {
        if (res && res.status === 200) {
          dispatch(success({ data: res.data }));
        } else {
          // dispatch(modalAction.show({ title: res.response?.data?.errors[0].msg }))
          // dispatch(failure(res.response?.data?.errors[0].msg));
        }
      },
      (error) => {
        //
        dispatch(failure(error.toString()));
      }
    );
  };
};
const filterAnalytics = (data, setErr, setLoading) => {
  const request = () => ({ type: postConstants.GET_SEARCH, key: data.keyword });
  const success = (search) => ({
    type: postConstants.SEARCH_ANALYTICS,
    search,
  });
  const failure = (error) => ({ type: postConstants.FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    postService.filterAnalytics(data).then(
      (res) => {
        if (res && res.status === 200) {
          dispatch(success({ data: res.data }));
        } else {
          setErr &&
            setErr(
              "Oops, we couldn’t find enough results to make reliable suggestions for your Landing Page. Please try again with another URL."
            );
          // dispatch(modalAction.show({ title: res.response?.data?.errors[0].msg }))

          // dispatch(failure(res.response?.data?.errors[0].msg));
        }
        setLoading(false);
      },
      (error) => {
        //
        dispatch(failure(error.toString()));
      }
    );
  };
};

export const postAction = {
  get,
  search,
  filterAnalytics,
};
