import React from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";

export default function Bubble({ audience, misc }) {
  return (
    <div className={`bg-bubble`} style={{ marginTop: misc && 20 }}>
      <p>{audience}</p>
    </div>
  );
}

Bubble.propTypes = {
  audience: PropTypes.string,
  misc: PropTypes.bool,
};
