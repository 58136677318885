import React, { useEffect, useState } from "react";
import { Card } from "antd";
import PropTypes from "prop-types";
import "./scss/index.scss";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { postService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/actions";
export const Cards = (props) => {
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  const [heartFill, setHeartFill] = useState(false);
  const dispatch = useDispatch();
  const addToFav = async () => {
    setHeartFill(!heartFill);
    const data = { template: props.heading };
    if (heartFill) {
      await postService.removeFromFavTemplate(data);
      return;
    }
    const res = await postService.addToFavTemplate(data);
    if (res.data.success) {
      const u = JSON.parse(localStorage.getItem("auth"));
      dispatch(authActions.getUser(u?.user?._id, "_"));
    }
  };

  useEffect(() => {
    if (user?.favTemplates?.length) {
      user?.favTemplates.forEach((element) => {
        if (element === props.heading) setHeartFill(true);
      });
    }
  }, [props.heading, user?.favTemplates]);

  return (
    <div className=" w-full flex flex-col align-stretch">
      <div
        className="FavouriteIcon ml-auto  relative top-14 z-40 mr-2 cursor-pointer rounded-full heartIconDiv"
        onClick={addToFav}
      >
        {!heartFill ? (
          <HeartOutlined className="p-3" />
        ) : (
          <HeartFilled className="p-3" style={{ color: "red" }} />
        )}
      </div>
      <Card
        style={{ minWidth: 228, minHeight: 308 }}
        onClick={props.onClick}
        className="customHeight card-item h:auto"
      >
        <div>
          <img src={props.img} alt="Icon" className="h-16 w-16" />
        </div>
        <h4 className="card-heading mt-4 mb-4">
          {props.heading && props.heading}
        </h4>
        <div>
          <p>{props.text && props.text}</p>
        </div>
      </Card>
    </div>
  );
};
Cards.propTypes = {
  img: PropTypes.any,
  favimg: PropTypes.any,
  heading: PropTypes.string,
  text: PropTypes.string,
  notActive: PropTypes.string,
  onClick: PropTypes.any,
};
