import React, { useCallback, useEffect, useState } from "react";
import { logoGif } from "../../assests/images";
import "./scss/index.scss";

import {
  brain,
  logo,
  logoName,
  dynamic,
  social,
  lifestyle,
  product,
  spokesperson,
  unboxing,
} from "../../assests/images";
// import { Select } from "antd";
import { Input } from "antd";

// const { Option } = Select;

import {
  ArrowLeftOutlined,
  // FacebookOutlined,
  // LinkedinOutlined,
  // YoutubeOutlined,
  // TwitterOutlined,
  // InstagramOutlined,
  // OneToOneOutlined,
  TeamOutlined,
  ProjectOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { BrainCard } from "../../components/brain-card";
import PropTypes from "prop-types";
import {
  BasicBoxPlot,
  RadarBar,
  ApexChart,
  // Heatmap,
  SplineChart,
  // ScatterChart,
} from "../../components/charts";
import { Row, Col } from "antd";
// import { TextInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { postAction } from "../../store/actions";
import { Filter } from "../Filter";
// import ReactLoading from "react-loading";
// import { BrainCard2 } from "../../components/brain-card-2";
let Icons = [
  <img src={dynamic} width={30} key={1} />,
  <img src={social} width={30} key={2} />,
  <img src={lifestyle} width={30} key={3} />,
  <img src={product} width={30} key={4} />,
  <img src={spokesperson} width={30} key={5} />,
  <img src={unboxing} width={30} key={6} />,
].flat();
export const Brain = (props) => {
  const [datapoints, setDataPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCategories, setFilterCategories] = useState(false);
  const post = useSelector((e) => e.post);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const selectedData = useSelector((e) => e.filter);
  const [searchKey, setSearchKey] = useState("");
  function closeQuickView() {
    props.setRenderBrain(false);
  }
  useEffect(() => {
    // Add a fake history event so that the back button does nothing if pressed once
    window.history.pushState(
      "fake-route",
      document.title,
      window.location.href
    );

    addEventListener("popstate", closeQuickView);

    // Here is the cleanup when this component unmounts
    return () => {
      removeEventListener("popstate", closeQuickView);
      // If we left without using the back button, aka by using a button on the page, we need to clear out that fake history event
      if (window.history.state === "fake-route") {
        window.history.back();
      }
    };
    // eslint-disable-next-line
  }, []);
  const getFilteredCards = useCallback(async () => {
    setLoading(true);
    const data = {
      category: selectedData?.refinements?.personName, // array of string
      classification: selectedData?.refinements?.Classification, // array of string ,

      adTypes: selectedData?.refinements?.adType,
      ad_score: selectedData?.refinements?.adScore?.length
        ? `${
            selectedData?.refinements?.adScore[0] +
            "/" +
            selectedData?.refinements?.adScore[1]
          }`
        : "",
      verified_facebook:
        selectedData?.refinements?.verifiedFb === "No" ? true : false,
      verified_instagram:
        selectedData?.refinements?.verifiedFb === "No" ? true : false,

      video_duration: !!selectedData?.refinements?.vidLength.length && {
        range1: selectedData?.refinements?.vidLength[0],
        range2: selectedData?.refinements?.vidLength[1],
      },
      likes: selectedData?.refinements?.likes?.length
        ? {
            range1: selectedData?.refinements?.likes[0],
            range2: selectedData?.refinements?.likes[1],
          }
        : { range1: 0, range2: 50000 },
      comments: selectedData?.refinements?.comments?.length
        ? {
            range1: selectedData?.refinements?.comments[0],
            range2: selectedData?.refinements?.comments[1],
          }
        : { range1: 0, range2: 50000 },
      shares: selectedData?.refinements?.shares?.length
        ? {
            range1: selectedData?.refinements?.shares[0],
            range2: selectedData?.refinements?.shares[1],
          }
        : { range1: 0, range2: 50000 },
      Averaged_Out_Pricing: selectedData?.refinements?.pricing?.length
        ? {
            range1: selectedData?.refinements?.pricing[0],
            range2: selectedData?.refinements?.pricing[1],
          }
        : "",
      editTypes: selectedData?.refinements?.editType,
      keyword: searchKey,
      skip: 0,
      limit: 10,
    };

    dispatch(postAction.filterAnalytics(data, () => {}, setLoading));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, dispatch, searchKey]);
  useEffect(() => {
    let timer;
    const filters = selectedData?.refinements;
    if (
      (filters?.Classification && filters?.Classification?.length) ||
      filters?.adScore?.length ||
      filters?.comments?.length ||
      filters?.likes?.length ||
      filters?.personName?.length ||
      filters?.shares?.length ||
      filters?.verifiedFb ||
      filters?.vidLength?.length ||
      filters?.pricing?.length ||
      filters?.editType?.length ||
      filters?.adType?.length
    ) {
      timer = setTimeout(() => {
        getFilteredCards();
      }, 1000);
    } else {
      // console.log("Ge5t cards");fcons
    }

    return () => {
      timer && clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, selectedData?.refinements, getFilteredCards]);

  useEffect(() => {
    if (post.analytics?.totalResults > 0) {
      const total = post.analytics?.totalResults;
      setTotal(total);
      let arr = [
        {
          key: [
            {
              name: "dynamic_ads",
              value: post.analytics?.dynamic_ads,
            },
            {
              name: "social_proof",
              value: post.analytics?.social_proof,
            },
            {
              name: "lifestyle",
              value: post.analytics?.lifestyle,
            },
            {
              name: "Product_Demo",
              value: post.analytics?.Product_Demo,
            },
            {
              name: "Spokesperson Anchor",
              value: post.analytics?.Spokesperson_Anchor,
            },
            {
              name: "dynamic_ads_views",
              value: post.analytics?.dynamic_ads_views,
            },
            {
              name: "social_proof_views",
              value: post.analytics?.social_proof_views,
            },
            {
              name: "lifestyle_views",
              value: post.analytics?.lifestyle_views,
            },
            {
              name: "Product_Demo_views",
              value: post.analytics?.Product_Demo_views,
            },

            {
              name: "Spokesperson Anchor Views",
              value: post.analytics?.Spokesperson_Anchor_views,
            },

            {
              name: "Unboxing",
              value: post.analytics?.unboxing_views,
            },
            {
              name: "views",
              value: post.analytics?.views,
            },
            {
              name: "Case Study Views",
              value: post.analytics?.case_study_views,
            },
            {
              name: "Case Study",
              value: post.analytics?.case_study,
            },
          ],
        },
        {
          key: [
            {
              name: "Highly_edited_videos",
              value: post.analytics.Highly_edited_video_views,
            },
            {
              name: "Raw_cuts",
              value: post.analytics.Raw_cuts_views,
            },
            {
              name: "views",
              value: post.analytics.views,
            },
          ],
        },
        {
          key: [
            {
              name: "Primary text word count",
              value: post.analytics.primary_text_word_count,
            },
            {
              name: "Average word count",
              value: Math.round(
                post.analytics.primary_text_word_count /
                  post.analytics.totalResults
              ),
            },
            {
              name: "Min",
              value: post.analytics.minimum_primary_text_word_count,
            },
            {
              name: "Max",
              value: post.analytics.maximum_primary_text_word_count,
            },
          ],
        },
        {
          key: [
            {
              name: "Text Score Flesch Kincaid",
              value: post.analytics.text_score_flesch_kincaid,
            },
            {
              name: "Average word count",
              value: Math.round(
                post.analytics.text_score_flesch_kincaid /
                  post.analytics.totalResults
              ),
            },
            {
              name: "Min",
              value: post.analytics.minimum_text_score_flesch_kincaid,
            },
            {
              name: "Max",
              value: post.analytics.maximum_text_score_flesch_kincaid,
            },
          ],
        },
        {
          key: [
            {
              name: `Offer Discount`,
              value: Math.round(
                (post.analytics.viewsInDiscounts / post.analytics.views) * 100 -
                  100
              ),
              actualValue: post.analytics.discount,
            },
            {
              name: "Do Not Offer Any Discount",
              value: Math.round(
                (post.analytics.viewsNotDiscount / post.analytics.views) * 100 -
                  100
              ),
              actualValue: total - post.analytics.discount,
            },
          ],
        },
        {
          key: [
            {
              name: `Include Testimonials`,
              value: Math.round(
                (post.analytics.viewInTestimonial / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: post.analytics.testimonial_in_copy,
            },
            {
              name: "Do Not Include Any Testimonials",
              value: Math.round(
                (post.analytics.viewNotTestimonial / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: total - post.analytics.testimonial_in_copy,
            },
          ],
        },
        {
          key: [
            {
              name: "Mentions Shipping In Copy",
              value: Math.round(
                (post.analytics.viewsInShippingInCopy / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: post.analytics.mentions_shipping_in_copy,
            },
            {
              name: "Does Not Include Shipping",
              value: Math.round(
                (post.analytics.viewsNotShippingInCopy / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: total - post.analytics.mentions_shipping_in_copy,
            },
          ],
        },
        {
          key: [
            {
              name: "Offer Free Shipping",
              value: Math.round(
                (post.analytics.viewInShipping / post.analytics.views) * 100 -
                  100
              ),
              actualValue: post.analytics.free_shipping,
            },
            {
              name: "Do Not Offer Free Shipping",
              value: Math.round(
                (post.analytics.viewNotShipping / post.analytics.views) * 100 -
                  100
              ),
              actualValue: total - post.analytics.free_shipping,
            },
          ],
        },
        {
          key: [
            {
              name: "Uses “!” on the Headline?",
              value: post.analytics.uses_on_the_Headline,
            },
            {
              name: "Does not Uses “!”",
              value: total - post.analytics.uses_on_the_Headline,
            },
          ],
        },
        {
          key: [
            {
              name: "Send Users To Your Homepage?",
              value: Math.round(
                (post.analytics.viewsInOutbound / post.analytics.views) * 100 -
                  100
              ),
              actualValue: post.analytics.outbound_URL_is_Homepage,
            },
            {
              name: "Send Users To A Custom Landing Page?",
              value: Math.round(
                (post.analytics.viewsNotOutbound / post.analytics.views) * 100 -
                  100
              ),
              actualValue: total - post.analytics.outbound_URL_is_Homepage,
            },
          ],
        },
        {
          key: [
            {
              name: "CTA URL on the Copy?",
              value: post.analytics.CTA_URL_on_the_Copy,
            },
            {
              name: "No CTA URL on the Copy",
              value: total - post.analytics.outbound_URL_is_Homepage,
            },
          ],
        },
        {
          key: [
            {
              name: "Description Used",
              value: Math.round(
                (post.analytics.viewsInDescription / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: post.analytics.description_used,
            },
            {
              name: "Does Not Include Description",
              value: Math.round(
                (post.analytics.viewsNoDescription / post.analytics.views) *
                  100 -
                  100
              ),
              actualValue: total - post.analytics.description_used,
            },
          ],
        },
        {
          key: [
            { name: "Uses ® or ™ in the copy?", value: 400 },
            { name: "Does not  Uses ® or ™ in the copy", value: 300 },
          ],
        },
        {
          key: [
            {
              name: "Text Score Dale Chall",
              value: post.analytics.text_score_dale_chall,
            },
            {
              name: "Average word count",
              value: Math.round(
                post.analytics.text_score_dale_chall /
                  post.analytics.totalResults
              ),
            },
            {
              name: "Min",
              value: post.analytics.minimum_text_score_dale_chall,
            },
            {
              name: "Max",
              value: post.analytics.maximum_text_score_dale_chall,
            },
          ],
        },
        {
          key: [
            {
              name: "Headline word count",
              value: post.analytics.headline_word_count,
            },
            {
              name: "Average word count",
              value: Math.round(
                post.analytics.headline_word_count / post.analytics.totalResults
              ),
            },
            { name: "Min", value: post.analytics.minimum_headline_word_count },
            { name: "Max", value: post.analytics.maximum_headline_word_count },
          ],
        },
        {
          key: [
            {
              name: "Ideal Video Creative Length",
              value: post.analytics?.Average_Video_Creative_Length,
            },
          ],
        },
        {
          key: [
            {
              name: "Product Price",
              value: post.analytics?.Product_Price,
            },
          ],
        },
        {
          key: [
            {
              name: `Word you in Primary Text  `,
              value: post.analytics.uses_word_you_in_primary_text,
            },
            {
              name: "Does not use word You in Primary Text ",
              value: total - post.analytics.uses_word_you_in_primary_text,
            },
          ],
        },
      ];
      setDataPoints(arr);
      // console.log("datapoint ==> ", arr);
      setShow(true);
    }
  }, [post.analytics]);

  let chart1Series = !datapoints.length
    ? []
    : [
        Math.round(
          (datapoints[6]?.key[0]?.actualValue * 100) /
            (datapoints[6]?.key[0]?.actualValue +
              datapoints[6]?.key[1]?.actualValue)
        ),
        Math.round(
          (datapoints[6]?.key[1]?.actualValue * 100) /
            (datapoints[6]?.key[0]?.actualValue +
              datapoints[6]?.key[1]?.actualValue)
        ),
      ];
  let chart1Colors = ["#56adb6", "#ee9fa3"];
  let chart1Labels = ["Offer free shipping", "Does not offer"];
  let freeShippingLabels = generateOtherLables(datapoints[6]);
  let discountLables = generateOtherLables(datapoints[4]);
  let chartDiscount = !datapoints.length
    ? []
    : [
        Math.round(
          (datapoints[4]?.key[0]?.actualValue * 100) /
            (datapoints[4]?.key[0]?.actualValue +
              datapoints[4]?.key[1]?.actualValue)
        ),
        Math.round(
          (datapoints[4]?.key[1]?.actualValue * 100) /
            (datapoints[4]?.key[0]?.actualValue +
              datapoints[4]?.key[1]?.actualValue)
        ),
      ];
  let chartHighlyEditedVideos = !datapoints.length
    ? []
    : [
        Math.round(
          (datapoints[1]?.key[0]?.value * 100) /
            (datapoints[1]?.key[0]?.value + datapoints[1]?.key[1]?.value)
        ),
        Math.round(
          (datapoints[1]?.key[1]?.value * 100) /
            (datapoints[1]?.key[0]?.value + datapoints[1]?.key[1]?.value)
        ),
      ];
  let editedVideosLabels = [];
  if (datapoints[1]?.key?.length) {
    datapoints[1]?.key?.forEach((e) => {
      if (e.name === "views") return;
      var value = 0;
      let status = "";
      let total, result;
      total = datapoints[1].key[2]?.value / e?.value;
      result = Math.round(total * 100);
      value = 100 - result;
      if (value === -Infinity) value = 0;
      if (value === 0) status = 0;
      if (value > 0 || value < 0) {
        status = value;
      }
      let name =
        e.name === "Highly_edited_videos" ? "Highly Edited Videos" : "Raw Cuts";
      editedVideosLabels.push({ name, status });
    });
  }
  let charthomeUrl = !datapoints.length
    ? []
    : [
        Math.round(
          (datapoints[9]?.key[0]?.actualValue * 100) /
            (datapoints[9]?.key[0]?.actualValue +
              datapoints[9]?.key[1]?.actualValue)
        ),
        Math.round(
          (datapoints[9]?.key[1]?.actualValue * 100) /
            (datapoints[9]?.key[0]?.actualValue +
              datapoints[9]?.key[1]?.actualValue)
        ),
      ];
  let homeUrlLabels = generateOtherLables(datapoints[9]);

  let chartTestimonals = !datapoints.length
    ? []
    : [
        Math.round(
          (datapoints[5]?.key[0]?.actualValue * 100) /
            (datapoints[5]?.key[0]?.actualValue +
              datapoints[5]?.key[1]?.actualValue)
        ),
        Math.round(
          (datapoints[5].key[1]?.actualValue * 100) /
            (datapoints[5].key[0]?.actualValue +
              datapoints[5].key[1]?.actualValue)
        ),
      ];
  let testimonalLabels = generateOtherLables(datapoints[9]);

  var totalC =
    datapoints[0]?.key[5]?.value +
    datapoints[0]?.key[6]?.value +
    datapoints[0]?.key[7]?.value +
    datapoints[0]?.key[8]?.value +
    datapoints[0]?.key[9]?.value +
    datapoints[0]?.key[10]?.value +
    datapoints[0]?.key[12]?.value;
  let mostValuableAdTypesLabels = [];
  let mostValuableAdTypes = [
    Math.round((datapoints[0]?.key[5]?.value * 100) / totalC),
    Math.round((datapoints[0]?.key[6]?.value * 100) / totalC),
    Math.round((datapoints[0]?.key[7]?.value * 100) / totalC),
    Math.round((datapoints[0]?.key[8]?.value * 100) / totalC),
    Math.round((datapoints[0]?.key[9]?.value * 100) / totalC),
    Math.round((datapoints[0]?.key[10]?.value * 100) / totalC),
  ];
  if (datapoints[0]?.key?.length) {
    datapoints[0]?.key?.forEach((e, i) => {
      if (e.name === "views") return;
      var value = 0;
      let status = "";
      let total, result;
      if (i >= 5 && i <= 11 && e.value > 0) {
        let pr = 100;
        total = datapoints[0].key[11].value / e.value;
        result = Math.round(total * pr);
        value = 100 - result;
        if (
          value !== Number.POSITIVE_INFINITY &&
          value !== Number.NEGATIVE_INFINITY &&
          e?.name !== "views"
        ) {
          if (value === 0) status = 0;
          if (value > 0 || value < 0) {
            status = value;
          }
          let name =
            e?.name === "dynamic_ads_views"
              ? "Dynamic Ads"
              : e?.name === "social_proof_views"
              ? "Social Proof"
              : e?.name === "Product_Demo_views"
              ? "Product Demo"
              : e?.name === "lifestyle_views"
              ? "Lifestyle"
              : e?.name === "Spokesperson Anchor Views"
              ? "Spokesperson Anchor"
              : e?.name === "Case Study Views"
              ? "Case Study"
              : e?.name;
          // if (showLog) console.log(status);

          mostValuableAdTypesLabels.push({ name, status });
        }
      }
    });
  }
  function generateOtherLables(datapoints) {
    let temp = [];
    datapoints?.key?.forEach((e) => {
      if (e.name === "views") return;
      var value = e.value;
      let status = "";
      if (
        value !== Number.POSITIVE_INFINITY &&
        value !== Number.NEGATIVE_INFINITY &&
        e?.name !== "views"
      ) {
        if (value === 0) status = 0;
        if (value > 0 || value < 0) {
          status = value;
        }
        let modifiedName =
          e.name === "Offer Discount"
            ? "Offer"
            : e.name === "Do Not Offer Any Discount"
            ? "Do Not Offer"
            : e.name === "Include Testimonials"
            ? "Include"
            : e.name === "Do Not Include Any Testimonials"
            ? "Do Not Include"
            : e.name === "Mentions Shipping In Copy"
            ? "Mentions Shipping"
            : e.name === "Does Not Include Shipping"
            ? "Does Not Include"
            : e.name === "Send Users To Your Homepage?"
            ? "Send To Homepage"
            : e.name === "Send Users To A Custom Landing Page?"
            ? "Send To Landing Page"
            : e.name;
        temp.push({ name: modifiedName, status });
      }
    });
    return temp;
  }
  let chart2Colors = ["#56adb6", "#ee9fa3"];
  let chart2Labels = ["Sending Url", "Not Sending"];
  let chart3Series = [90, 66, 44, 30, 33, 45];
  let chart3Colors = [
    "#d4c44e",
    "#5ab4bd",
    "#b5d63d",
    "#ffae49",
    "#c27615",
    "#ee9fa3",
  ];
  let chart3Labels = [
    "Dynamic Ads",
    "Social Proof",
    "Lifestyle",
    "Product Demo",
    "SpokesPerson Anchor",
    "Unboxing",
  ];

  const { Search } = Input;

  const getIcon = (i) => {
    if (i === 5) return <>{Icons[0]}</>;
    else if (i === 6) return <>{Icons[1]}</>;
    else if (i === 7) return <>{Icons[2]}</>;
    else if (i === 8) return <>{Icons[3]}</>;
    else if (i === 9) return <>{Icons[4]}</>;
    else return Icons[5];
  };
  const getColor = (i) => {
    if (i === 5) return "#D04443";
    else if (i === 6) return "#14b8a6";
    else if (i === 7) return "#ffaf20";
    else if (i === 8) return "#5048e4";
    else if (i === 9) return "#83f2ae";
    else return "#6d378f";
  };

  return (
    <div className="main pb-8">
      <div className="subMain mx-auto">
        <div className="topHeader flex justify-between py-5 w-11/12">
          <div className="flex justify-center items-center">
            <div>
              <img src={logo} width="70px" />
            </div>
            <div>
              <img src={logoName} width="140px" height="120px" />
            </div>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => props.setRenderBrain(false)}
          >
            <ArrowLeftOutlined style={{ color: "#9799a0", fontSize: 15 }} />
            <p className="backTxt m-0 ml-1">Exit The Brain</p>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-5">
          <div className="flex">
            <div className="px-2">
              <img src={brain} width="40px" />
            </div>
            <div className="searchDivHeading text-4xl m-0">The Brain</div>
          </div>
          <h1 className="searchDivHeading text-4xl m-0">
            Profitable Ad Creative Answers
          </h1>
          <p className="searchDivSubHeading m-0 mt-5">
            🔍 How do I use The Brain to improve my Ad Creative performance?
          </p>
          <div className="flex mt-10 flex-col justify-center items-center">
            <Search
              onChange={(event) => {
                setSearchKey(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  getFilteredCards();
                }
              }}
              size="large"
              datapoints={datapoints}
              total={total}
              shows={show + 1}
              placeholder="search"
              allowClear
              style={{ width: 604 }}
            />
            <div
              onClick={() => setFilterCategories(!filterCategories)}
              className="flex cursor-pointer searchDivSubHeading mt-5"
            >
              <p>OR, filter the Ad Creative insights by specific categories</p>
              {filterCategories ? (
                <span
                  onClick={() => setFilterCategories(false)}
                  className="mx-2 cursor-pointer"
                >
                  -
                </span>
              ) : (
                <span
                  onClick={() => setFilterCategories(true)}
                  className="mx-2 cursor-pointer"
                >
                  +
                </span>
              )}
            </div>

            {filterCategories ? (
              <div
                className="filters"
                style={{
                  maxWidth: 1450,
                }}
              >
                <Filter />
              </div>
            ) : null}
          </div>
        </div>
        {loading && (
          <img
            src={logoGif}
            width="100px"
            className="h-24 mr-auto ml-auto mt-10 object-cover"
          />
        )}
        {show && (
          <>
            <div className="graphDivMain">
              <div>
                <Row gutter={[12, 12]} className="justify-center">
                  {!!(datapoints?.length && datapoints[0].key.length) &&
                    datapoints[0].key.map((e, i) => {
                      let pr = 100;
                      const total = datapoints[0].key[11].value / e.value;
                      const result = Math.round(total * pr);
                      let value = 100 - result;
                      if (
                        i >= 5 &&
                        i <= 11 &&
                        e.value > 0 &&
                        i !== 11 &&
                        value !== Number.POSITIVE_INFINITY &&
                        value !== Number.NEGATIVE_INFINITY
                      ) {
                        const icon = getIcon(i);
                        let num = e?.value;
                        if (num) {
                          num = parseInt(num);
                          num = num.toLocaleString("en-US");
                        }
                        return (
                          <Col lg={8} md={12} sm={12} xs={24} key={i}>
                            <BrainCard
                              heading={
                                e?.name === "dynamic_ads_views"
                                  ? "Dynamic Ads"
                                  : e?.name === "social_proof_views"
                                  ? "Social Proof"
                                  : e?.name === "Product_Demo_views"
                                  ? "Product Demo"
                                  : e?.name === "lifestyle_views"
                                  ? "Lifestyle"
                                  : e?.name === "Spokesperson Anchor Views"
                                  ? "Spokesperson Anchor"
                                  : e?.name === "Case Study Views"
                                  ? "Case Study"
                                  : e?.name
                              }
                              amount={num + " views"}
                              bottomText="Since last month"
                              perc={value}
                              roundIcon={icon}
                              color={getColor(i)}
                            />
                          </Col>
                        );
                      } else {
                        return null;
                      }
                    })}
                  {false && (
                    <>
                      <Col lg={6} md={12} sm={12} xs={24}>
                        <BrainCard
                          heading="Ideal Video Creative Length"
                          amount={
                            Math.round(datapoints[15].key[0].value / total) +
                            " Seconds"
                          }
                          bottomText="Since last month"
                          perc="16"
                          roundIcon={
                            <TeamOutlined
                              style={{ fontSize: "25px", color: "#121826" }}
                            />
                          }
                          color="#14b8a6"
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12} xs={24}>
                        <BrainCard
                          heading="Average Product Price"
                          amount={
                            "$" +
                            Math.round(datapoints[16].key[0].value / total)
                          }
                          bottomText="Since last month"
                          perc="16"
                          progress={true}
                          roundIcon={
                            <ProjectOutlined
                              style={{ fontSize: "25px", color: "#121826" }}
                            />
                          }
                          color="#ffaf20"
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12} xs={24}>
                        <BrainCard
                          heading="TOTAL PROFIT"
                          amount="$23k"
                          bottomText="Since last month"
                          perc="16"
                          profit={true}
                          roundIcon={
                            <DollarCircleOutlined
                              style={{ fontSize: "25px", color: "#121826" }}
                            />
                          }
                          color="#5048e4"
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              {false && (
                <Row gutter={[24, 24]} className="mt-6">
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="multipleCardsDiv flex flex-col justify-between">
                      <div className="flex justify-between"></div>
                      <div className="flex justify-between"></div>
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="graphDiv2 p-3">
                      <p className="graphHeading mb-0">Average Product Price</p>
                      <BasicBoxPlot />
                    </div>
                  </Col>
                </Row>
              )}
              <Row gutter={[24, 24]} className="mt-6">
                <Col lg={24} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">Most Valuable Ad Types</p>
                    <ApexChart
                      height="420"
                      type="C"
                      subType="eighty"
                      series={mostValuableAdTypes}
                      color={chart1Colors}
                      label={chart1Labels}
                      bottomText="Include"
                      bottomText2="Do Not Include"
                      mostValuableAdTypesLabels={mostValuableAdTypesLabels}
                    />
                  </div>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">
                      Ad performance Impact Mentioning Free Shipping on your add
                      or not
                    </p>
                    {!!chart1Series.length && (
                      <ApexChart
                        height="340"
                        series={chart1Series}
                        color={chart1Colors}
                        label={chart1Labels}
                        bottomText="Mentions Shipping"
                        bottomText2="Does Not Include"
                        mostValuableAdTypesLabels={freeShippingLabels}
                      />
                    )}
                  </div>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">
                      Should You Send Users To A Homepage URL
                    </p>
                    <ApexChart
                      height="370"
                      series={charthomeUrl}
                      color={chart2Colors}
                      mostValuableAdTypesLabels={homeUrlLabels}
                      label={chart2Labels}
                      bottomText="Send To Homepage"
                      bottomText2="Sending To Landing Page"
                    />
                  </div>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">
                      Highly Edited Videos Vs Rw Cuts
                    </p>
                    <ApexChart
                      height="370"
                      series={chartHighlyEditedVideos}
                      color={chart1Colors}
                      label={chart1Labels}
                      bottomText="Highly Edited Videos"
                      bottomText2="Raw Cuts"
                      type="C"
                      mostValuableAdTypesLabels={editedVideosLabels}
                    />
                  </div>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">
                      Should you offer a discount on ads?
                    </p>
                    <ApexChart
                      height="370"
                      series={chartDiscount}
                      mostValuableAdTypesLabels={discountLables}
                      color={chart1Colors}
                      label={chart1Labels}
                      bottomText="Offer"
                      bottomText2="Do Not Offer"
                    />
                  </div>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <div className="graphDiv p-3">
                    <p className="graphHeading mb-0">
                      Should you add testimonials to your ads?
                    </p>
                    <ApexChart
                      height="370"
                      series={chartTestimonals}
                      color={chart1Colors}
                      label={chart1Labels}
                      bottomText="Include"
                      mostValuableAdTypesLabels={testimonalLabels}
                      bottomText2="Do Not Include"
                    />
                  </div>
                </Col>
              </Row>
              {false && (
                <Row gutter={[24, 24]} className="mt-5">
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="graphDiv2 p-3">
                      <p className="graphHeading mb-0">Average Product Price</p>
                      <ApexChart
                        height="450"
                        series={chart3Series}
                        color={chart3Colors}
                        label={chart3Labels}
                        bottomText="Mentions Shipping"
                        bottomText2="Does Not Include"
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="graphDiv2 p-3">
                      <p className="graphHeading mb-0">
                        Most Engaging Hook Type
                      </p>
                      <RadarBar />
                    </div>
                  </Col>
                </Row>
              )}
              <div className="ThirdRowMain  w-full mt-5 flex md:flex-row md:justify-between sm:flex-col"></div>
              {false && (
                <Row className="mt-5">
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <div className="graphDiv2 p-3">
                      <p className="graphHeading mb-0">
                        Likes vs Shares vs Comments
                      </p>
                      <SplineChart />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            <div className="mt-5 w-11/12 mx-auto footerDiv relative pt-3">
              <div className="FooterTextDiv absolute right-0">
                <a
                  href="https://www.chamber.media"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="FooterLinkTxt mx-3 cursor-pointer mb-0">
                    Powered by Chamber Media
                  </p>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Brain.propTypes = {
  setRenderBrain: PropTypes.any,
  renderBrain: PropTypes.bool,
};
