import React from "react";
import { AgencyPlan, PowerPlan, GrowthPlan } from "../../components";
import "./scss/index.scss";
import { Col, Row } from "antd";
import { authService } from "../../services";
import { Navbar } from "../../components";

export const Packages = () => {
  const result = (res) => {
    if (res?.data) {
      window.location.href = res?.data;
    }
    if (res.redirected) {
      window.location.href = res;
    }
  };
  const createCheckoutSessionGrowth = async () => {
    try {
      const formData = {
        // priceId: "price_1KRet4ITK2576wx8ffOzed9Y",
        // prod_id: "prod_L7uizXfBsXRMKY",
        priceId: products[0].priceId,
        prod_id: products[0].id,
        type: "growth",
      };
      const res = await authService.createCheckoutSession(formData);
      result(res);
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionPower = async () => {
    try {
      const formData = {
        priceId: products[1].priceId,
        prod_id: products[1].id,
        type: "power",
      };
      const res = await authService.createCheckoutSession(formData);
      result(res);
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionAgency = async () => {
    try {
      const formData = {
        priceId: products[2].priceId,
        prod_id: products[2].id,
        type: "agency",
      };
      const res = await authService.createCheckoutSession(formData);
      result(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Navbar />
      <div className="mt-8 w-full container mx-auto  text-center pb-10">
        <h1 className="heading w-full">Select your UnicornCopy.AI package</h1>
        <h2 className="subtitle">
          Getting top 1% Ad Copy will cost you less than 1 coffee per day ☕️
        </h2>
        <div className="mt-10">
          <Row className="flex justify-center items-center">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <GrowthPlan createCheckoutSession={createCheckoutSessionGrowth} />
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <AgencyPlan createCheckoutSession={createCheckoutSessionPower} />
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <PowerPlan createCheckoutSession={createCheckoutSessionAgency} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export const products = [
  {
    name: "Basic",
    id: "prod_K8vD2BoV4qQu3W",
    amount: 15,
    description: "Basic",
    priceId: "price_1Lfni4JqvE65u2mwnAhViz0m",
    planId: "prod_K8vD2BoV4qQu3W",
  },
  {
    name: "Agency",
    id: "prod_K95qYSdEL1xEt5",
    amount: 199,
    description: "Basic",
    priceId: "price_1JUnexJqvE65u2mwGF7msNEn",
    planId: "prod_K95qYSdEL1xEt5",
  },
  {
    name: "Unlimited",
    id: "prod_K95qrrQ5KDZhc0",
    amount: 99,
    description: "Unlimited",
    priceId: "price_1JUneYJqvE65u2mwnAyr3Jlx",
    planId: "prod_K95qrrQ5KDZhc0",
  },
];
