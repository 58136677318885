import {
  facebookAd,
  facebookHeadline,
  facebookPrimeryText,
  linkedInAdCopy,
  personalAdBio,
  companyAdBio,
  googleAdCopy,
  googleAdHeadline,
  seoTilteAndMetaDescription,
  googleAdDescription,
  tiktokAdCopy,
  aida,
  problemAagitateSolution,
  productDescription,
  contentImprover,
  creativeStory,
  blogPostTopic,
  blogPostOutline,
  blogPostIntro,
  featureToBenefit,
  sentenceExpander,
  realEstateListing,
  amazonProductFeatures,
  engagingQues,
  perfectHeadline,
  quoraAnswers,
  marketingAngles,
  persuasiveBulletPoint,
  videoTopicIdeas,
  emailSubject,
  videoScriptHook,
  videoTitle,
  explainItToChild,
  videoDescription,
  reviewResponder,
  seoBlog,
  seoMeta,
  seoProductPage,
  seoServicePage,
  uniqueValue,
  component270_29,
  component270_30,
  component270_31,
  component270_32,
} from "../../assests/images";

export const sharedCardData = [
  {
    img: facebookAd,
    heading: "Facebook Ad Copy",
    text: "Generate Ad Copy that can be used on Facebook, Instagram and Audience Network.",
    type: "fb",
  },
  {
    img: linkedInAdCopy,
    heading: "LinkedIn Ad Copy",
    text: "Generate Ad Copy that can be used on LinkedIn, Facebook and Audience Network.",
    type: "ld",
  },
  {
    img: googleAdCopy,
    heading: "Google Ad Copy",
    text: "Generate Ad Copy that can be used on Google Search and google Ads.",
    type: "google",
  },
  {
    img: tiktokAdCopy,
    heading: "TikTok Ad Copy",
    text: "Coming soon - The A.I. is being trained right now, Please stay tuned.",
    type: "google_description",
  },
  {
    img: facebookPrimeryText,
    heading: "Facebook Ad Primary Text",
    text: "Create high converting copy for the 'Primary Text' section of your Facebook ads.",
    type: "fb_primary",
  },
  {
    img: facebookHeadline,
    heading: "Facebook Ad Headline",
    text: "Generate scroll-stopping headlines for your Facebook Ads to get prospects to click, and ultimately buy.",
    type: "fb_headline",
  },
  {
    img: googleAdHeadline,
    heading: "Google Ads Headline",
    text: "Create high converting copy for the 'Headlines' section of your Google Ads.",
    type: "google_headline",
  },
  {
    img: perfectHeadline,

    heading: "Perfect Headline",
    text: "Trained with formulas from the world's best copywriters, this template is sure to create high-converting headlines for your business.",
  },
  {
    img: component270_29,

    heading: "Website Sub-Headline",
    text: "Create delightfully informative sub-headlines (H2) for your websites and landing pages.",
  },
  {
    img: seoTilteAndMetaDescription,

    heading: "SEO - Title and Meta Descriptions",
    text: "Write SEO optimized title tags and meta descriptions that will rank well on Google.",
    type: "seo_title_meta",
  },
  {
    img: googleAdDescription,

    heading: "Google Ads Description",
    text: "Create high converting copy for the 'Description' section of your Google Ads.",
    type: "google_description",
  },
  {
    img: videoDescription,

    heading: "Video Description",
    text: "Create unique descriptions for Youtube videos that rank well in search.",
  },
  {
    img: productDescription,

    heading: "Product description",
    text: "Create compelling product descriptions to be used on websites, emails and social media.",
    type: "product_description",
  },
  {
    img: amazonProductFeatures,

    heading: "Amazon Product Description (paragraph)",
    text: "Create compelling product descriptions for Amazon listings. The output is typically paragraph form, but the style will vary.",
  },
  {
    img: seoBlog,

    heading: "SEO - Blog Posts - Title and Meta Descriptions",
    text: "Write SEO optimized title tags and meta descriptions for blog posts that will rank well on Google.",
  },
  {
    img: seoMeta,

    heading: "SEO - Homepage - Title and Meta Descriptions",
    text: "Write SEO optimized title tags and meta descriptions for homepages that will rank well on Google.",
  },
  {
    img: seoProductPage,

    heading: "SEO - Product Page - Title and Meta Descriptions",
    text: "Write SEO optimized title tags and meta descriptions that will rank well on Google for product pages.",
  },
  {
    img: seoServicePage,

    heading: "SEO - Services Pages - Title and Meta Descriptions",
    text: "Write SEO optimized title tags and meta descriptions that will rank well on Google for company services pages.",
  },
  {
    img: personalAdBio,
    heading: "Personal Bio",
    text: "Write a creative personal bio that captures attention.",
    type: "personal_bio",
  },
  {
    img: companyAdBio,
    heading: "Company Bio",
    text: "Tell your company's story with a captivating bio.",
    type: "company_bio",
  },

  {
    img: aida,

    heading: "AIDA Framework",
    text: "Use the oldest marketing framework in the world. Attention, Interest, Desire, Action.",
    type: "aida",
  },
  {
    img: problemAagitateSolution,

    heading: "Problem-Agitate-Solution (PAS) Framework",
    text: "A valuable framework for creating new marketing copy ideas.",
    type: "pas",
  },

  {
    img: contentImprover,

    heading: "Content improver",
    text: "Take a piece of content and rewrite it to make it more interesting, creative, and engaging.",
    type: "content_improver",
  },
  {
    img: creativeStory,

    heading: "Creative Story",
    text: "Write deliciously creative stories to engage your readers.",
    type: "creative_story",
  },
  {
    img: blogPostTopic,

    heading: "Blog Post Topic Ideas",
    text: "Brainstorm new blog post topics that will engage readers and rank well on Google.",
    type: "blog_topic",
  },
  {
    img: blogPostOutline,

    heading: "Blog Post Outline",
    text: "Create lists and outlines for articles. Works best for 'Listicle' and 'How to' style blog posts or articles.",
    type: "blog_outlice",
  },
  {
    img: blogPostIntro,

    heading: "Blog Post Intro Paragraph",
    text: "Blast through writers block by letting us write your opening paragraph for you.",
  },
  {
    img: featureToBenefit,

    heading: "Feature to Benefit",
    text: "Turn your product features into benefits that compel action.",
  },
  {
    img: blogPostIntro,

    heading: "Blog Post Conclusion Paragraph",
    text: "Wrap up your blog posts with an engaging conclusion paragraph.",
  },
  {
    img: sentenceExpander,

    heading: "Sentence Expander",
    text: "Expand a short sentence or a few words into a longer sentence that is creative, interesting, and engaging.",
  },
  {
    img: realEstateListing,

    heading: "Real Estate Listing - Residential",
    text: "Creative captivating real estate listings that sell homes quickly.",
  },
  {
    img: amazonProductFeatures,

    heading: "Amazon Product Features (bullets)",
    text: "Create key feature and benefit bullet points for Amazon listings under the 'about this item' section.",
  },

  {
    img: engagingQues,

    heading: "Engaging Questions",
    text: "Ask your audience creative questions to increase engagement.",
  },

  {
    img: quoraAnswers,

    heading: "Quora Answers",
    text: "Intelligent answers for tough questions.",
  },

  {
    img: component270_30,

    heading: "Photo Post Captions",
    text: "Write catchy captions for your Instagram posts",
  },
  {
    img: component270_31,

    heading: "Short Social Posts",
    text: "Generate short social posts under 140 characters.",
  },
  {
    img: component270_32,

    heading: "Ridiculous Marketing Ideas",
    text: "A funny template that generates bad marketing ideas. Like throwing a metal ball at a car window.",
  },
  {
    img: marketingAngles,

    heading: "Marketing Angles",
    text: "Brainstorm different angles to add vibrancy to your marketing.",
  },
  {
    img: persuasiveBulletPoint,

    heading: "Persuasive bullet points",
    text: "Generate persuasive bullet points to insert into landing pages, emails, and more.",
  },
  {
    img: videoTopicIdeas,

    heading: "Video Topic Ideas",
    text: "Brainstorm new video topics that will engage viewers and rank well on YouTube.",
  },
  {
    img: emailSubject,

    heading: "Email Subject Lines",
    text: "Write compelling email subject lines that get readers to open.",
  },
  {
    img: videoScriptHook,

    heading: "Video Script Outline",
    text: "Create script outlines for your videos. Works best for 'Listicle' and 'How to' style videos.",
  },
  {
    img: videoTitle,

    heading: "Video Titles",
    text: "Create engaging, click-worthy titles for your videos that will rank on Youtube.",
  },
  {
    img: videoScriptHook,

    heading: "Video Script Hook and Introduction",
    text: "Create a video intro that will capture your viewers attention and compel them to watch all the way through.",
  },
  {
    img: explainItToChild,

    heading: "Explain It To A Child",
    text: "Rephrase text to a lower grade level make it more simple to understand and easier to read.",
  },

  {
    img: reviewResponder,

    heading: "Review Responder",
    text: "Write responses to public customer reviews that are winsome, professional, and delightful.",
  },

  {
    img: uniqueValue,

    heading: "Unique Value Propositions",
    text: "Create a clear statement that describes the benefit of your offer in a powerful way.",
  },
];
