import React, { useEffect, useState } from "react";
import "./scss/index.scss";
import { PaymentSetting } from "./payment-setting";
import { GeneralSetting } from "./general-setting";

import { Tabs } from "antd";

import { Plans } from "./plans-setting";
import { Btn, CancelPlanModal } from "../../components";

export const Settings = () => {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState("1");
  const [modal, setModal] = useState(false);
  let searchParams = new URLSearchParams(window.location.search);
  let param = searchParams.has("packages");
  useEffect(() => {
    if (param) setTabKey("2");
  }, [param]);
  return (
    <div className="w-full p-5   container mx-auto">
      <div className="pt-4 flex justify-between">
        <h1 className="heading-setting">Settings</h1>
        <span>
          {tabKey === "2" && (
            <Btn
              bgColor="#0e1526"
              color="white"
              value="Cancel Plan"
              onClick={() => setModal(true)}
            />
          )}
          <CancelPlanModal
            modal={modal}
            setModal={setModal}
            title="Are you sure?"
          />
        </span>
      </div>
      <div>
        <Tabs
          className="setting-links"
          defaultActiveKey="1"
          activeKey={tabKey}
          onChange={(e) => setTabKey(e)}
        >
          <TabPane tab="General" key="1">
            <GeneralSetting />
          </TabPane>
          <TabPane tab="Plan and Packages" key="2">
            <Plans />
          </TabPane>
          <TabPane tab="Payment History" key="3">
            <PaymentSetting />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
