import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import "./scss/index.scss";

export const TextInput = (props) => {
  return (
    <>
      <div>
        <Input
          defaultValue={props.defaultValue}
          id={props.id}
          addonBefore={props.addonBefore}
          prefix={props.prefix}
          size={props.size}
          placeholder={props.placeholder}
          type={props.type}
          className="input"
          onChange={props.onChange}
          maxLength={props.maxLength}
          autoComplete={"false"}
          autoFocus={false}
          bordered={false}
          enterbutton="true"
          value={props.value}
          disabled={props.disabled}
        />
      </div>
    </>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.array,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  addonBefore: PropTypes.string,
  disabled: PropTypes.any,
  size: PropTypes.any,
  prefix: PropTypes.any,
  id: PropTypes.any,
};
