import React, { useEffect, useState } from "react";
import "./scss/index.scss";
import { Btn } from "../../components";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Divider, Carousel, Row, Col, Spin, Avatar, Tooltip } from "antd";

import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  UserOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";
import {
  brain,
  raferralLinkCopy,
  // card1,
  // card2,
  // card3,
  slide1,
  slide2,
  slide3,
  slide4,
} from "../../assests/images/";
import { UserProfile } from "../../components/user-profile";
import { useDispatch, useSelector } from "react-redux";
import { sharedCardData } from "../../store/common/sharedData";
import { postService } from "../../services";
import { authActions } from "../../store/actions";
// import ReactApexChart from "react-apexcharts";

export const DashBoard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  // const series = [
  //   {
  //     name: "XYZ MOTORS",
  //     // data: dates,
  //   },
  // ];
  // const options = {
  //   chart: {
  //     type: "area",
  //     stacked: false,
  //     height: 20,

  //     toolbar: {
  //       autoSelected: "zoom",
  //     },
  //     offsetX: 10,
  //     offsetY: 3,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   markers: {
  //     size: 0,
  //   },

  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.5,
  //       opacityTo: 0,
  //       stops: [0, 90, 100],
  //     },
  //   },
  //   yaxis: {
  //     labels: {
  //       formatter: function (val) {
  //         return (val / 1000000).toFixed(0);
  //       },
  //     },
  //     title: {
  //       text: "Price",
  //     },
  //   },
  //   xaxis: {
  //     type: "datetime",
  //   },
  //   tooltip: {
  //     shared: false,
  //     y: {
  //       formatter: function (val) {
  //         return (val / 1000000).toFixed(0);
  //       },
  //     },
  //   },
  // };

  // const titles = [
  //   {
  //     question: "Have you ever been trained by a Jedi Master?",
  //     paragraph:
  //       "Here’s your chance. 1-on-1 coaching and consulting with Travis Chambers!",
  //     bg: card1,
  //     btnText: "Book Travis today",
  //     url: "https://www.travischambers.com/",
  //   },
  //   {
  //     question: "Want custom professional grade product videos?",
  //     paragraph:
  //       "You are 1 Video away from TAKE OFF! Check out One In The Chamber.",
  //     bg: card2,
  //     btnText: "Start Now",
  //     url: "https://www.chamber.media",
  //   },
  //   {
  //     question: "Need Concierge Ad Management Service?",
  //     paragraph:
  //       "Our world class ad buyers can scale your campaigns & grow your business.",
  //     bg: card3,
  //     btnText: "Let us take the reins",
  //     url: "https://www.chamber.media/ad-buying/",
  //   },
  //   {
  //     question:
  //       "Companies who are spending over 10k in ad spend or with funding. Want to know how?",
  //     paragraph:
  //       "With lengths of over two minutes, these videos run at the top of funnel and have extreme longevity.",
  //     bg: card3,
  //     btnText: "Schedule a Call",
  //     url: "https://www.chamber.media/contact-us/",
  //   },
  // ];
  const [favouriteCards, setFavouriteCards] = useState([]);
  const [isShowing, setIsShowing] = useState(false);

  const [load, setLoad] = useState(false);
  const removeFavourite = async (index) => {
    const data = { template: favouriteCards[index].titles };
    const rm = favouriteCards.filter((val, i) => index !== i);
    setFavouriteCards(rm);
    const res = await postService.removeFromFavTemplate(data);
    const u = JSON.parse(localStorage.getItem("auth"));
    dispatch(authActions.getUser(u?.user?._id, "_"));
    return res;
  };

  useEffect(() => {
    setLoad(true);
    if (!user?.favTemplates?.length) return;
    const favs = user?.favTemplates.reduce((acc, current) => {
      const image = sharedCardData.find((e) => e.heading === current);
      if (image) {
        acc.push({
          titles: current,
          img: image.img,
          description: image.text,
          type: image.type,
        });
      }
      return acc;
    }, []);
    setFavouriteCards(favs);
    setLoad(false);
  }, [user?.favTemplates]);

  const handlegenerateLink = () => {
    const link =
      window.location.origin +
      "/signup" +
      "?name=" +
      user?.username +
      "&ref=" +
      user?._id;
    console.log(link);
    //copy to clipboard
    navigator.clipboard.writeText(link);
    setIsShowing(true);
    setTimeout(() => {
      setIsShowing(false);
    }, 2000);
  };

  return (
    <div className="container mx-auto p-3">
      <div className="flex flex-wrap justify-between items-center pb-5 pt-5 flex-wrap xl:flex-nowrap ">
        <UserProfile
          title={`Hey there ${user?.fullname}, 👋`}
          subtitle="What are you generating today?"
        />
        <div className="brain rounded-lg">
          <div className="p-1">
            {user?.planType === "agency" ? (
              <Btn
                value="Enter The Brain"
                bgColor="#f0f2f5"
                color="black"
                border="8px"
                onClick={() => {
                  props.setRenderBrain(true);
                  window.location.href = "#";
                }}
                cards
                icon={<img src={brain} width="20px" />}
              />
            ) : (
              <Btn
                value="Upgrade to agency plan to use our AI Brain"
                bgColor="#f0f2f5"
                color="black"
                border="8px"
                onClick={() =>
                  navigate("/unicorncopy/userpanel/settings?packages=true")
                }
                cards
                icon={<img src={brain} width="20px" />}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center pb-5 pt-5 flex-wrap xl:flex-nowrap">
        <div className="container rounded-sm">
          <Row>
            {/* <Col xs={24} lg={{ span: 6, push: 18 }}>
              {titles.map((v, i) => {
                return (
                  <div key={i} className="pb-2">
                    <div
                      className="p-8  text-center rightCol ml-2"
                      style={{
                        backgroundImage: `url(${v.bg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <h3>{v.question}</h3>
                      <p>{v.paragraph}</p>
                      <a href={v.url} target="_blank" rel="noreferrer">
                        <Btn
                          bgColor="rgba(96, 17, 74, 1)"
                          color="white"
                          value={v.btnText}
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </Col> */}
            <Col xs={24} lg={{ span: 24 }}>
              <Carousel
                arrows
                nextArrow={<RightOutlined />}
                prevArrow={<LeftOutlined />}
                autoplay
                autoplaySpeed={5000}
              >
                <div>
                  <h3
                    className="carousel"
                    style={{
                      backgroundImage: `url(${slide1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></h3>
                </div>
                <div>
                  <h3
                    className="carousel"
                    style={{
                      backgroundImage: `url(${slide2})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></h3>
                </div>
                {/* <div className="carousel">
                  <img src={slide2} />
                </div> */}
                <div>
                  <h3
                    className="carousel"
                    style={{
                      backgroundImage: `url(${slide3})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></h3>
                </div>
                {/* <div className="carousel">
                  <img src={slide3} />
                </div> */}
                <div>
                  <h3
                    className="carousel"
                    style={{
                      backgroundImage: `url(${slide4})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></h3>
                </div>
                {/* <div className="carousel">
                  <img src={slide4} />
                </div> */}
              </Carousel>

              <div className="favorite-card-main">
                <div className="w-full">
                  <div className="items-center p-5">
                    <div className="child-heading">
                      <h2>Favorite Templates</h2>
                    </div>
                  </div>
                  <Divider className="divider" />
                  <div className="favorite-cards">
                    {load ? (
                      <div className="favorite-card flex justify-center items-center">
                        <h6 className="para">
                          <span>
                            <Link to="/unicorncopy/userpanel/templates">
                              Go find your top templates
                            </Link>
                          </span>
                          {" and hit the heart ♡ icon to add them here!"}
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-row flex-wrap ">
                          {favouriteCards ? (
                            favouriteCards.map((v, i) => (
                              <div
                                key={i}
                                className="favTemp text-center relative flex flex-col cursor-pointer items-center ml-5"
                              >
                                {console.log(v)}
                                <div
                                  onClick={() => removeFavourite(i)}
                                  className="absolute right-4 text-xs text-white bg-red-200 rounded-full pr-1 pl-1 hover:bg-red-700"
                                >
                                  X
                                </div>
                                <div
                                  onClick={() => {
                                    navigate(
                                      "/unicorncopy/userpanel/content-generation",
                                      {
                                        state: {
                                          heading: v.titles,
                                          image: v.img,
                                          description: v.description,
                                          type: v.type,
                                        },
                                      }
                                    );
                                  }}
                                  className="bg-gray-100 p-1 opacity-1 rounded-2xl hover:bg-gray-200 hover:shadow-sm"
                                >
                                  <img src={v.img} className="p-2 h-11 w-11" />
                                </div>
                                <div className="mt-1">
                                  <h6 className="w-24 text-center text-xs font-medium">
                                    {v.titles}
                                  </h6>
                                </div>
                              </div>
                            ))
                          ) : (
                            <Spin
                              size={18}
                              style={{
                                color: "#ffff",
                                display: "flex",
                                marginLeft: "50%",
                                paddingBottom: 20,
                              }}
                            />
                          )}
                          <div
                            onClick={() =>
                              navigate("/unicorncopy/userpanel/templates")
                            }
                            className="bg-gray-100 plusIcon  opacity-80 mb-14   rounded-2xl text-center ml-10 cursor-pointer"
                          >
                            <PlusOutlined className="plusIcon text-2xl   h-14 w-14 p-4" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Coin refral carded */}
              <div className="favorite-card-main mt-2">
                <div className="w-full p-5 ">
                  <h1>❤️ ❤️ ❤️</h1>
                  <h2>Give 100C - Get 100C</h2>
                  <p className="para pt-4">
                    Give 100 Coins and Get 100 Coins for each new account that
                    signs p with your link.
                  </p>
                  <div style={{ width: "200px" }} className="button-copy">
                    <Tooltip
                      title="Link copied to clipboard!"
                      placement="right"
                      trigger="click"
                      visible={isShowing ? true : false}
                    >
                      <Btn
                        onClick={handlegenerateLink}
                        value="Copy referral link"
                        bgColor="transparent"
                        color="#5F636E"
                        border="1px solid #5F636E"
                        icon={<img src={raferralLinkCopy} width="20px" />}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Join community card section */}
              <div className="favorite-card-main mt-2">
                <div className="w-full p-5 ">
                  <Avatar.Group
                    maxCount={5}
                    maxPopoverTrigger="click"
                    size="large"
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      cursor: "pointer",
                    }}
                  >
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    <Avatar
                      style={{
                        backgroundColor: "#f56a00",
                      }}
                    >
                      A
                    </Avatar>
                    <Tooltip>
                      <Avatar
                        style={{
                          backgroundColor: "#87d068",
                        }}
                        icon={<UserOutlined />}
                      />
                    </Tooltip>
                    <Avatar
                      style={{
                        backgroundColor: "#1890ff",
                      }}
                      icon={<AntDesignOutlined />}
                    />
                  </Avatar.Group>
                  <h2 className="pt-2">Join the Facebook community</h2>
                  <p className="para pt-3">
                    Learn from over 6,000+ other experts leading the AI
                    copywriting and content creation space. We have users from
                    top companies like Meta, Google,Cocacola, Yahoo, Amazon, IBM
                    and more.
                  </p>
                  <div style={{ width: "200px" }} className="pt-3">
                    <a
                      href="https://www.facebook.com/groups/unicornadcreatives/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Btn
                        value="Open Facebook groups"
                        bgColor="linear-gradient(132deg, rgba(58,168,180,1) 1%, rgba(131,58,180,1) 32%, rgba(253,29,29,1) 62%, rgba(252,176,69,1) 100%)"
                        color="white"
                      />
                    </a>
                  </div>
                </div>
              </div>

              {/* Words generated section  */}
              {/* <div className="favorite-card-main mt-2">
                <div className="w-full">
                  <div className="items-center p-5">
                    <div className="child-heading">
                      <h2>Words generated</h2>
                    </div>
                  </div>
                  <Divider className="divider" />
                  <div className="mx-auto">
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={"170%"}
                      width={"96%"}
                    />
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
DashBoard.propTypes = {
  setRenderBrain: PropTypes.any,
};
