import React from "react";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Btn, Cards } from "../../components";
import { UserProfile } from "../../components/user-profile";
import {
  brain,
  facebookAd,
  facebookHeadline,
  facebookPrimeryText,
  linkedInAdCopy,
  googleAdCopy,
  googleAdHeadline,
  googleAdDescription,
  tiktokAdCopy,
  aida,
  problemAagitateSolution,
  featureToBenefit,
  engagingQues,
  perfectHeadline,
  persuasiveBulletPoint,
  uniqueValue,
  component270_29,
  dalle,
} from "../../assests/images/";
import { Col, Divider, Row } from "antd";
import { useSelector } from "react-redux";

const cardData = [
  {
    img: dalle,
    heading: "Dall-E",
    text: "Coming soon.",
    notActive: true,
    // type: "fb",
  },
  {
    img: facebookAd,
    heading: "Facebook Ad Copy",
    text: "Generate Ad Copy that can be used on Facebook.",
    type: "fb",
  },
  {
    img: linkedInAdCopy,
    heading: "LinkedIn Ad Copy",
    text: "Generate Ad Copy that can be used on LinkedIn.",
    type: "ld",
  },
  {
    img: googleAdCopy,
    heading: "Google Ad Copy",
    text: "Generate Ad Copy that can be used on Google Search and google Ads.",
    type: "google",
  },
  {
    img: tiktokAdCopy,
    heading: "TikTok Ad Copy",
    text: "Generate Ad Copy that can be used on TikTok.",
    type: "tiktok_ad_copy",
  },
  {
    img: facebookPrimeryText,
    heading: "Facebook Ad Primary Text",
    text: "Create high converting copy for the 'Primary Text' section of your Facebook ads.",
    type: "fb_primary",
  },
  {
    img: facebookHeadline,
    heading: "Facebook Ad Headline",
    text: "Generate scroll-stopping headlines for your Facebook Ads to get prospects to click, and ultimately buy.",
    type: "fb_headline",
  },
  {
    img: googleAdHeadline,
    heading: "Google Ads Headline",
    text: "Create high converting copy for the 'Headlines' section of your Google Ads.",
    type: "google_headline",
  },
  {
    img: perfectHeadline,

    heading: "Perfect Headline",
    text: "Trained with formulas from the world's best copywriters, this template is sure to create high-converting headlines for your business.",
    type: "perfect_headline",
  },
  {
    img: component270_29,

    heading: "Website Sub-Headline",
    text: "Create delightfully informative sub-headlines (H2) for your websites and landing pages.",
    type: "website_sub_headline",
  },
  // {
  //   img: SEOTilteAndMetaDescription,

  //   heading: "SEO - Title and Meta Descriptions",
  //   text: "Write SEO optimized title tags and meta descriptions that will rank well on Google.",
  //   type: "seo_title_meta",
  // },
  {
    img: googleAdDescription,

    heading: "Google Ads Description",
    text: "Create high converting copy for the 'Description' section of your Google Ads.",
    type: "google_description",
  },
  // {
  //   img: VideoDescription,

  //   heading: "Video Description",
  //   text: "Create unique descriptions for Youtube videos that rank well in search.",
  // },
  // {
  //   img: ProductDescription,

  //   heading: "Product description",
  //   text: "Create compelling product descriptions to be used on websites, emails and social media.",
  //   type: "product_description",
  // },
  // {
  //   img: AmazonProductFeatures,

  //   heading: "Amazon Product Description (paragraph)",
  //   text: "Create compelling product descriptions for Amazon listings. The output is typically paragraph form, but the style will vary.",
  // },
  // {
  //   img: SEOBlog,

  //   heading: "SEO - Blog Posts - Title and Meta Descriptions",
  //   text: "Write SEO optimized title tags and meta descriptions for blog posts that will rank well on Google.",
  // },
  // {
  //   img: SEOMeta,

  //   heading: "SEO - Homepage - Title and Meta Descriptions",
  //   text: "Write SEO optimized title tags and meta descriptions for homepages that will rank well on Google.",
  // },
  // {
  //   img: SEOProductPage,

  //   heading: "SEO - Product Page - Title and Meta Descriptions",
  //   text: "Write SEO optimized title tags and meta descriptions that will rank well on Google for product pages.",
  // },
  // {
  //   img: SEOServicePage,

  //   heading: "SEO - Services Pages - Title and Meta Descriptions",
  //   text: "Write SEO optimized title tags and meta descriptions that will rank well on Google for company services pages.",
  // },
  // {
  //   img: PersonalAdBio,
  //   heading: "Personal Bio",
  //   text: "Write a creative personal bio that captures attention.",
  //   type: "personal_bio",
  // },
  // {
  //   img: CompanyAdBio,
  //   heading: "Company Bio",
  //   text: "Tell your company's story with a captivating bio.",
  //   type: "company_bio",
  // },

  {
    img: aida,

    heading: "AIDA Framework",
    text: "Use the oldest marketing framework in the world. Attention, Interest, Desire, Action.",
    type: "aida",
  },
  {
    img: problemAagitateSolution,

    heading: "Problem-Agitate-Solution (PAS) Framework",
    text: "A valuable framework for creating new marketing copy ideas.",
    type: "pas",
  },

  // {
  //   img: ContentImprover,

  //   heading: "Content improver",
  //   text: "Take a piece of content and rewrite it to make it more interesting, creative, and engaging.",
  //   type: "content_improver",
  // },
  // {
  //   img: CreativeStory,

  //   heading: "Creative Story",
  //   text: "Write deliciously creative stories to engage your readers.",
  //   type: "creative_story",
  // },
  // {
  //   img: BlogPostTopic,

  //   heading: "Blog Post Topic Ideas",
  //   text: "Brainstorm new blog post topics that will engage readers and rank well on Google.",
  //   type: "blog_topic",
  // },
  // {
  //   img: BlogPostOutline,

  //   heading: "Blog Post Outline",
  //   text: "Create lists and outlines for articles. Works best for 'Listicle' and 'How to' style blog posts or articles.",
  //   type: "blog_outlice",
  // },
  // {
  //   img: BlogPostIntro,

  //   heading: "Blog Post Intro Paragraph",
  //   text: "Blast through writers block by letting us write your opening paragraph for you.",
  // },
  {
    img: featureToBenefit,
    type: "ftb",
    heading: "Feature to Benefit",
    text: "Turn your product features into benefits that compel action.",
  },
  // {
  //   img: BlogPostIntro,

  //   heading: "Blog Post Conclusion Paragraph",
  //   text: "Wrap up your blog posts with an engaging conclusion paragraph.",
  // },
  // {
  //   img: SentenceExpander,

  //   heading: "Sentence Expander",
  //   text: "Expand a short sentence or a few words into a longer sentence that is creative, interesting, and engaging.",
  // },
  // {
  //   img: RealEstateListing,

  //   heading: "Real Estate Listing - Residential",
  //   text: "Creative captivating real estate listings that sell homes quickly.",
  // },
  // {
  //   img: AmazonProductFeatures,

  //   heading: "Amazon Product Features (bullets)",
  //   text: "Create key feature and benefit bullet points for Amazon listings under the 'about this item' section.",
  // },

  {
    img: engagingQues,
    type: "engaging_question",
    heading: "Engaging Questions",
    text: "Ask your audience creative questions to increase engagement.",
  },

  // {
  //   img: QuoraAnswers,

  //   heading: "Quora Answers",
  //   text: "Intelligent answers for tough questions.",
  // },

  // {
  //   img: Component270_30,

  //   heading: "Photo Post Captions",
  //   text: "Write catchy captions for your Instagram posts",
  // },
  // {
  //   img: Component270_31,

  //   heading: "Short Social Posts",
  //   text: "Generate short social posts under 140 characters.",
  // },
  // {
  //   img: Component270_32,

  //   heading: "Ridiculous Marketing Ideas",
  //   text: "A funny template that generates bad marketing ideas. Like throwing a metal ball at a car window.",
  // },
  // {
  //   img: MarketingAngles,

  //   heading: "Marketing Angles",
  //   text: "Brainstorm different angles to add vibrancy to your marketing.",
  // },
  {
    img: persuasiveBulletPoint,

    heading: "Persuasive bullet points",
    text: "Generate persuasive bullet points to insert into landing pages, emails, and more.",
    type: "persuasive_bullet_points",
  },
  // {
  //   img: VideoTopicIdeas,

  //   heading: "Video Topic Ideas",
  //   text: "Brainstorm new video topics that will engage viewers and rank well on YouTube.",
  // },
  // {
  //   img: EmailSubject,

  //   heading: "Email Subject Lines",
  //   text: "Write compelling email subject lines that get readers to open.",
  // },
  // {
  //   img: VideoScriptHook,

  //   heading: "Video Script Outline",
  //   text: "Create script outlines for your videos. Works best for 'Listicle' and 'How to' style videos.",
  // },
  // {
  //   img: VideoTitle,

  //   heading: "Video Titles",
  //   text: "Create engaging, click-worthy titles for your videos that will rank on Youtube.",
  // },
  // {
  //   img: VideoScriptHook,

  //   heading: "Video Script Hook and Introduction",
  //   text: "Create a video intro that will capture your viewers attention and compel them to watch all the way through.",
  // },
  // {
  //   img: ExplainItToChild,

  //   heading: "Explain It To A Child",
  //   text: "Rephrase text to a lower grade level make it more simple to understand and easier to read.",
  // },

  // {
  //   img: ReviewResponder,

  //   heading: "Review Responder",
  //   text: "Write responses to public customer reviews that are winsome, professional, and delightful.",
  // },

  {
    img: uniqueValue,
    type: "unique",
    heading: "Unique Value Propositions",
    text: "Create a clear statement that describes the benefit of your offer in a powerful way.",
  },
];
export const Templates = (props) => {
  const navigate = useNavigate();
  const {
    loggedInUser: { user },
  } = useSelector((e) => e.authentication);
  return (
    <div className="container mx-auto p-5">
      <div className="flex w-full flex-row items-center justify-between pb-5 pt-5 flex-wrap xl:flex-nowrap ">
        <UserProfile
          title="Here you can find 40+ types of copies! 🤩"
          subtitle="Click on the ❤️ to add any templates in your favorites"
        />
        <div className="brain rounded-lg">
          <div className="p-1">
            {user?.planType === "agency" ? (
              <Btn
                value="Enter The Brain"
                bgColor="#f0f2f5"
                color="black"
                border="8px"
                onClick={() => props.setRenderBrain(true)}
                cards
                icon={<img src={brain} width="20px" />}
              />
            ) : (
              <Btn
                value="Upgrade to agency plan to use our AI Brain"
                bgColor="#f0f2f5"
                color="black"
                border="8px"
                onClick={() =>
                  navigate("/unicorncopy/userpanel/settings?packages=true")
                }
                cards
                icon={<img src={brain} width="20px" />}
              />
            )}
          </div>
        </div>
      </div>
      <Divider />
      <h2 className="text-3xl">Templates</h2>
      <Row gutter={[12]}>
        {cardData.map((v, i) => {
          return (
            <Col key={i} lg={6} md={8} sm={12} xs={24}>
              <Cards
                className="ddd"
                notActive={v.notActive}
                onClick={() => {
                  if (!v?.type) return;
                  navigate("/unicorncopy/userpanel/content-generation", {
                    state: {
                      heading: v.heading,
                      image: v.img,
                      description: v.text,
                      type: v.type,
                    },
                  });
                }}
                img={v.img}
                heading={v.heading}
                text={v.text}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

Templates.propTypes = {
  setRenderBrain: PropTypes.any,
};
