import axios from "axios";
import { io } from "socket.io-client";
import { authActions } from "../store/actions";
/**
 * Configuration for all API calls
 */
export const API = axios.create({
  // baseURL: process.env.REACT_APP_URL,
  // baseURL: "https://project-copytool.herokuapp.com/",
  baseURL: "https://project-uc.herokuapp.com/",
  // baseURL: "http://localhost:7001/",
  // baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://localhost:7001/",
});
export const GPT3API = axios.create({
  baseURL: process.env.REACT_APP_GPT_BASE_URL,
});

// Authorization: 'Bearer ' + getToken(),
// "Content-Type": "multipart/form-data"
export const Headers = (token) => {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    };
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};
API.defaults.headers.post["Content-Type"] = "application/json";
API.defaults.headers.post["token"] = localStorage.getItem("token");
API.defaults.headers.get["token"] = localStorage.getItem("token");

GPT3API.defaults.headers.post["Content-Type"] = "application/json";
GPT3API.defaults.headers.post["token"] = localStorage.getItem("token");
GPT3API.defaults.headers.post["token"] = localStorage.getItem("token");
GPT3API.defaults.headers.get["x-organization"] =
  process.env.REACT_APP_GPT_HEADER_X_ORGANIZATION;
GPT3API.defaults.headers.post["x-organization"] =
  process.env.REACT_APP_GPT_HEADER_X_ORGANIZATION;
GPT3API.defaults.headers.common = {
  Authorization: `bearer ${localStorage.getItem("gpt3token")}`,
};
GPT3API.defaults.headers.common = {
  "X-Access-Key": process.env.REACT_APP_GPT_X_ACCESS_KEY,
};
/**
 * The below code runs for every endpoint request
 */
API.interceptors.request.use(
  (req) => {
    // const auth = localStorage.getItem("token");
    /**
     * If access_token available then send in endpoint request
     */
    // req.headers.Authorization = auth && auth.token ? `Bearer ${auth.token}` : null;
    return req;
  },
  (error) => Promise.reject(error)
);
GPT3API.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => Promise.reject(error)
);

// const socket = io("http://localhost:4001/");
const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/socket.io",
  transports: ["websocket"],
  secure: true,
});
//
export const emitLogin = (res) => {
  socket.on("connect", {
    reconnect: false,
  });

  socket.emit("login", res.data);
};
socket.on("verifyLogin", (data) => {
  let user = localStorage.getItem("auth");
  user = JSON.parse(user);
  if (data && user && data.user.email === user.user.email) {
    if (data.token !== user.token) {
      authActions.logout();
    }
  }
});

const growthPriceId = process.env.REACT_APP_GROWTH_PRICE_ID;
const growthProductId = process.env.REACT_APP_GROWTH_PRODUCT_ID;
const agencyPriceId = process.env.REACT_APP_AGENCY_PRICE_ID;
const agencyProductId = process.env.REACT_APP_AGENCY_PRODUCT_ID;
const powerPriceId = process.env.REACT_APP_POWER_PRICE_ID;
const powerProductId = process.env.REACT_APP_POWER_PRODUCT_ID;

export const stripe_id = {
  growthPriceId,
  growthProductId,
  agencyPriceId,
  agencyProductId,
  powerPriceId,
  powerProductId,
};
