import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { changer, textCopy } from "../../assests/images";

export default function FooterGenerations({
  title,
  changeIndex,
  noIndex,
  noIcon,
  noInput,
}) {
  const headline = noIndex
    ? title.text
    : // this is the rest of them
      title.text[title.index];
  const [data, setData] = useState(headline);

  useEffect(() => {
    setData(headline);
  }, [headline]);

  const handleCopy = () => {
    navigator.clipboard.writeText(data).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  // no index for those having no index to change to
  return (
    <div style={{ width: "100%", overflow: "hidden", display: "flex" }}>
      <div style={{ color: "#222222", width: "80%" }}>
        {!noInput ? (
          <textarea
            onChange={(e) => {
              setData(e.target.value);
              title.text[title.index] = e.target.value;
            }}
            value={data}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {data}
          </textarea>
        ) : (
          <div style={{ color: "#222222", textAlign: "left" }}>{headline}</div>
        )}
      </div>
      {!noIcon && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "20%",
          }}
        >
          {!noIndex ? (
            <img
              onClick={changeIndex}
              src={changer}
              alt="Lock"
              width="15px"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <></>
          )}

          <img
            onClick={handleCopy}
            src={textCopy}
            alt="Copy"
            width="15px"
            style={{ cursor: "pointer" }}
            // onClick={changeStatus}
          />
        </div>
      )}
    </div>
  );
}
FooterGenerations.propTypes = {
  title: propTypes.any,
  changeIndex: propTypes.any,
  noIndex: propTypes.any,
  noIcon: propTypes.any,
  noInput: propTypes.any,
};
