import { API, Headers } from "../config/api";

const addToShowCase = (data) =>
  API.post("addToShowCase", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const get = (data) =>
  API.get("getShowcase", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const search = (data) =>
  API.post("searchShowCase", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const like = (data) =>
  API.post("handleLIke", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const addComment = (data) =>
  API.post("addComment", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getCommentById = (data) =>
  API.post("getCommentById", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

export const showcaseService = {
  addToShowCase,
  search,
  get,
  like,
  addComment,
  getCommentById,
};
