import React, { useEffect, useState } from "react";
import { TextInput, Btn } from "../../../components/forms";
import { Link } from "react-router-dom";
import "./scss/index.scss";
import { signInSchema } from "../../../Validations/UserValidation";
import { useDispatch } from "react-redux";
import { authActions, modalAction } from "../../../store/actions";
import { Navbar } from "../../../components";
import { Spin } from "antd";
import { authService } from "../../../services";
import { products } from "../../packages";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback } from "react";
import { EyeFilled, EyeInvisibleOutlined } from "@ant-design/icons";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const [initiateLoading, setInitateLoading] = useState(true);
  const url = new URL(window.location.href);
  const value = url.searchParams.get("redirectTo");
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("dynamicAction");
    console.log(" saved!");
    localStorage.setItem("recaptcha", token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  const createCheckoutSessionGrowth = async () => {
    try {
      const formData = {
        priceId: products[0].priceId,
        prod_id: products[0].id,
        type: "growth",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionPower = async () => {
    try {
      const formData = {
        priceId: products[1].priceId,
        prod_id: products[1].id,
        type: "power",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createCheckoutSessionAgency = async () => {
    try {
      const formData = {
        priceId: products[2].priceId,
        prod_id: products[2].id,
        type: "agency",
      };
      const res = await authService.createCheckoutSession(formData);

      if (res?.data) {
        window.location.href = res?.data;
      }
      if (res.redirected) {
        window.location.href = res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleInitiate = () => {
    if (!value) {
      setInitateLoading(false);
      return;
    }
    if (!localStorage.getItem("token")) {
      setInitateLoading(false);
      return;
    }
    if (value === "growth") {
      createCheckoutSessionGrowth();
      return;
    }
    if (value === "power") {
      createCheckoutSessionPower();
      return;
    }
    if (value === "agency") {
      createCheckoutSessionAgency();
      return;
    }
  };

  useEffect(() => {
    handleInitiate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignIn = async () => {
    const formData = {
      email,
      password,
    };

    if (!email) {
      dispatch(
        modalAction.error({
          title: "Email is a Required Field",
        })
      );
    } else if (!password) {
      dispatch(
        modalAction.error({
          title: "Password is a Required Field",
          show: false,
        })
      );
    } else if (email && password) {
      try {
        const isValid = await signInSchema.validate(formData);

        if (!isValid) return;
        setLoad(true);
        dispatch(
          authActions.login(formData.email, formData.password, false, setLoad)
        );
      } catch (err) {
        if (err.name === "ValidationError") {
          console.log(err);
          dispatch(
            modalAction.error({
              title: err.errors,
            })
          );
        }
      }
    }
  };

  if (initiateLoading) {
    return (
      <Spin className="ant-desigin-ns" size={18} style={{ color: "#0e0d29" }} />
    );
  }

  return (
    <>
      <Navbar signout={true} />
      <div className="flex flex-col justify-center items-center h-full xs:overflow-hidden max-width screen-h ">
        <div className="card p-10">
          <h1>Sign in to your account</h1>
          <div className="w-full pb-2 pt-2">
            <label htmlFor="email">Email address</label>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="w-full pb-2 pt-2 relative">
            <label htmlFor="passowrd">Password</label>
            {showPassword ? (
              <div
                onMouseUp={() => setShowPassword(false)}
                className="absolute w-5  z-10 right-1  cursor-pointer m-1.5"
              >
                <EyeFilled
                  style={{ fontSize: "16px", color: "#60114a" }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <div
                onClick={() => setShowPassword((e) => !e)}
                className="absolute w-5 z-10  right-1 cursor-pointer m-1.5"
              >
                <EyeInvisibleOutlined
                  className="cursor-pointer"
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
            <TextInput
              name="input"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="pb-2 pt-2">
            <Link to="/forget-password" className="forget_pass_link signInBtn">
              Forget your Password?
            </Link>
            <Btn
              onClick={handleSignIn}
              value="Continue"
              bgColor="#1e2a3b"
              color="#ffffff"
              load={load}
            />
          </div>
          <div className="text-left">
            Don&apos;t have an account yet?
            <Link to="/signup" className="pl-1 signInBtn">
              Get started here
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
