import { LogoutOutlined } from "@ant-design/icons";
import React from "react";
import { logo, logoName } from "../assests/images";
import { Btn } from "./forms";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/actions";

export const Navbar = ({ signout }) => {
  const navigation = useNavigate();

  return (
    <div className="flex items-center justify-between pr-5 pl-5 bg-white">
      <div className="flex items-center">
        <img
          onClick={() => signout && navigation("/signup", { replace: true })}
          src={logo}
          alt="Logo"
          width="70px"
        />
        <img
          onClick={() => signout && navigation("/signup", { replace: true })}
          src={logoName}
          alt="Logo"
          width="150px"
        />
      </div>
      <div>
        {!signout && (
          <Btn
            onClick={authActions.logout}
            value="Signout"
            bgColor="#7d21be"
            icon={<LogoutOutlined />}
            color="#ffffff"
          />
        )}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  signout: PropTypes.bool,
};
