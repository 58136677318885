import React, { useState } from "react";
import { AdHeader } from "../post-components/header";
import PropTypes from "prop-types";
import { BodyAdGoogle } from "./BodyAdGoogle";
import postAction from "../../../store/actions/post.actions";
import { useDispatch, useSelector } from "react-redux";
import Bubble from "../../../modules/Buble";

export const GooglePostAd = ({
  google,
  state,
  logo,
  handleShowCase,
  showCase,
  view,
}) => {
  console.log("type ==> ", state);

  const [score, setScore] = useState(0);
  const [, setCopyText] = useState(false);
  const post = useSelector((e) => e.post);
  const dispatch = useDispatch();
  const handleRemix = () => {
    const { copyIndex } = post;
    if (copyIndex > 1) {
      dispatch(postAction.resetCopyIndex());
    } else {
      dispatch(postAction.incrementIndex());
    }
  };
  return (
    <div
      style={{ boxShadow: "0px 1px 3px rgb(0 0 0 / 20%)" }}
      className="w-100 border-2 mx-auto container bg-white rounded-lg"
    >
      <AdHeader
        logo={logo}
        google={google}
        title={state?.productName}
        state={state}
        score={score}
        handleRemix={handleRemix}
        handleShowCase={handleShowCase}
        showCase={showCase}
        view={view}
      />
      <BodyAdGoogle
        state={state}
        d={state.google}
        googleBody={state.googleBody}
        setScore={setScore}
        setCopyText={setCopyText}
      />
      {showCase && <Bubble audience={state?.showcase?.audience} />}
    </div>
  );
};

GooglePostAd.propTypes = {
  google: PropTypes.any,
  state: PropTypes.object,
  logo: PropTypes.any,
  handleShowCase: PropTypes.func,
  showCase: PropTypes.any,
  view: PropTypes.any,
};
