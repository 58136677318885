import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  ForgetPassword,
  SignUp,
  Tutorials,
  Packages,
  Payment,
  ChangePassword,
  VerifyEmail,
  SigninRecaptcha,
} from "../modules";
import { ApplicationLayout } from "./index";
import { PrivateRoute } from "../components/private-route.js";
export const MainRouting = () => {
  const initCrisp = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "fd9d00bb-1eb0-43e3-b6a0-c505203dde1f";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  };
  useEffect(() => {
    initCrisp();
  }, []);
  return (
    <div className="w-full">
      <Routes>
        <Route path="/" element={<SigninRecaptcha />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/tutorial" element={<Tutorials />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/VerifyEmail" element={<VerifyEmail />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/secure-payment" element={<Payment />} />
        <Route element={<PrivateRoute redirectTo={"/"} />}>
          <Route path="/unicorncopy/*" element={<ApplicationLayout />} />
        </Route>
      </Routes>
    </div>
  );
};
