import React, { useState } from "react";
import { showcaseService } from "../../../services";
import Comments from "./Comments";
import "./scss/index.scss";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { commentIcon, likeIcon, likeIconFilled } from "../../../assests/images";

export default function CommentNLikes({ commentState }) {
  const {
    setCurrentComment,
    _id,
    uid,
    liked,
    // like_doc,
    currentItems,
    index,
    setCurrentItems,
    likes,
    currentComment,
    commentsNumber,
  } = commentState;
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const handleComment = async () => {
    if (!setCurrentComment) return;
    setLoad(true);
    const res = await showcaseService.getCommentById({ showcase_id: _id });
    console.log(res);
    let d = res?.data?.comments;
    if (!d) return setLoad(false);
    setData(d);
    setCurrentComment(_id);
    setLoad(false);
  };
  const handleLIke = async () => {
    if (!_id) return;
    let temp = [...currentItems];
    if (liked) {
      temp[index].liked = false;
      temp[index].likes -= 1;
    } else {
      temp[index].liked = true;
      temp[index].likes += 1;
    }
    if (setCurrentItems) setCurrentItems(temp);
    const res = await showcaseService.like({
      _id,
      uid,
      liked,
      like_doc: currentItems[index].like_doc,
    });
    if (res?.data?.result?._id) {
      temp[index].like_doc = res?.data?.result?._id;
      setCurrentItems(temp);
    }
  };
  return (
    <div className="ml-3 mb-3">
      <div className="border-top-likes">
        <div className="flex items-center">
          <img
            className="cursor-pointer"
            src={currentItems[index].liked === true ? likeIconFilled : likeIcon}
            style={{
              width: "1rem",
            }}
            onClick={handleLIke}
          />
          <p className="ml-1 mt-4">{likes} Likes</p>
        </div>
        <div className="flex items-center">
          <p className="mr-2" style={{ marginBottom: 0 }}>
            {commentsNumber} Comments
          </p>
          <img
            className="cursor-pointer"
            src={commentIcon}
            style={{ width: "1rem" }}
            onClick={handleComment}
          />
        </div>
      </div>
      {load && (
        <div className="flex justify-center">
          <Spin size={18} className="ant-d-spin flex justify-center " />
        </div>
      )}
      {currentComment === _id && (
        <div>
          <Comments commentState={{ ...commentState, data, handleComment }} />
        </div>
      )}
    </div>
  );
}

CommentNLikes.propTypes = {
  commentState: PropTypes.any,
};
