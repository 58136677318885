import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextInput, Btn } from "../../../components";
import { authService } from "../../../services";
import { authActions, modalAction } from "../../../store/actions";
// import { Avatar } from "antd";

export const ProfileSetting = () => {
  const user = useSelector((e) => e.authentication);
  const dispatch = useDispatch();
  const [profileSetting, setProfileSetting] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadPassword, setLoadPassword] = useState(false);
  const [userData, setUserData] = useState({
    firstname: user?.loggedInUser?.user?.firstname,
    lastname: user?.loggedInUser?.user?.lastname,
    fullname: user?.loggedInUser?.user?.fullname,
    email: user?.loggedInUser?.user?.email,
    phone: user?.loggedInUser?.user?.phone,
    password: "",
    oldPassword: "",
    confirmPassword: "",
    username: user?.loggedInUser?.user?.username,
  });
  const [, setValidate] = useState({
    fullname: "",
    lastNameError: "",
    userNameError: "",
    passwordError: "",
  });
  useEffect(() => {
    if (!user?.loggedInUser?.user?._id) {
      const u = JSON.parse(localStorage.getItem("auth"));

      dispatch(authActions.getUser(u.user?._id, "_"));
    }
  }, [user?.loggedInUser?.user?._id, dispatch]);
  useEffect(() => {
    setUserData({
      firstname: user?.loggedInUser?.user?.firstname,
      lastname: user?.loggedInUser?.user?.lastname,
      fullname: user?.loggedInUser?.user?.fullname,
      email: user?.loggedInUser?.user?.email,
      phone: user?.loggedInUser?.user?.phone,
      password: "",
      oldPassword: "",
      confirmPassword: "",
      username: user?.loggedInUser?.user?.username,
    });
  }, [user]);
  const updateUserProfile = async () => {
    if (!userData.fullname) {
      // dispatch(modalAction.show({ title: "Required first name", warn: true }));
      setValidate((oldState) => ({
        ...oldState,
        firstNameError: "Empty first name",
      }));
      return;
    } else {
      setValidate((oldState) => ({
        ...oldState,
        firstNameError: "",
      }));
    }
    // if (!userData.lastname) {
    //   // dispatch(modalAction.show({ title: "Required last name", warn: true }));
    //   setValidate((oldState) => ({
    //     ...oldState,
    //     lastNameError: "Empty last name",
    //   }));
    //   return;
    // } else {
    //   setValidate((oldState) => ({
    //     ...oldState,
    //     lastNameError: "",
    //   }));
    // }
    if (!userData.username) {
      // dispatch(modalAction.show({ title: "Required User name", warn: true }));
      setValidate((oldState) => ({
        ...oldState,
        userNameError: "Empty User name",
      }));
      return;
    } else {
      setValidate((oldState) => ({
        ...oldState,
        userNameError: "",
      }));
    }

    var data = {
      id: user?.loggedInUser?.user?._id,
      fullname: userData?.fullname,
      username: userData?.username,
      phone: userData?.phone,
      firstname: userData?.firstname,
      lastname: userData?.lastname,
    };

    if (user.changePwd) data.password = user.password;

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    setLoad(true);
    const res = await authService.updateUser(
      user?.loggedInUser?.user?._id,
      formData
    );

    if (res.data?.msg === "User Updated Successfully") {
      setLoad(false);
      dispatch(authActions.getUser(data.id, "_"));
      dispatch(
        modalAction.success({
          title: res.data?.msg,
        })
      );
    } else {
      setLoad(false);
      dispatch(
        modalAction.error({
          title: "Something went Wrong",
        })
      );
    }
  };
  const updatePassword = async () => {
    if (!userData.password) {
      // dispatch(modalAction.show({ title: "Required first name", warn: true }));
      setValidate((oldState) => ({
        ...oldState,
        passwordError: "Empty passowrd",
      }));
      return;
    } else {
      setValidate((oldState) => ({
        ...oldState,
        firstNameError: "",
      }));
    }

    var data = {
      id: user?.loggedInUser?.user?._id,
      password: userData?.password.length ? userData?.password : undefined,
    };
    var dataOldPassword = {
      email: userData?.email,
      password: userData?.oldPassword.length
        ? userData?.oldPassword
        : undefined,
    };

    if (user.changePwd) data.password = user.password;

    const formDataOldPassword = new FormData();
    Object.keys(dataOldPassword).forEach((key) => {
      formDataOldPassword.append(key, dataOldPassword[key]);
    });
    setLoadPassword(true);
    const resp = await authService.verifyOldPassword(
      user?.loggedInUser?.user?._id,
      formDataOldPassword
    );

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    if (resp.data?.msg === "Password is valid") {
      if (userData.password.length >= 5) {
        if (userData.confirmPassword === userData.password) {
          const res = await authService.updateUser(
            user?.loggedInUser?.user?._id,
            formData
          );
          if (res.data?.msg === "User Updated Successfully") {
            setLoadPassword(false);
            dispatch(authActions.getUser(data.id, "_"));
            dispatch(
              modalAction.success({
                title: "Password Updated",
              })
            );
          } else {
            setLoadPassword(false);
            dispatch(
              modalAction.error({
                title: "Something went Wrong",
              })
            );
          }
        } else {
          setLoadPassword(false);
          dispatch(
            modalAction.warning({
              title: "Confirm password not matched",
            })
          );
        }
      } else {
        setLoadPassword(false);
        dispatch(
          modalAction.warning({
            title: "Password length should be greater than or equals to 5",
          })
        );
      }
    } else {
      setLoadPassword(false);
      dispatch(
        modalAction.warning({
          title: "Wrong Old Password",
        })
      );
    }
  };
  console.log(user);
  return (
    <div className="lg:flex flex-col justify-center items-center my-10 w-auto xs:overflow-hidden">
      <div className="settingCard p-10 rounded-lg">
        <div className="m-auto justify-center text-center">
          <div className="head">
            {profileSetting ? (
              <h1>Profile Settings</h1>
            ) : (
              <h1>Security Settings</h1>
            )}
            {profileSetting ? (
              <p>
                You can switch your account settings below.
                <br /> Feel free to have fun with it!
              </p>
            ) : (
              <p>You can change your account password below.</p>
            )}
          </div>
          <div className="">
            {/* <div className="flex justify-between  flex-wrap"> */}
            {profileSetting ? (
              <div className="">
                <h6 className="text-left">E-mail</h6>
                <TextInput
                  // onChange={(e) =>
                  //   setUserData((u) => ({ ...u, email: e.target.value }))
                  // }
                  value={userData.email}
                  placeholder="Your Email address"
                  disabled={true}
                />
                <h6 className="text-left mt-2">Full Name</h6>
                <TextInput
                  onChange={(e) =>
                    setUserData((u) => ({ ...u, fullname: e.target.value }))
                  }
                  value={userData.fullname}
                  placeholder="Edit your fullname"
                />
                <h6 className="text-left mt-2">Username</h6>
                <TextInput
                  onChange={(e) =>
                    setUserData((u) => ({ ...u, username: e.target.value }))
                  }
                  value={userData.username}
                  placeholder="Set your username"
                />
                <p
                  onClick={() => setProfileSetting(false)}
                  className="text-left text-purple-900 ml-1 cursor-pointer hover:underline mt-5"
                >
                  Change Passowrd
                </p>

                <div className="w-full flex justify-between items-center mt-4">
                  <div>
                    <Btn value="cancel" bgColor="#1e2a3b" color="white" />
                  </div>
                  <div>
                    <Btn
                      value="Update"
                      load={load}
                      bgColor="#1e2a3b"
                      color="white"
                      onClick={updateUserProfile}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="eye relative">
                <h6 className="text-left">Old Password</h6>
                {showOldPassword ? (
                  <div
                    onMouseUp={() => setShowOldPassword(false)}
                    className="absolute w-5  z-10 right-1  cursor-pointer m-2"
                  >
                    <EyeTwoTone
                      style={{ fontSize: "16px" }}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <div
                    onMouseDown={() => setShowOldPassword(true)}
                    className="absolute w-5 z-10  right-1 cursor-pointer m-2"
                  >
                    <EyeInvisibleOutlined
                      className="cursor-pointer"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                )}
                <TextInput
                  type={showOldPassword ? "text" : "password"}
                  onChange={(e) =>
                    setUserData((u) => ({ ...u, oldPassword: e.target.value }))
                  }
                  value={userData.oldPassword}
                  placeholder="Type Old Password"
                />

                <h6 className="text-left mt-2">New Password</h6>
                {showNewPassword ? (
                  <div
                    onMouseUp={() => setShowNewPassword(false)}
                    className="absolute w-5  z-10 right-1  cursor-pointer m-2"
                  >
                    <EyeTwoTone
                      style={{ fontSize: "16px" }}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <div
                    onMouseDown={() => setShowNewPassword(true)}
                    className="absolute w-5 z-10 right-1  cursor-pointer m-2"
                  >
                    <EyeInvisibleOutlined
                      className="cursor-pointer"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                )}
                <TextInput
                  type={showNewPassword ? "text" : "password"}
                  onChange={(e) =>
                    setUserData((u) => ({ ...u, password: e.target.value }))
                  }
                  value={userData.password}
                  placeholder="Type New Password"
                />
                <h6 className="text-left mt-2">Confirm Password</h6>
                {showConfirmPassword ? (
                  <div
                    onMouseUp={() => setShowConfirmPassword(false)}
                    className="absolute w-5  z-10 right-1  cursor-pointer m-2"
                  >
                    <EyeTwoTone
                      style={{ fontSize: "16px" }}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <div
                    onMouseDown={() => setShowConfirmPassword(true)}
                    className="absolute w-5 z-10 right-1  cursor-pointer m-2"
                  >
                    <EyeInvisibleOutlined
                      className="cursor-pointer"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                )}
                <TextInput
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) =>
                    setUserData((u) => ({
                      ...u,
                      confirmPassword: e.target.value,
                    }))
                  }
                  value={userData.confirmPassword}
                  placeholder="Confirm New Password"
                />
                <p
                  onClick={() => setProfileSetting(true)}
                  className="text-left text-purple-900 ml-1 cursor-pointer hover:underline mt-5"
                >
                  Go Back To Profile Setting
                </p>
                <div className="w-full flex  items-center mt-4">
                  <div>
                    <Btn
                      value="Update Password"
                      load={loadPassword}
                      bgColor="#1e2a3b"
                      color="white"
                      onClick={updatePassword}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
