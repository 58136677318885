const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case "APPLY_REFINEMENTS":
      return {
        ...state,
        refinements: action.payload,
      };

    default:
      return state;
  }
};

export default filterReducer;
