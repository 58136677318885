import React from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import FooterGenerations from "../../../../modules/ad-footer";
import { renderToString } from "react-dom/server";
import {
  changer,
  textCopy,
  //  showcase
} from "../../../../assests/images";
import Views from "../View";

export const TiktokAdPrimary = ({
  primaryText,
  primaryImg,
  advanceObj,
  IndexChanger,
  onClick,
  // handleShowCase,
  showCase,
  view,
}) => {
  const sperateInputBySpecialCharacter = (string) => {
    if (!string || !string?.length) return string;
    var formattedString = string;
    formattedString = formattedString.split("! ").join(".<br />");
    formattedString = formattedString.split("? ").join(".<br />");
    formattedString = formattedString.split(".\n");

    formattedString = formattedString.join(".<br />");

    if (
      formattedString[formattedString.length - 1] !== "." &&
      formattedString[formattedString.length - 1] !== "!" &&
      formattedString[formattedString.length - 1] !== "?" &&
      formattedString[formattedString.length - 1] !== ">"
    ) {
      formattedString += ".";
    }

    return formattedString;
  };
  const FreeShipping = () =>
    renderToString(
      <>
        {!!advanceObj?.freeShipping?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableFreeShipping}
            noIcon={true}
            title={advanceObj.freeShipping}
          />
        )}
      </>
    );

  // const CTAUrl = () =>
  //   renderToString(
  //     <>
  //       {!!advanceObj?.ctaUrl?.text?.length && (
  //         <FooterGenerations
  //           noInput={true}
  //           changeStatus={IndexChanger.disableDiscount}
  //           noIcon={true}
  //           title={advanceObj?.ctaUrl}
  //         />
  //       )}
  //     </>
  //   );
  const Discount = () =>
    renderToString(
      <>
        {!!advanceObj?.discount?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableDiscount}
            noIcon={true}
            title={advanceObj?.discount}
          />
        )}
      </>
    );
  const Location = () =>
    renderToString(
      <>
        {!!advanceObj?.location?.text?.length && (
          <FooterGenerations
            changeStatus={IndexChanger.disableLocation}
            title={advanceObj?.location}
            noIcon={true}
          />
        )}
      </>
    );
  const structureHtml = (primaryText) => {
    // primart text here is array of strings
    if (primaryText?.length) {
      let result = primaryText.reduce((accumlator, currentValue) => {
        if (currentValue.pain || currentValue.claim || currentValue.gain) {
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.pain} <br />
            </p>`;
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.gain} <br />
          </p>`;
          accumlator += `<p className="mb-0 px-4">
            ${currentValue.claim} <br />
            </p>`;
        } else {
          accumlator += currentValue;
        }
        return accumlator;
      }, "");
      result += FreeShipping();
      result = Location() + result;
      result += Discount();
      return result;
    }
    return primaryText;
  };

  const generateEditableContent = () => {
    let content = sperateInputBySpecialCharacter(structureHtml(primaryText));
    return content;
  };
  return (
    <div
      className="ml-2"
      style={{
        color: "white",
        lineHeight: 1.5,
        fontSize: "1rem",
        width: "100%",
      }}
    >
      <div style={{ width: "90%", minWidth: "90%" }}>
        <ContentEditable
          html={generateEditableContent()} // innerHTML of the editable div
        />
      </div>
      <div className="side-panel-tiktok flex">
        {showCase && (
          <div
            className="cursor-pointer bg-gray-100 rounded-full p-2"
            onClick={onClick}
          >
            <img
              src={changer}
              className="cursor-pointer hover:shadow-sm rounded-md headerIcon min-wd-12 white"
              width={"14px"}
            />
          </div>
        )}
        {/* {!showCase && (
          <div
            className="cursor-pointer bg-gray-100 rounded-full p-2"
            onClick={handleShowCase}
          >
            <img
              src={showcase}
              style={{ width: "1.2rem" }}
              className="cursor-pointer hover:shadow-sm rounded-md headerIcon"
            />
          </div>
        )} */}
        <div className="cursor-pointer bg-gray-100 rounded-full p-2">
          <img
            src={textCopy}
            className="cursor-pointer rounded-md headerIcon min-wd-12"
            width={"14px"}
            // onClick={props.wirteClipboardHeadline}
          />
        </div>
        <span className="image-tiktok-container">
          <img className="image-side-tiktok" src={primaryImg} alt="primary" />
        </span>
        {showCase && (
          <span style={{ marginBottom: -10 }}>
            <Views view={view} />
          </span>
        )}
      </div>
    </div>
  );
};

TiktokAdPrimary.propTypes = {
  primaryText: PropTypes.any,
  primaryImg: PropTypes.any,
  type: PropTypes.any,
  imageHidden: PropTypes.Boolean,
  IndexChanger: PropTypes.any,
  advanceObj: PropTypes.any,
  onClick: PropTypes.any,
  handleShowCase: PropTypes.any,
  showCase: PropTypes.any,
  view: PropTypes.any,
};
