import React from "react";
import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import "./scss/index.scss";
import { changer, textCopy, showcase } from "../../../../assests/images";
import PropTypes from "prop-types";
import { Spin, Tooltip } from "antd";
import { useState } from "react";
export const AdHeader = ({
  title,
  logo,
  score,
  onClick,
  wirteClipboardDesc,
  handleShowCase,
  showCase,
  view,
  loadShowCase,
  // d,
}) => {
  const [isAddedtoShowCase, setIsAddedToShowcase] = useState(false);
  return (
    <div className="px-4 py-2  flex-wrap flex justify-between items-center">
      <div className="flex items-center">
        <div className="flex items-center p-1">
          {!logo ? (
            <UserOutlined />
          ) : (
            <img
              style={{ width: "40px", height: "37px", borderRadius: "25px" }}
              src={logo}
            />
          )}
        </div>
        <div
          className="leading-none ml-2 mt-3 w-40 text-left"
          style={{ width: "fit-content" }}
        >
          <p className="text-sm font-medium capitalize mb-4">{title}</p>
          <p className="-mt-4 opacity-60 text-xs">Sponsored</p>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="opacity-60">{showCase ? "Views" : "Ad Score"}</div>

        <div className="px-1">
          <div className="adscore text-white w-8 h-8 flex text-sm items-center bg-cover justify-center bg-center">
            {showCase ? view : score}
          </div>
        </div>
        {loadShowCase && (
          <div>
            <Spin size={18} className="ant-d-spin" />
          </div>
        )}
        {!showCase && !loadShowCase && (
          <>
            {!isAddedtoShowCase ? (
              <div
                title="Add to showcase"
                className="mx-1 cursor-pointer hover:bg-gray-100 rounded-full p-2"
                onClick={() => {
                  handleShowCase();
                  setIsAddedToShowcase(true);
                }}
                style={{ color: "purple" }}
              >
                <Tooltip title="Add to showcase">
                  <img
                    src={showcase}
                    style={{ width: "1.5rem" }}
                    className="cursor-pointer hover:shadow-sm rounded-md headerIcon"
                  />
                </Tooltip>
              </div>
            ) : (
              <div
                title="Add to showcase"
                className="mx-1 cursor-pointer hover:bg-gray-100 rounded-full p-2"
                style={{ color: "purple" }}
              >
                <Tooltip title="Added to showcase">
                  <CheckOutlined
                    style={{ width: "1.5rem" }}
                    className="cursor-pointer hover:shadow-sm rounded-md headerIcon"
                  />
                </Tooltip>
              </div>
            )}
          </>
        )}
        {!showCase && false && (
          <div
            className="mx-1 cursor-pointer hover:bg-gray-100 rounded-full p-2"
            onClick={onClick}
          >
            <img
              src={changer}
              style={{ width: "1rem" }}
              className="cursor-pointer hover:shadow-sm rounded-md headerIcon"
            />
          </div>
        )}

        <div
          className=" mx-1 cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={wirteClipboardDesc}
        >
          <img
            src={textCopy}
            className="cursor-pointer hover:shadow-sm headerIcon"
            style={{ width: "1rem" }}
          />
        </div>
      </div>
    </div>
  );
};

AdHeader.propTypes = {
  title: PropTypes.any,
  logo: PropTypes.any,
  score: PropTypes.any,
  onClick: PropTypes.any,
  google: PropTypes.any,
  handleRemix: PropTypes.any,
  wirteClipboardDesc: PropTypes.any,
  handleShowCase: PropTypes.func,
  showCase: PropTypes.bool,
  d: PropTypes.any,
  view: PropTypes.any,
  loadShowCase: PropTypes.bool,
};
