import React from "react";
// import { Btn } from "../../forms";
import {
  FacebookAdCopy,
  GooglePostAd,
  LinkedInAdCopy,
  MiscAdCoopy,
  TiktokAdCopy,
} from "../../posts";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";
import rs from "text-readability";
import sanitizeHtml from "sanitize-html";
// import postAction from "../../../store/actions/post.actions";
import ReactLoading from "react-loading";
import { PasAdCopy } from "../../posts/pas-ad-copy";
// import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
export const Content = ({
  cardData,
  state,
  headline,
  // index,
  setHeadlineIndex,
  onNext,
  // onPrevious,
  gpt3Loading,
  google,
  IndexChanger,
  data,
  advanceObj,
  handleShowCase,
  showCase,
  view,
  loadShowCase,
  t,
  commentState,
  key,
}) => {
  const location = useLocation();
  const type = t ? t : location?.state?.type;
  // const { maxIndex } = useSelector((e) => e.post);
  // const dispatch = useDispatch();
  const gernrateScore = (s) => {
    if (s) {
      const text = s.reduce((onlyText, text) => {
        return onlyText + text + " \n";
      }, "");
      let grade = rs.fleschKincaidGrade(text);
      let formula = grade * 10 + 5.1;
      let sc = Math.round(formula > 100 ? 100 : formula < 0 ? 0 : formula);
      if (sc < 80) sc = 80;
      return sc;
    }
    return 0;
  };
  const sanitizeConf = {
    allowedTags: [],
    allowedAttributes: {},
  };
  const wirteClipboardDesc = () => {
    const text = data.reduce((onlyText, text) => {
      return onlyText + text + " \n";
    }, "");
    let sanitized = sanitizeHtml(text, sanitizeConf);
    sanitized = sanitized.trim().replace(/^\s+|\s+$/gm, "");
    navigator.clipboard.writeText(sanitized).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const wirteClipboardHeadline = () => {
    let sanitized = sanitizeHtml(headline?.text[headline.index], sanitizeConf);
    sanitized = sanitized.trim().replace(/^\s+|\s+$/gm, "");
    navigator.clipboard.writeText(sanitized).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  // const handleGoogleNext = () => {
  //   if (index > 2) {
  //     dispatch(postAction.resetCopyIndex());
  //   } else {
  //     dispatch(postAction.incrementIndex());
  //   }
  // };

  // const handlePrevGoogle = () => {
  //   if (index > 0) {
  //     dispatch(postAction.decrementIndex());
  //   } else {
  //     dispatch(postAction.goto(2));
  //   }
  // };
  if (gpt3Loading && key === 0) {
    return (
      <div className="">
        <ReactLoading
          className="mx-auto my-44"
          type="bars"
          color="rgba(96, 17, 74, 1)"
          height={14}
          width={120}
          delay={1}
        />
      </div>
    );
  }
  return (
    <div>
      {/* facebook */}
      {(type === "fb" || type === "aida") && cardData?.length > 0 && (
        <div className="mt-10" id={key}>
          <div
            className={`cards cards ${
              showCase ? "mt-4" : "md:mx-4 sm:mx-4 lg:mx-20 xl-20"
            }`}
          >
            <FacebookAdCopy
              commentState={commentState}
              loadShowCase={loadShowCase}
              logo={state?.kalazify?.logo}
              text={state?.productName}
              headline={headline}
              primaryImg={google}
              primaryText={data}
              state={state}
              setHeadlineIndex={setHeadlineIndex}
              onClick={onNext}
              score={gernrateScore(data)}
              wirteClipboardDesc={wirteClipboardDesc}
              wirteClipboardHeadline={wirteClipboardHeadline}
              type={type}
              IndexChanger={IndexChanger}
              advanceObj={advanceObj}
              showCase={showCase}
              view={view}
              handleShowCase={() =>
                handleShowCase({
                  data,
                  logo: state?.kalazify?.logo,
                  headline,
                  google,
                  score: gernrateScore(data),
                  type,
                  audience: state?.audience,
                  input: state?.input,
                  name: state?.name,
                  url: state?.url,
                  template: type,
                  copy: data[0],
                })
              }
            />
            {/* {false && (
                <div className="next__prev">
                  <Btn
                    onClick={onPrevious}
                    disabled={index > 0 ? false : true}
                    value="previous"
                    bgColor="#0E1526"
                    color="white"
                    load={false}
                  />
                  <Btn
                    onClick={onNext}
                    disabled={index >= maxIndex ? true : false}
                    value="Next"
                    bgColor="#0E1526"
                    color="white"
                    load={false}
                  />
                </div>
              )} */}
          </div>
        </div>
      )}
      {type === "pas" && cardData?.length > 0 && (
        <div className="mt-10">
          <div
            className={`cards cards ${
              showCase ? "mt-4" : "md:mx-4 sm:mx-4 lg:mx-20 xl-20"
            }`}
          >
            <PasAdCopy
              logo={state?.kalazify?.logo}
              text={state?.productName}
              headline={headline}
              primaryImg={google}
              primaryText={data}
              state={state}
              setHeadlineIndex={setHeadlineIndex}
              onClick={onNext}
              score={gernrateScore(data)}
              wirteClipboardDesc={wirteClipboardDesc}
              wirteClipboardHeadline={wirteClipboardHeadline}
              type={type}
              loadShowCase={loadShowCase}
              view={view}
              showCase={showCase}
              handleShowCase={() =>
                handleShowCase({
                  data,
                  logo: state?.kalazify?.logo,
                  headline,
                  google,
                  score: gernrateScore(data),
                  type,
                  audience: state?.audience,
                  input: state?.input,
                  name: state?.name,
                  url: state?.url,
                  template: type,
                  copy: data[0],
                })
              }
            />
            {/* {false && (
                <div className="next__prev">
                  <Btn
                    onClick={onPrevious}
                    disabled={index > 0 ? false : true}
                    value="previous"
                    bgColor="#0E1526"
                    color="white"
                    load={false}
                  />
                  <Btn
                    onClick={onNext}
                    disabled={index >= maxIndex ? true : false}
                    value="Next"
                    bgColor="#0E1526"
                    color="white"
                    load={false}
                  />
                </div>
              )} */}
          </div>
        </div>
      )}
      {type === "ld" && cardData?.length > 0 && (
        <div className="mt-10">
          <div
            className={`cards cards ${
              showCase ? "mt-4" : "md:mx-4 sm:mx-4 lg:mx-20 xl-20"
            }`}
          >
            <LinkedInAdCopy
              logo={state?.kalazify?.logo}
              text={state?.productName}
              headline={headline}
              primaryImg={google}
              primaryText={data}
              state={state}
              loadShowCase={loadShowCase}
              view={view}
              showCase={showCase}
              setHeadlineIndex={setHeadlineIndex}
              onClick={onNext}
              score={gernrateScore(data)}
              wirteClipboardDesc={wirteClipboardDesc}
              wirteClipboardHeadline={wirteClipboardHeadline}
              type={type}
              IndexChanger={IndexChanger}
              commentState={commentState}
              advanceObj={advanceObj}
              handleShowCase={() =>
                handleShowCase({
                  data,
                  logo: state?.kalazify?.logo,
                  headline,
                  google,
                  score: gernrateScore(data),
                  type,
                  audience: state?.audience,
                  input: state?.input,
                  name: state?.name,
                  url: state?.url,
                  template: type,
                  copy: data[0],
                })
              }
            />
          </div>
        </div>
      )}
      {type === "tiktok_ad_copy" && cardData?.length > 0 && (
        <div className="mt-10">
          <div
            className={`cards cards ${
              showCase ? "mt-4" : "md:mx-4 sm:mx-4 lg:mx-20 xl-20"
            }`}
          >
            <TiktokAdCopy
              logo={state?.kalazify?.logo}
              text={state?.productName}
              headline={headline}
              primaryImg={google}
              primaryText={data}
              state={state}
              setHeadlineIndex={setHeadlineIndex}
              onClick={onNext}
              view={view}
              score={gernrateScore(data)}
              wirteClipboardDesc={wirteClipboardDesc}
              type={type}
              wirteClipboardHeadline={wirteClipboardHeadline}
              IndexChanger={IndexChanger}
              showCase={showCase}
              handleShowCase={() =>
                handleShowCase({
                  data,
                  logo: state?.kalazify?.logo,
                  headline,
                  google,
                  score: gernrateScore(data),
                  type,
                  audience: state?.audience,
                  input: state?.input,
                  name: state?.name,
                  url: state?.url,
                  template: type,
                  copy: data[0],
                })
              }
            />
          </div>
        </div>
      )}
      {(type === "fb_primary" ||
        type === "google_description" ||
        type === "perfect_headline" ||
        type === "website_sub_headline" ||
        type === "engaging_question" ||
        type === "persuasive_bullet_points" ||
        type === "unique" ||
        type === "ftb" ||
        type === "fb_headline" ||
        type === "website_sub_headline") &&
        (cardData?.length > 0 || state?.google?.length > 0) && (
          <div className="mt-10">
            <div
              className={`cards cards ${
                showCase ? "mt-4" : "md:mx-4 sm:mx-4 lg:mx-20 xl-20"
              }`}
            >
              <MiscAdCoopy
                logo={state?.kalazify?.logo}
                text={state?.productName}
                headline={headline}
                primaryImg={google}
                primaryText={data}
                state={state}
                setHeadlineIndex={setHeadlineIndex}
                onClick={onNext}
                score={gernrateScore(data)}
                wirteClipboardDesc={wirteClipboardDesc}
                wirteClipboardHeadline={wirteClipboardHeadline}
                type={type}
                IndexChanger={IndexChanger}
                google={google}
                showCase={showCase}
                view={view}
                commentState={commentState}
                handleShowCase={() =>
                  handleShowCase({
                    data,
                    logo: state?.kalazify?.logo,
                    headline,
                    google,
                    score: gernrateScore(data),
                    type,
                    audience: state?.audience,
                    input: state?.input,
                    name: state?.name,
                    url: state?.url,
                    template: type,
                    copy: data[0],
                  })
                }
              />
              {/* <div className="next__prev">
                <Btn
                  onClick={onPrevious}
                  disabled={index > 0 ? false : true}
                  value="previous"
                  bgColor="#0E1526"
                  color="white"
                  load={false}
                />
                <Btn
                  onClick={onNext}
                  disabled={index >= maxIndex ? true : false}
                  value="Next"
                  bgColor="#0E1526"
                  color="white"
                  load={false}
                />
              </div> */}
            </div>
          </div>
        )}
      {/* facebook ends */}

      {/* google starts */}
      {(type === "google" || type === "google_headline") &&
        !!state?.google?.length && (
          <div className="mt-10">
            <div
              className={`cards cards ${
                showCase ? "mt-4" : "md:mx-12 sm:mx-12 lg:mx-20 xl-20"
              }`}
            >
              <GooglePostAd
                logo={state?.kalazify?.logo}
                state={state}
                google={google}
                IndexChanger={IndexChanger}
                showCase={showCase}
                view={view}
                handleShowCase={() =>
                  handleShowCase({
                    data,
                    logo: state?.kalazify?.logo,
                    headline,
                    google,
                    score: gernrateScore(data),
                    type,
                    audience: state?.audience,
                    input: state?.input,
                    name: state?.name,
                    url: state?.url,
                    template: type,
                    copy: data[0],
                    googleCopy: state?.google,
                    googleBody: state.googleBody,
                  })
                }
              />
            </div>
          </div>
        )}
      {/* google Ends */}
    </div>
  );
};

Content.propTypes = {
  cardData: PropTypes.any,
  state: PropTypes.any,
  headline: PropTypes.any,
  index: PropTypes.any,
  setHeadlineIndex: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  gpt3Loading: PropTypes.any,
  google: PropTypes.any,
  data: PropTypes.any,
  IndexChanger: PropTypes.any,
  advanceObj: PropTypes.any,
  handleShowCase: PropTypes.func,
  t: PropTypes.any,
  showCase: PropTypes.bool,
  view: PropTypes.number,
  loadShowCase: PropTypes.bool,
  commentState: PropTypes.any,
  key: PropTypes.number,
};
