import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SignIn } from "../signin";

export const SigninRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <SignIn />
    </GoogleReCaptchaProvider>
  );
};
