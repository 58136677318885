import { CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

export const Points = ({ point }) => {
  return (
    <div className="flex items-center justify-start p-2">
      <CheckOutlined
        style={{ fontSize: "18px", color: "#7d21be", paddingRight: "8px" }}
      />
      <p>{point}</p>
    </div>
  );
};

Points.propTypes = {
  point: PropTypes.any,
};
