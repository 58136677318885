import React from "react";
import "./scss/index.scss";
import { ArrowDownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

export const BrainCard = (props) => {
  // console.log(props.perc);
  return (
    <div className="topCard p-3 flex flex-col justify-between">
      <div className="flex justify-between items-center">
        <div>
          <p className="headingPara mb-0">{props.heading}</p>
          <p className="amountPara mb-0">{props.amount}</p>
        </div>
        <div
          className=" rounded-full w-14 h-14 flex justify-center items-center "
          style={{ backgroundColor: `${props.color}` }}
        >
          {props.roundIcon}
        </div>
      </div>
      {!props.profit ? (
        !props.progress ? (
          <div className="w-full flex">
            <div className="flex items-center">
              <span style={{ transform: props?.perc > 0 && "rotate(180deg)" }}>
                <ArrowDownOutlined
                  style={{ color: `${props.color}`, fontSize: "20px" }}
                />
              </span>
              <p
                className="text-base m-0"
                style={{ color: `${props.color}` }}
              >{`${props.perc}%`}</p>
            </div>
            <p className="white text-base m-0 ml-2">{props.bottomText}</p>
          </div>
        ) : (
          <div className="progressDiv w-full">
            <div
              className="nestedProgressDiv h-full"
              style={{
                width: props.amount,
                backgroundColor: `${props.color}`,
                borderRadius: "5px",
              }}
            ></div>
          </div>
        )
      ) : null}
    </div>
  );
};

BrainCard.propTypes = {
  heading: PropTypes.any,
  amount: PropTypes.any,
  perc: PropTypes.any,
  bottomText: PropTypes.any,
  roundIcon: PropTypes.any,
  color: PropTypes.any,
  progress: PropTypes.any,
  profit: PropTypes.any,
};
