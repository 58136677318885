import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableContentGoogle from "./EditableContentGoogle";
import rs from "text-readability";
import postAction from "../../../store/actions/post.actions";
import PropTypes from "prop-types";

export const BodyAdGoogle = ({
  d,
  setScore,
  refresh,
  setCopyText,
  googleBody,
}) => {
  //
  const post = useSelector((e) => e.post);
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (post.copyIndex > 2) {
      dispatch(postAction.resetCopyIndex());
    }
  }, [dispatch, post.copyIndex]);
  const gernrateScore = useCallback(
    (s) => {
      if (s) {
        let grade = rs.fleschKincaidGrade(s);
        let formula = grade * 10 + 5.1;
        let sc = Math.round(formula > 100 ? 100 : formula < 0 ? 0 : formula);
        if (sc < 80) sc = 80;
        setScore(sc);
      }
    },
    [setScore]
  );

  useEffect(() => {
    if (post?.copyIndex < 3 && d?.length && googleBody?.length) {
      let text = d[post.copyIndex]?.onlyText;
      let body = googleBody[post.copyIndex]?.onlyText;
      if (!body) return;
      let split = body.split(". ");
      if (split?.length > 0 && body.length > 90) {
        split.length = split.length - 1;
        body = split.join(". ");
      }
      if (body[body.length - 1] !== ".") body += ".";
      let temp = ` <span style="font-size: 1rem;"> ${text}</span><br />`;
      text = temp + body;
      setData(text);
      gernrateScore(text);
      setCopyText(text);
    }
  }, [post.copyIndex, d, gernrateScore, setCopyText, googleBody]);

  useEffect(() => {}, [refresh]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
      className="ml-2 mr-2"
    >
      <EditableContentGoogle data={data} setCopyText={setCopyText} />
    </div>
  );
};

BodyAdGoogle.propTypes = {
  d: PropTypes.any,
  setScore: PropTypes.any,
  refresh: PropTypes.any,
  setCopyText: PropTypes.any,

  googleBody: PropTypes.any,
};
