import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from "redux-logger";
import { rootReducer } from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

// const loggerMiddleware = createLogger();

// Getting from LocalStorage
// function loadState() {
//   try {
//     const serializedState = localStorage.getItem("state");
//     if (serializedState === null) {
//       return undefined;
//     }
//     console.log("Loaded State", serializedState);
//     return JSON.parse(serializedState);
//   } catch (error) {
//     console.log(error);
//     return undefined;
//   }
// }

// // Setting to LocalStorage
// function saveState(state) {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("state", serializedState);
//   } catch (error) {
//     console.log(error);
//   }
// }

// const persistedState = loadState();

const persistConfig = {
  key: "sideBarReducer",
  storage,
  whitelist: ["sideBarReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunkMiddleware
    //  loggerMiddleware
  )
);

export const persistor = persistStore(store);

// store.subscribe(() => {
//   saveState({
//     currentKey: store.getState().sideBarReducer.currentKey,
//   });
// });
