import { authConstants } from "../constants";
import { authService, gpt3 } from "../../services/index.js";
import { history } from "../../helpers";
import { emitLogin } from "../../config/api";
import { modalAction } from ".";

const login = (email, password, _, l) => {
  const request = () => ({ type: authConstants.LOGIN_REQUEST });
  const success = (loggedInUser) => ({
    type: authConstants.LOGIN_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    authService.login(email, password).then(
      async (res) => {
        if (res && res.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.token);

          localStorage.setItem("uid", res.data.user._id);
          emitLogin(res);
          const userFormGpt3 = await gpt3.login();
          console.log("user form fpt4", userFormGpt3);
          localStorage.setItem("gpt3token", userFormGpt3?.data?.accessToken);
          // if (!userFormGpt3) return;
          dispatch(success(res.data));
          if (res.data?.user?.status === "InActive") {
            // alert("Please activate your account");
            dispatch(
              modalAction.warning({
                title: "Please activate your account",
              })
            );
            l(false);
            return;
          } else if (!res.data?.user?.subcriptionId) {
            setTimeout(() => {
              history.push("/packages");
              window.location.reload();
            }, 0);
          } else {
            setTimeout(() => {
              history.push("/unicorncopy/userpanel");
              window.location.reload();
            }, 0);
          }
        } else {
          l(false);
          // alert(
          // res.response?.data?.errors[0]?.msg
          //   ? res.response?.data?.errors[0]?.msg
          //   : res.response?.data?.errors?.msg
          // );
          dispatch(
            modalAction.warning({
              title: res.response?.data?.errors[0]?.msg
                ? res.response?.data?.errors[0]?.msg
                : res.response?.data?.errors?.msg,
            })
          );
          dispatch(failure(res.response?.data?.errors[0]?.msg));
        }
      },
      (error) => {
        l(false);
        dispatch(failure(error.toString()));
      }
    );
  };
};

const googlehandler = (data, history, l) => {
  const request = () => ({ type: authConstants.SIGNUP_REQUEST });
  const success = (loggedInUser) => ({
    type: authConstants.SIGNUP_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.SIGNUP_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    authService.googlehandler(data).then(
      async (res) => {
        if (res && res.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.token);

          localStorage.setItem("uid", res.data.user._id);
          const userFormGpt3 = await gpt3.login();

          localStorage.setItem("gpt3token", userFormGpt3?.data?.accessToken);
          dispatch(success({ role: res.data }));
          history.push("unicorncopy/userpanel");
        } else {
          l(false);
          dispatch(failure(res?.response?.data?.errors[0]?.msg));
          // alert("Signup Failed", res?.response?.data?.errors[0]?.msg);
          dispatch(
            modalAction.warning({
              title: "Signup Failed",
              subtitle: res?.response?.data?.errors[0]?.msg,
            })
          );
        }
      },
      (error) => {
        l(false);
        dispatch(failure(error.toString()));
        // alert(error.toString());
        dispatch(
          modalAction.warning({
            title: error.toString(),
          })
        );
      }
    );
  };
};

const signup = (data, navigate, l) => {
  const request = () => ({ type: authConstants.SIGNUP_REQUEST });
  const success = (loggedInUser) => ({
    type: authConstants.SIGNUP_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.SIGNUP_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    authService.signUp(data).then(
      (res) => {
        if (res && res.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("uid", res.data.user._id);
          dispatch(success({ role: res.data }));
          // alert(
          //   "Your account has been registered",
          //   +"Check your email now to get your confirmation link."
          // );
          dispatch(
            modalAction.success({
              title: "Your account has been registered",
              subtitle: "Check your email now to get your confirmation link.",
            })
          );
          navigate("/", { replace: true });
        } else {
          l(false);
          dispatch(failure(res?.response?.data?.errors[0]?.msg));
          // alert(res?.response?.data?.errors[0]?.msg);
          dispatch(
            modalAction.warning({
              title: res?.response?.data?.errors[0]?.msg,
            })
          );
        }
      },
      (error) => {
        l(false);
        dispatch(failure(error.toString()));
        // alert(error.toString());
        dispatch(
          modalAction.warning({
            title: error.toString(),
          })
        );
      }
    );
  };
};

const logout = () => {
  authService.logout();
  window.location.href = "/";
  return { type: authConstants.LOGOUT };
};

const getUser = (data, _, l, route, history) => {
  const request = () => ({ type: authConstants.LOGIN_REQUEST });
  const success = (loggedInUser) => ({
    type: authConstants.LOGIN_SUCCESS,
    loggedInUser,
  });

  const failure = (error) => ({ type: authConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    authService.getUser(data).then(
      (res) => {
        if (res && res.status === 200) {
          dispatch(success(res.data));
          if (route) {
            localStorage.setItem("auth", JSON.stringify(res.data));
            history.push(route);
          }
        } else {
          //to-do handle error
        }
      },
      (error) => {
        l(false);
        dispatch(failure(error.toString()));
      }
    );
  };
};

const getSubscription = (data) => {
  const success = (payload) => ({
    type: authConstants.SUBSCRIPTION,
    payload,
  });
  return (dispatch) => {
    authService.getSubs(data).then(
      (res) => {
        if (res && res.status === 200) {
          if (res.data?.subscription?.status === "canceled") {
            let periosEnd = new Date(
              res.data?.subscription?.current_period_end * 1000
            );
            let now = new Date();
            if (periosEnd < now) {
              window.location.href = "/";
            }
          }
          dispatch(success(res.data));
        } else {
          //to-do handle error
        }
      },
      (error) => {
        //to-do handle error
        console.log(error);
      }
    );
  };
};

const payment = (payment) => {
  return { type: authConstants.PAYMENT, payment };
};
const changePlan = (payment) => {
  return { type: authConstants.CHNAGEPLAN, payment };
};
const fp = (fp) => {
  return { type: authConstants.FP, payload: fp };
};

const removeCoin = (data) => {
  const request = () => ({ type: authConstants.REMOVECOIN });
  const success = (loggedInUser) => ({
    type: authConstants.LOGIN_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    authService.removeCoin(data).then(
      (res) => {
        if (res && res.status === 200) {
          dispatch(getUser(localStorage.getItem("uid")));
          dispatch(success(res.data));
        } else {
          //to-do handle error
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
};

const handleFacebook = (data) => {
  const success = (loggedInUser) => ({
    type: authConstants.LOGIN_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    authService.login(data.email, data.password).then(async (res) => {
      if (res && res.status === 200) {
        localStorage.setItem("auth", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("uid", res.data.user._id);
        emitLogin(res);
        const userFormGpt3 = await gpt3.login();
        localStorage.setItem("gpt3token", userFormGpt3?.data?.accessToken);
        dispatch(success(res.data));
        if (res.data?.user?.planType === "free_trial") {
          setTimeout(() => {
            history.push("unicorncopy/userpanel");
            window.location.reload();
          }, 0);
        } else {
          setTimeout(() => {
            history.push("/packages");
            window.location.reload();
          }, 0);
        }
      } else {
        authService.signUp(data).then((res) => {
          if (res && res.status === 200) {
            localStorage.setItem("auth", JSON.stringify(res.data));
            localStorage.setItem("token", res.data.token);
            dispatch(success({ role: res.data }));
            // alert("Your account has been registered");
            dispatch(
              modalAction.success({
                title: "Your account has been registered",
              })
            );
            if (res.data?.user?.planType === "free_trial") {
              setTimeout(() => {
                history.push("unicorncopy/userpanel");
                window.location.reload();
              }, 0);
            } else {
              setTimeout(() => {
                history.push("/packages");
                window.location.reload();
              }, 0);
            }
          } else {
            dispatch(failure(res?.response?.data?.errors[0]?.msg));
            // alert("Signup failed", res?.response?.data?.errors[0]?.msg);
            dispatch(
              modalAction.warning({
                title: "Signup failed",
                subtitle: res?.response?.data?.errors[0]?.msg,
              })
            );
          }
        });
      }
    });
  };
};
const processInfoLinkedIn = (data) => {
  const success = (loggedInUser) => ({
    type: authConstants.LOGIN_SUCCESS,
    loggedInUser,
  });
  const failure = (error) => ({ type: authConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    authService.login(data.email, data.password).then(async (res) => {
      if (res && res.status === 200) {
        localStorage.setItem("auth", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("uid", res.data.user._id);
        emitLogin(res);
        const userFormGpt3 = await gpt3.login();
        localStorage.setItem("gpt3token", userFormGpt3?.data?.accessToken);
        dispatch(success(res.data));
        if (res.data?.user?.planType === "free_trial") {
          setTimeout(() => {
            history.push("unicorncopy/userpanel");
            window.location.reload();
          }, 0);
        } else {
          setTimeout(() => {
            history.push("/packages");
            window.location.reload();
          }, 0);
        }
      } else {
        authService.signUp(data).then(async (res) => {
          const loggedIn = await authService.login(data.email, data.password);

          if (res && res.status === 200) {
            localStorage.setItem("auth", JSON.stringify(res.data));
            localStorage.setItem("uid", res?.data?.user?._id);
            localStorage.setItem("token", loggedIn.data.token);
            dispatch(success({ role: res.data }));
            // alert("Your account has been registered");
            dispatch(
              modalAction.success({
                title: "Your account has been registered",
              })
            );
            if (res.data?.user?.planType === "free_trial") {
              setTimeout(() => {
                history.push("unicorncopy/userpanel");
                window.location.reload();
              }, 0);
            } else {
              setTimeout(() => {
                history.push("/packages");
                window.location.reload();
              }, 0);
            }
          } else {
            dispatch(failure(res?.response?.data?.errors[0]?.msg));
            // alert("signup failed", res?.response?.data?.errors[0]?.msg);
            dispatch(
              modalAction.warning({
                title: "signup failed",
                subtitle: res?.response?.data?.errors[0]?.msg,
              })
            );
          }
        });
      }
    });
  };
};
export const authActions = {
  signup,
  login,
  logout,
  googlehandler,
  getUser,
  payment,
  getSubscription,
  removeCoin,
  changePlan,
  fp,
  handleFacebook,
  processInfoLinkedIn,
};
