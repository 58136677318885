import { postConstants } from "../constants";

const initialState = {
  posts: [],
  copyIndex: 60,
  maxIndex: 0,
  vairationIndex: [],
  prevIndexes: [],
  error: "",
  search: [],
  load: false,
  key: "",
  analytics: {},
  prevCopy: [],
  abTest: {
    selected: false,
    data: [],
    type: "",
    value: "Qualifying question VS Feature based copy",
    getStoreAbTest: () => {},
  },
  settings: {
    advanceSetting: {
      discount: {
        active: false,
      },
    },
  },
  strings: {
    discount: [
      (offType, promo) => `Get ${offType} off today with promo code: ${promo}!`,
      (offType, promo) => `Save  ${offType} today with promo code: ${promo}!`,
      (offType, promo) => `Take  ${offType} off with Promo Code: ${promo}!`,
    ],
    discountWithOutPromo: [
      (val) => `Get ${val} off today`,
      (val) => `Save ${val} today by using the link in this ad`,
      (val) => `Take ${val} off today with our special discount`,
    ],
    freeShipping: [`Free shipping on all orders`, `100% Free Shipping`],
    freeShippingWithValue: [
      (val) => ` Free shipping on all orders over ${val}!`,
      (val) => `Spend ${val} and get FREE SHIPPING.`,
    ],
    locations: [
      (val) => `Attention people from ${val}!`,
      (val) => `Hey there ${val}!`,
      (val) => `Are you from ${val}?`,
      (val) => `Anyone from ${val} here?`,
    ],
    cta: [
      (val) => `Check it out now: ${val}`,
      (val) => `Click here now: ${val}`,
      (val) => `See what’s all about now: ${val}`,
      (val) => `Find out more now: ${val}`,
      (val) => `Click here to find out more now: ${val}`,
      (val) => `Know more here: ${val}`,
      (val) => `Click here to check it out: ${val}`,
      (val) => `See it here now: ${val}`,
      (val) => `Check it here now: ${val}`,
      (val) => `Available today: ${val}`,
      (val) => `Available now: ${val}`,
      (val) => `Know more now: ${val}`,
    ],
  },
  funnelStage: "",
  direction: "right", //needed if first time
};

export default function post(state = initialState, action) {
  switch (action.type) {
    case postConstants.GET:
      return { ...state, posts: action.post?.data, error: "" };
    case postConstants.GET_SEARCH:
      return { ...state, error: "", load: true, posts: [], key: action.key };
    case postConstants.FAILURE:
      return { ...state, error: action.error };
    case postConstants.SEARCH:
      return { ...state, search: action.search?.data, load: false };
    case postConstants.AD_POST_DATA:
      return { ...state, postAdData: action.data, load: false };
    case postConstants.SET_POST_SETTINGS:
      return { ...state, settings: action.payload, load: false };
    case postConstants.UPDATE_ADD_SETTINGS:
      return { ...state, settings: action.payload, load: false };
    case postConstants.SELECT_AB_TEST:
      return {
        ...state,
        abTest: { ...state.abTest, ...action.payload },
        load: false,
      };
    case postConstants.SEARCH_ANALYTICS:
      return { ...state, analytics: action.search?.data, load: false };
    case postConstants.SELECT_FUNNEL_STAGE:
      return { ...state, funnelStage: action.payload };
    case postConstants.SELECT_DIRECTION:
      return { ...state, direction: action.payload };
    case postConstants.INITIATE_AB:
      return {
        ...state,
        abTest: { selected: false, data: [], type: "", value: "" },
      };
    case postConstants.SET_STORE_AB_TEST:
      return {
        ...state,
        abTest: { ...state.abTest, ...action.payload },
      };
    case postConstants.CHANGE_AD_TYPE:
      return {
        ...state,
        abTest: { ...state.abTest, type: action.payload },
      };
    case postConstants.GO_BACKWARD:
      return {
        ...state,
        prevCopy: action.payload,
        direction: "",
      };
    case postConstants.RANDOMIZE_NUMBER_COPYTYPE:
      return {
        ...state,
        copyIndex: action.payload(),
        prevIndexes: [...state.prevIndexes, action.payload()],
      };
    case postConstants.STORE_SECOND_VARIATION:
      return {
        ...state,
        vairationIndex: [...state.vairationIndex, action.payload],
      };
    case postConstants.SAVE_PREV_POST:
      return {
        ...state,
        prevCopy: [...state.prevCopy, action.payload],
      };
    case postConstants.INCREMENT_INDEX:
      return {
        ...state,
        copyIndex: state.copyIndex + 1,
      };
    case postConstants.DECREMENT_INDEX:
      return {
        ...state,
        copyIndex: state.copyIndex - 1,
      };
    case postConstants.RESET_INDEX:
      return {
        ...state,
        copyIndex: 0,
      };
    case postConstants.SET_MAX:
      return {
        ...state,
        maxIndex: action.payload,
      };
    case postConstants.GO_TO:
      return {
        ...state,
        copyIndex: action.payload,
      };
    default:
      return state;
  }
}
