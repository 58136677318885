import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import { Btn, Navbar } from "../../../components";
import { authService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { modalAction } from "../../../store/actions";

export function VerifyEmail() {
  const history = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setModalOpen(!modalOpen);
    history("/");
  };

  useEffect(() => {
    const reset = async () => {
      const url = global.location.href;
      if (url) {
        setLoad(false);
        const params = new URL(url).searchParams;
        const id = params.get("key");
        const key = params.get("id");
        var keys = {
          id,
          key,
        };
      }

      console.log(keys);

      if (keys.id && keys.key) {
        setLoad(true);
        const res = await authService.verifyEmail(keys);
        console.log(res);
        if (res.message === "Request failed with status code 400") {
          alert("Link expired please try again later");
          setLoad(false);
          setError(true);
        } else if (res.data.msg === "Account is activated") {
          dispatch(
            modalAction.success({
              title: "Account Verified!",
            })
          );
          history("/");
        }
      }
    };
    reset();
  }, [dispatch, history]);
  if (load) {
    return (
      <>
        <Navbar signout={true} />
        {load && <Spin />}
      </>
    );
  }
  // setModalOpen(true)
  return (
    <div>
      <Navbar signout={true} />
      <div>
        <div style={{ marginBottom: "50px" }}>
          <h1>{"Email Verification"}</h1>
        </div>
      </div>
      {error && (
        <div className="flex align-center justify-center">
          <div>
            <div className="flex align-center">
              <div style={{ padding: "10px  40px  10px  40px" }}>
                <h4 style={{ textAlign: "center" }}>
                  Error
                  <hr />
                </h4>
                <h4>
                  Link is expired
                  <br />
                  <br />
                </h4>
                <br />
                <div onClick={handleClose} style={{ width: "100%" }}>
                  <Btn value="Close" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
