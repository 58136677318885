export const postConstants = {
  GET: "GET",
  FAILURE: "FAILURE",
  SEARCH: "SEARCH",
  GET_SEARCH: "GET_SEARCH",
  AD_POST_DATA: "AD_POST_DATA",
  SET_POST_SETTINGS: "SET_POST_SETTINGS",
  UPDATE_ADD_SETTINGS: "UPDATE_ADD_SETTINGS",
  SELECT_AB_TEST: "SELECT_AB_TEST",
  SELECT_FUNNEL_STAGE: "SELECT_FUNNEL_STAGE",
  INITIATE_AB: "INITIATE_AB",
  SET_STORE_AB_TEST: "SET_STORE_AB_TEST",
  CHANGE_AD_TYPE: "CHANGE_AD_TYPE",
  SEARCH_ANALYTICS: "SEARCH_ANALYTICS",
  RANDOMIZE_NUMBER_COPYTYPE: "RANDOMIZE_NUMBER_COPYTYPE",
  GO_BACKWARD: "GO_BACKWARD",
  SELECT_DIRECTION: "SELECT_DIRECTION",
  STORE_SECOND_VARIATION: "STORE_SECOND_VARIATION",
  SAVE_PREV_POST: "SAVE_PREV_POST",
  INCREMENT_INDEX: "INCREMENT_INDEX",
  RESET_INDEX: "RESET_INDEX",
  DECREMENT_INDEX: "DECREMENT_INDEX",
  SET_MAX: "SET_MAX",
  GO_TO: "GO_TO",
};
