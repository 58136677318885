import React from "react";
import { Switch } from "antd";
import PropTypes from "prop-types";
import "./scss/index.scss";

export const SwitchBtn = (props) => {
  return (
    <div>
      <Switch
        disabled={props.disabled}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onClick={props.onChange}
      />
    </div>
  );
};

SwitchBtn.propTypes = {
  disabled: PropTypes.any,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.any,
  checked: PropTypes.any,
};
