import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AdHeader } from "../post-components/header";
import { AdHeadline } from "../post-components/headline";
import { AdPrimary } from "../post-components/primary";
import { GooglePostAd } from "../google-ad-copy";
import Views from "../post-components/View";
import Bubble from "../../../modules/Buble";
import CommentNLikes from "../like-comments";

export const MiscAdCoopy = ({
  logo,
  state,
  text,
  primaryImg,
  primaryText,
  headline,
  setHeadlineIndex,
  onClick,
  score,
  wirteClipboardDesc,
  wirteClipboardHeadline,
  type,
  google,
  IndexChanger,
  handleShowCase,
  showCase,
  commentState,
  view,
}) => {
  const [, setIsPrev] = useState(false);
  const [, setIsEidted] = useState(false);
  const { copyIndex } = useSelector((e) => e.post);
  let d = state?.statticType;
  useEffect(() => {
    setIsEidted(false);
    setIsPrev(false);
  }, [d, copyIndex]);
  return (
    <div className="shadow-sm w-100 border-2 mx-auto container bg-white rounded-lg">
      {/* to-do make all type true condition only */}
      {type !== "google_description" && type !== "google_headline" && (
        <AdHeader
          onClick={onClick}
          title={text}
          score={score}
          logo={logo}
          handleShowCase={handleShowCase}
          wirteClipboardDesc={wirteClipboardDesc}
          d={d}
          showCase={showCase}
          view={view}
        />
      )}
      {(type === "fb_primary" ||
        type === "engaging_question" ||
        type === "persuasive_bullet_points" ||
        type === "unique" ||
        type === "ftb") && (
        <AdPrimary
          primaryImg={primaryImg}
          primaryText={primaryText}
          type={type}
          imageHidden={type === "persuasive_bullet_points"}
        />
      )}
      {(type === "fb_headline" ||
        type === "website_sub_headline" ||
        type === "perfect_headline" ||
        type === "ftb" ||
        type === "website_sub_headline") && (
        <AdHeadline
          setHeadlineIndex={setHeadlineIndex}
          wirteClipboardHeadline={wirteClipboardHeadline}
          headline={headline}
          handleShowCase={handleShowCase}
          noIcon={
            type === "perfect_headline" || (type === "fb_headline" && true)
          }
        />
      )}
      {(type === "google_headline" || type === "google_description") && (
        <GooglePostAd
          state={state}
          google={google}
          IndexChanger={IndexChanger}
          handleShowCase={handleShowCase}
          showCase={showCase}
          view={view}
        />
      )}
      {false && <Views view={view} misc={true} />}
      {showCase && <Bubble audience={state?.showcase?.audience} misc={true} />}
      {showCase && <CommentNLikes commentState={commentState} misc={true} />}
    </div>
  );
};

MiscAdCoopy.propTypes = {
  state: PropTypes.any,
  text: PropTypes.any,
  primaryImg: PropTypes.any,
  primaryText: PropTypes.any,
  headline: PropTypes.any,
  logo: PropTypes.any,
  setHeadlineIndex: PropTypes.any,
  onClick: PropTypes.any,
  score: PropTypes.number,
  wirteClipboardDesc: PropTypes.func,
  wirteClipboardHeadline: PropTypes.func,
  type: PropTypes.string,
  google: PropTypes.any,
  IndexChanger: PropTypes.any,
  handleShowCase: PropTypes.any,
  commentState: PropTypes.any,
  showCase: PropTypes.bool,
  view: PropTypes.number,
};
