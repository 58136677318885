import React from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";
import { Space, Spin } from "antd";

export const Btn = (props) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={"btn flex justify-center items-center " + props.className}
      style={{
        background: props.bgColor,
        color: props.color,
        border: props.border,
        padding: props.padding,
      }}
    >
      {!props.load ? (
        <Space size={"middle"}>
          {props.icon ? props.icon : props.icon}
          {props.value && props.value}
        </Space>
      ) : (
        <Spin
          size={18}
          style={{ padding: "0px", margin: "0px", color: "#ffff" }}
        />
      )}
    </button>
  );
};

Btn.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  load: PropTypes.any,
  padding: PropTypes.string,
  disabled: PropTypes.any,
  setRenderBrain: PropTypes.func,
  className: PropTypes.string,
};
