//AppAxios.put(`${MASTER_URL}`,master,{headers:Headers(token)});

import { API, Headers } from "../config/api";

const login = (email, password) =>
  API.post("login", { email, password }).then(
    (res) => res,
    (err) => err
  );

const signUp = (data) =>
  API.post("createUser", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const googlehandler = (data) =>
  API.post("googleSiginHandler", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("auth");
  localStorage.removeItem("uid");
  localStorage.removeItem("token");
  localStorage.removeItem("Usertype");
  return true;
};

const resetPassword = (data) =>
  API.post("forgotPassword", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const changePassword = (data) =>
  API.post("changePassword/" + data.id + "/" + data.key, data, {
    headers: Headers(),
  }).then(
    (res) => res,
    (err) => err
  );

const verifyEmail = (data) =>
  API.post("verifyEmail/" + data.id + "/" + data.key, data, {
    headers: Headers(),
  }).then(
    (res) => res,
    (err) => err
  );

const forgotPasswordViaText = (data) =>
  API.post("forgotPasswordViaText", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const checkVerificationCode = (data) =>
  API.post("checkVerificationCode", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const changePasswordViaText = (data) =>
  API.post("changePasswordViaText", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );

const updateUser = (id, data) =>
  API.post("updateUser/" + id, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const verifyOldPassword = (id, data) =>
  API.post("verifyOldPassword", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getUser = (id, data) =>
  API.get("getUser/" + id, data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getSubs = (data) =>
  API.post("retrieveASubscription", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getCus = (data) =>
  API.post("retrieveaCsutomer", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const cancelSub = (data) =>
  API.post("cancelSubcription", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const addFav = (data) =>
  API.post("addFavourite", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getFav = (data) =>
  API.get("getFav", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getShowcase = (data) =>
  API.get("getShowcase", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const addShowCase = (data) =>
  API.post("addToShowCase", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const getFavById = (data) =>
  API.post("getFavById", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const removeCoin = (data) =>
  API.post("removeCoin", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const createPortal = (data) =>
  API.post("create-customer-portal-session", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const linkedinSignin = (data) =>
  API.post("loginWithLinkedIn", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const createCheckoutSession = (data) =>
  API.post(
    "createCheckoutSession",
    { ...data, email: JSON.parse(localStorage.getItem("auth"))?.user?.email },
    { headers: Headers() }
  ).then(
    (res) => res,
    (err) => err
  );
const retrievePayments = (data) =>
  API.post("retrievePayments", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const sendEmailForContact = (data) =>
  API.post("sendEmailForContact", data, { headers: Headers() }).then(
    (res) => res,
    (err) => err
  );
const validateSession = (data, uid) =>
  API.get("validateSession/" + data, uid, {
    headers: Headers(),
  }).then(
    (res) => res,
    (err) => err
  );
export const authService = {
  login,
  logout,
  signUp,
  resetPassword,
  changePassword,
  forgotPasswordViaText,
  checkVerificationCode,
  changePasswordViaText,
  googlehandler,
  verifyEmail,
  updateUser,
  verifyOldPassword,
  getUser,
  getSubs,
  getCus,
  cancelSub,
  addFav,
  getFav,
  addShowCase,
  getShowcase,
  getFavById,
  createPortal,
  removeCoin,
  linkedinSignin,
  createCheckoutSession,
  validateSession,
  retrievePayments,
  sendEmailForContact,
};
