import { combineReducers } from "redux";
/**
 * all reducers
 */
import { authentication } from "./auth.reducer";
import { modal } from "./modal.reducer";
import post from "./post.reducer";
import filter from "./filterReducer";
import sideBarReducer from "./sideBarReducer";

export const rootReducer = combineReducers({
  authentication,
  modal,
  post,
  filter,
  sideBarReducer,
});
export const RootState = rootReducer;
