import React, { useState } from "react";
import { TextInput, Btn } from "../../../components/forms";
import { Link, useNavigate } from "react-router-dom";
import "./scss/index.scss";
// import { emailSchema } from "../../../Validations/UserValidation";
import { authService } from "../../../services";
// import { useDispatch } from "react-redux";
// import { authActions } from "../../../store/actions";

export const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigation = useNavigate();
  // const dispatch = useDispatch();
  const chagnePassword = async () => {
    const url = global.location.href;
    if (url) {
      const params = new URL(url).searchParams;
      const id = params.get("u");
      const key = params.get("k");
      var keys = {
        id,
        key,
        password,
        confirm_password: confirmPassword,
      };
      if (keys.id && keys.key) {
        if (password && password === confirmPassword) {
          authService
            .changePassword(keys)
            .then((success) => {
              if (success.data?.msg === "Password has changed successfully") {
                alert(success.data.msg);
                navigation("../", { replace: true });
                return;
              }

              if (success.response?.data?.msg) {
                alert(success.response?.data?.msg);
              }
            })
            .catch((e) => {
              if (e.response?.data?.msg === "Invalid email address") {
                alert("invalid email");
              } else {
                alert(e.response?.data?.msg);
              }
            });
        } else if (password !== confirmPassword) {
          alert("password does not match");
        }
      } else {
        alert("please try again");
      }
    }
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen xs:overflow-hidden w-screen">
      <div className="card p-10">
        <h1>Reset Password</h1>
        <p>Enter your New Password!</p>
        <div className="w-full pb-2 pt-2">
          <label htmlFor="email">Password</label>
          <TextInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="email"
            id="email"
            placeholder="Enter your password"
          />
        </div>
        <div className="w-full pb-2 pt-2">
          <label htmlFor="email">Confirm Password</label>
          <TextInput
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="email"
            id="email"
            placeholder="Re enter your password"
          />
        </div>
        <div className="pb-2 pt-2 text-center">
          <Btn
            onClick={chagnePassword}
            value="Confirm"
            bgColor="#1e2a3b"
            color="#ffffff"
          />
          <Link to="/" className="mt-5">
            Go Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};
