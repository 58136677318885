import React from "react";
import PropTypes from "prop-types";

export default function MobileDropdown({
  filters,
  handleFilter,
  selectedFilter,
}) {
  return (
    <>
      <select
        onChange={(e) => {
          handleFilter(e.target.value);
        }}
        className="mobileDropdown w-full container-btn-showcase-header rounded-lg"
      >
        {!!filters?.length &&
          filters.map((e, id) => {
            return (
              <option
                key={id}
                className={`btn-showcase ${
                  selectedFilter?.toLowerCase() === e?.type?.toLowerCase() &&
                  "btn-showcase-activ"
                }`}
                value={e.type}
              >
                {e.name}
              </option>
            );
          })}
      </select>
    </>
  );
}

MobileDropdown.propTypes = {
  filters: PropTypes.any,
  handleFilter: PropTypes.any,
  selectedFilter: PropTypes.any,
};
